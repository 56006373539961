<div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
  <div class="flex flex-1 items-center justify-between">
    <div>
      <p class="text-sm text-gray-700">
        {{ 'teacher_event_list.results' | transloco }}:
        <span class="font-medium">{{totalCount}}</span>
      </p>
      <p class="text-sm text-gray-700">
        <span class="font-medium">{{firstElementNumberOnPage}}</span>
        -
        <span class="font-medium">{{lastElementNumberOnPage}}</span>
        {{ 'teacher_event_list.results_shown' | transloco }}
      </p>
    </div>
    <div>
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <button type="button" (click)="firstPageClick()"
          class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-light-green/25 focus:z-20 focus:outline-offset-0">
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd" />
          </svg>
          <span> {{ 'teacher_event_list.pagination.first' | transloco }}</span>
        </button>
        <button type="button" (click)="previousClick()"
          class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-light-green/25 focus:z-20 focus:outline-offset-0">
          <span class="sr-only">Previous</span>
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd" />
          </svg>
        </button>
        @if(totalPageNumbersArray.length >0){
        @for(i of totalPageNumbersArray; track $index){
        <button type="button" [class.active]="selectedPageNumber === i" (click)="pageClick(i)"
          [class.inactive]="selectedPageNumber !== i" class="relative hover:bg-light-green/25 page z-10 
          inline-flex items-center px-4 py-2 text-sm font-semibold text-black focus:z-20 focus-visible:outline">
          {{i}}</button>
        }
        }
        <button type="button" (click)="nextClick()"
          class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-light-green/25 focus:z-20 focus:outline-offset-0">
          <span class="sr-only">Next</span>
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
        </button>
        <button type="button" (click)="lastPageClick()"
          class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-light-green/25 focus:z-20 focus:outline-offset-0">
          <span> {{ 'teacher_event_list.pagination.last' | transloco }}</span>
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
        </button>
      </nav>
    </div>
  </div>
</div>