<div class="grid grid-rows-2 gap-4 m-5">
    <div class="row-start-1 lg:w-2/3 md:w-2/3 w-full justify-self-center">
        <div
            class="max-w justify-center p-6 m-6 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 sign-up-background">
            <div>
                <h3 class="text-white text-center mb-6 text-2xl font-bold">{{'common.registration' | transloco}}</h3>
            </div>
            <form [formGroup]="form" class="group" (ngSubmit)="submit()">
                <div class="grid grid-cols-1 gap-4 ">
                    <div class="w-full mb-6"
                        [class.error]="form.controls['email'].invalid && form.controls['email'].touched">
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="email"
                                class="mb-2 text-sm font-medium text-white dark:text-white">{{'common.email'
                                | transloco}}: </label>
                            <input (keydown.enter)="preventEnter($event)" type="email" id="email" class="
                                        bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green
                                        focus:border-light-green w-full p-2.5
                                        invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                                placeholder="Kérlek írd be az e-mail címed" formControlName="email" #email required>
                            @if(form.controls['email'].touched){
                            @if(form.errors?.['invalidEmail']){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs"> {{'validation.error.email'
                                | transloco}}</span>
                            }
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">
                                {{getError(form.controls['email'])}}</span>
                            }
                        </div>
                    </div>
                    <div class="w-full mb-6"
                        [class.error]="form.controls['password'].invalid && form.controls['password'].touched">
                        <label for="password" class="block text-sm font-medium text-white leading-6">
                            {{'common.password'
                            | transloco}}</label>
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <input [type]="type" formControlName="password" name="password" id="password"
                                class="input-text" placeholder="*****">
                            <button type="button" class="absolute inset-y-0 right-3 flex items-center"
                                (click)="eyeClicked()">
                                @if(type === 'password'){
                                <img src="../../../../assets/images/eye.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                    alt="eye">
                                }@else if(type === 'text'){
                                <img src="../../../../assets/images/eye-slash.svg" class="h-7 w-7 btn-icon"
                                    loading="lazy" alt="eye">
                                }
                            </button>
                            @if(form.controls['password'].touched){
                            <div class="absolute end-0 bottom-[-15px] text-red-600 text-xs">
                                @if(form.errors?.['noUpperCase']){
                                <span>
                                    {{'validation.error.uppercase' | transloco}},
                                </span>
                                }
                                @if(form.errors?.['noLowerCase']){
                                <span>
                                    {{'validation.error.lowercase' | transloco}},
                                </span>
                                }
                                @if(form.errors?.['noNumber']){
                                <span>
                                    {{'validation.error.number' | transloco}},
                                </span>
                                }
                                @if(form.errors?.['noSpecialChar']){
                                <span>
                                    {{'validation.error.special' | transloco}},
                                </span>
                                }
                                <span>{{
                                    getError(form.controls['password'])
                                    }}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div class="w-full mb-6"
                        [class.error]="form.controls['confirm_password'].invalid && form.controls['confirm_password'].touched">
                        <label for="confirm_password" class="block text-sm font-medium text-white leading-6">
                            {{'common.password_confirm'
                            | transloco}}</label>
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <input [type]="confirmType" formControlName="confirm_password" name="confirm_password"
                                id="confirm_password" class="input-text" placeholder="*****">
                            <button type="button" class="absolute inset-y-0 right-3 flex items-center"
                                (click)="eyeConfirmClicked()">
                                @if(confirmType === 'password'){
                                <img src="../../../../assets/images/eye.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                    alt="eye">
                                }@else if(confirmType === 'text'){
                                <img src="../../../../assets/images/eye-slash.svg" class="h-7 w-7 btn-icon"
                                    loading="lazy" alt="eye">
                                }
                            </button>
                            @if(form.controls['password'].touched){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                                getError(form.controls['password'])
                                }}</span>
                            @if(form.errors?.['PasswordNoMatch']){
                            <div class="abottom-[-15px] text-red-600 text-xs">
                                {{'validation.error.doesnt_match' | transloco}}
                            </div>
                            }
                            }
                        </div>

                    </div>

                    <div class="w-full mb-6"
                        [class.error]="form.controls['lastName'].invalid && form.controls['lastName'].touched">
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="lastName"
                                class="mb-2 text-sm font-medium text-white dark:text-white">{{'common.last_name' |
                                transloco}}:
                            </label>
                            <input (keydown.enter)="preventEnter($event)" type="text" id="lastName" class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green focus:border-light-green  w-full p-2.5
                            invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                                placeholder="Kérlek írd be a vezetékneved..." formControlName="lastName" required>
                            @if(form.controls['lastName'].touched){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                                getError(form.controls['lastName'])
                                }}</span>
                            }
                        </div>
                    </div>

                    <div class="w-full mb-6"
                        [class.error]="form.controls['firstName'].invalid && form.controls['firstName'].touched">
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="firstName"
                                class=" mb-2 text-sm font-medium text-white dark:text-white">{{'common.first_name' |
                                transloco}}:
                            </label>
                            <input (keydown.enter)="preventEnter($event)" type="text" id="firstName" class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green focus:border-light-green  w-full p-2.5
                                invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                                placeholder="Kérlek írd be a keresztneved..." formControlName="firstName" required>
                            @if(form.controls['firstName'].touched){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                                getError(form.controls['firstName'])
                                }}</span>
                            }
                        </div>
                    </div>
                    <div class="w-full mb-6"
                        [class.error]="form.controls['phoneNumber'].invalid && form.controls['phoneNumber'].touched">
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="phoneNumber"
                                class=" mb-2 text-sm font-medium text-white dark:text-white">{{'common.phone_number' |
                                transloco}}:
                            </label>
                            <input (keydown.enter)="preventEnter($event)" type="text" id="phoneNumber" class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green focus:border-light-green  w-full p-2.5
                            invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                                placeholder="+36201025100" formControlName="phoneNumber" required>
                            @if(form.controls['phoneNumber'].touched){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                                getError(form.controls['phoneNumber'])
                                }}</span>
                            }
                        </div>
                    </div>

                    <label for="roleType" class="mb-2 text-sm font-medium text-white dark:text-white">
                        {{'common.registration_goal' | transloco}}
                    </label>
                    <select id="roleType" formControlName="roleId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-light-green focus:border-light-green block w-full p-2.5
                        invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500">
                        <option value="1" selected>{{'common.teacher_role' | transloco}}</option>
                        <option value="2">{{'common.student_role' | transloco}}</option>
                    </select>
                    <label for="public" class="mb-2 text-sm font-medium text-white dark:text-white">{{'common.public' |
                        transloco}}:
                    </label>

                    <div class="flex flex-wrap" [class.error]="form.controls['isAszfAccepted'].errors && form.controls['isAszfAccepted'].touched">
                        <input (keydown.enter)="preventEnter($event)" type="checkbox" formControlName="isAszfAccepted"
                            class="w-6 h-6 me-2 bg-gray-100 rounded text-light-green focus:ring-light-green" />
                        <label for="isAszfAccepted" class="mb-2 text-sm font-medium text-white dark:text-white">
                            {{'common.isAszfAccepted' | transloco}}
                        </label>
                        @if(form.errors?.['isAszfNoAccepted']){
                        <span class="ml-5 text-red-600 text-xs"> {{'validation.error.aszf' | transloco}}</span>
                        }
                    </div>

                    @if(selectedRole === '1'){
                    <div class="flex flex-wrap">
                        <input (keydown.enter)="preventEnter($event)" type="checkbox" formControlName="isPhonePublic"
                            class="w-6 h-6 me-2 bg-gray-100 rounded text-light-green focus:ring-light-green" />
                        <label for="isPhonePublic" class="mb-2 text-sm font-medium text-white dark:text-white">
                            {{'common.isPhonePublicLabel' | transloco}}
                        </label>
                    </div>

                    <div class="flex flex-wrap">
                        <input (keydown.enter)="preventEnter($event)" type="checkbox" formControlName="isEmailPublic"
                            class="w-6 h-6 me-2 bg-gray-100 rounded text-light-green focus:ring-light-green" />
                        <label for="isEmailPublic" class="mb-2 text-sm font-medium text-white dark:text-white">
                            {{'common.isEmailPublicLabel' | transloco}}
                        </label>
                    </div>
                    }
                    <button [disabled]="!form.valid" class="login_button_firebase disabled:pointer-events-none disabled:opacity-30
                        justify-self-center w-full text-white font-bold px-4 py-2 rounded" type="submit">
                        <p class="justify-self-center">{{'common.registration' | transloco}}</p>
                    </button>
                    <button type="button"
                        class="bg-light-yellow hover:bg-dark-yellow justify-self-center w-full text-white font-bold px-4 py-2 rounded"
                        (click)="backClicked()">
                        <p class="justify-self-center">{{'common.back' | transloco}}</p>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>