export class Config{
  public static baseUrl = 'https://www.orafoglalo.hu:8083/api/';
  public static backendUrl = 'https://www.orafoglalo.hu:8083/';
  public static frontendUrl = 'https://www.orafoglalo.hu/';
  public static stripePublicKey = 'pk_test_51OjjhYLslMHctIr8pWIxN3KJlgKeRa4goEHMdnfjuRe6vNzBVK9kNUdp8mklvMFfScpDq5YMLELJW6UU9GPV6bmW00IodEB9ky';
  public static stripeSecretKey = 'sk_test_51OjjhYLslMHctIr85w9zWOn7LBbtuNEyMGZ6Y4wyXDpgKCFlNLmoMwNarDGzxediG4HqRHvwTNAf1sIqiLvY5RDQ00xiFmSKWK';
  public static facebookLink = 'https://www.facebook.com/orafoglalo';
  public static instagramLink = 'https://www.instagram.com/orafoglalo/';
  public static youtubeLink = 'https://www.youtube.com/channel/UCIbXRrKLxWjIx6hr4yIkLgQ';
  public static tiktokLink = 'https://www.tiktok.com/@orafoglalo';
public static googleAnalyticsKey = 'G-SWVH07GECX';
  public static googleAnalyticsKeyDev = '';
}
