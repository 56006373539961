import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { LessonTypeR } from "../../../teacher/models/lesson-type/lesson-type.model";

@Injectable({
    providedIn: 'root'
})
export class LessonTypeService {

    constructor(private http: HttpClient) {

    }

    getAllLessonTypes() {
        return this.http.get<LessonTypeR[]>(Config.baseUrl + 'lessontype/getall');
    }
}