import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, ReplaySubject } from "rxjs";
import { JwtDecode, JwtDecodeGoogleCredentials } from "../../models/jwt-token/jwt-token.model";
import { formatDate } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor(
        private _sanitizer: DomSanitizer
    ) {

    }

    convertFileToBase64(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => {
            if (event.target && event.target.result)
                result.next(btoa(event.target.result.toString()));
        }
        return result;
    }

    decodeBase64ImageFileToSecurityTrustResource(base64String: string) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(base64String)
    }

    decodeToken(token: string): JwtDecode | JwtDecodeGoogleCredentials {
        const _decodeToken = (token: string) => {
            try {
                const base64 = token.replace(/-/g, '+').replace(/_/g, '/');
                const decoded = atob(base64);
                return JSON.parse(decodeURIComponent(escape(decoded)));
            } catch {
                return;
            }
        };
    
        return token
            .split('.')
            .map(token => _decodeToken(token))
            .reduce((acc, curr) => {
                if (!!curr) acc = { ...acc, ...curr };
                return acc;
            }, Object.create(null));
    }

    decodeTokenExpired(exp: number) {
        return new Date(exp * 1000);
    }

    createDateFromData(selectedTime: string, selectedDate: Date) {
        let date = new Date(selectedDate);
        let hourAndMinute = selectedTime.split(':');
        date.setHours(hourAndMinute[0] as unknown as number);
        date.setMinutes(hourAndMinute[1] as unknown as number);
        return formatDate(date, 'YYYY-MM-dd HH:mm', 'en-US')
    }

    getKeyByValue(value: string, customEnum: any) {
        const indexOfS = Object.values(customEnum).indexOf(value as unknown as typeof customEnum);
        const key = Object.keys(customEnum)[indexOfS];

        return key;
    }

    getValueBykey(value: string, customEnum: any) {
        const indexOfS = Object.keys(customEnum).indexOf(value as unknown as typeof customEnum);
        const key = Object.values(customEnum)[indexOfS];

        return key as string;
    }

    getKeyIndexByValue(value: string, customEnum: any) {
        const indexOfS = Object.values(customEnum).indexOf(value as unknown as typeof customEnum);

        return indexOfS;
    }

    getValueIndexBykey(value: string, customEnum: any) {
        const indexOfS = Object.keys(customEnum).indexOf(value as unknown as typeof customEnum);

        return indexOfS;
    }

    getImageSize(file: File): Observable<number[]> {
        return new Observable(observer => {
            var image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                var height = image.height;
                var width = image.width;
                observer.next([width, height]);
                observer.complete();
            };
        });
    }
}