import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import * as moment from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { UserDataR } from '../../../shared/services/user/user.model';
import { LocationTypeR } from '../../../teacher/models/locations/location-type.model';
import { LessonR } from '../../../teacher/models/lesson/lesson.model';
import { OnlineLocation } from '../../../teacher/models/locations/online-location.model';
import { OfflineLocation } from '../../../teacher/models/locations/offline-location.model';
import { CreateEventQ, EventR } from '../../../shared/models/event/event.model';
import { LessonIdEnum } from '../../../shared/enums/lessond-ids.enum';
import { UtilService } from '../../../shared/services/utils/utils.serivce';
import { TOAST_STATE, ToastService } from '../../../shared/services/toast/toast.service';
import * as EventActions from '../../../shared/services/event/event-store/event.action';
import * as LocationActions from '../../../teacher/services/location/location-store/location.action';
import * as LessonActions from '../../../teacher/services/lesson/lesson-store/lesson.action';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { LocationTypeEnum } from '../../../shared/enums/location-types.enum';
import { RoleEnum } from '../../../shared/enums/roles.enum';
import { eventDatesValidator } from '../../../shared/validators/event-dates-validator';
import { SendEmailByIdDTO, SendEmailModel } from '../../../shared/models/email/send-email-to-students.model';

export interface CreateEventModalData {
  title: string;
  event: EventR
}

enum RepeatTypesEnum {
  NoRepeat = 1,
  Monthly = 3,
  Weekly = 2
}

@Component({
  selector: 'create-event-by-student-modal',
  templateUrl: './create-event-by-student-modal.component.html'
})
export class CreateEventByStudentModal extends BaseComponent implements OnInit {
  override form!: FormGroup;
  userData: UserDataR = {
    profilePicture: '',
    about: '',
    professionalExperience: ''
  };
  emailDTO: SendEmailModel = {};
  emailByIdDTO: SendEmailByIdDTO = {};
  locationTypes: LocationTypeR[] = [];
  lessons: LessonR[] = [];
  onlineLocations: OnlineLocation[] = [];
  offlineLocations: OfflineLocation[] = [];
  eventQ: CreateEventQ = {};
  eventR!: EventR;
  teacherId = 0;
  selectedLessonId = -1;
  timeZones: string[] = [];
  filteredOptions!: Observable<string[]>;
  actualTimeZone = 'Europe/Budapest'
  availableLessonId = LessonIdEnum.AVAILABLE;
  repeatTypesEnum = Object.values(RepeatTypesEnum)
    .filter(key => Number(key))
    .map(key => { return { key: key, value: RepeatTypesEnum[key as number] }; });

  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<CreateEventByStudentModal>,
    @Inject(MAT_DIALOG_DATA) public data: CreateEventModalData,
    private utilService: UtilService,
    override translocoService: TranslocoService,
    override toastService: ToastService,
    private fb: FormBuilder,
    override store: Store,
    override action$?: Actions,
  ) {
    super(store, toastService, action$, translocoService);

    action$?.pipe(ofType(EventActions.getEventSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.eventR = response.data;
        this.patchEventValues(this.eventR);
      }
    })

    action$?.pipe(ofType(EventActions.CreateEventSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        this.sendEmailToStudent();
        this.sendEmailToTeacher();
      }
    })

    action$?.pipe(ofType(EventActions.createAvailableEventsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data > 0) {
        this.sendEmailToStudent();
        this.sendEmailToTeacher();
      }
    })

    action$?.pipe(ofType(LocationActions.getAllLocationTypesSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data && response.data.length > 0) {
        this.locationTypes = response.data ?? [];
      }
    })

    action$?.pipe(ofType(LessonActions.loadGetTeacherLessonsByTeacherIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data && response.data.length > 0) {
        this.lessons = response.data;
      }
    })

    action$?.pipe(ofType(LocationActions.getAllLocationByTypeIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      this.onlineLocations = [];
      this.offlineLocations = [];
      if (response.data && response.data.length > 0) {
        const typeId = response.data[0].locationTypeId;
        if (typeId === LocationTypeEnum.ONLINE) {
          this.onlineLocations = [];
          response.data.forEach(loc => {
            this.onlineLocations.push(loc)
          });
        } else if (typeId === LocationTypeEnum.OFFLINE) {
          this.offlineLocations = [];
          response.data.forEach(loc => {
            this.offlineLocations.push(loc)
          });
        }
      }
    })

    action$?.pipe(ofType(TeacherActions.failure), takeUntil(this.destroy$)).subscribe(() => {
      this.loaderService?.hideLoader();
      this.toastService.showToast(TOAST_STATE.warning, "Hiba a folyamat során!");
    })
  }

  ngOnInit(): void {
    this.formBuilder();
    this.actualTimeZone = this.getTimezone();
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      })
    this.subscriptionsInit();
    this.initTimeZones();
    if (this.data.event) {
      this.patchEventValues(this.data.event);
    }
  }

  sendEmailToStudent() {
    this.emailDTO.subject = 'Óra kérésed elküldve!';
    this.emailDTO.toAddresses = [];
    this.emailDTO.toAddresses.push(this.userData.email!);
    this.emailDTO.body = "A következ adatokkal szeretnél órát kérni:\n<br>"
      + "<b>" + this.eventQ.eventName + "\n</b><br>"
      + "<b><u>Időpontja: </u>" + this.eventQ.eventStartDate + " - " + this.eventQ.eventEndDate + "</b><br>\n" + " Időzóna: " +
      "<b>" + this.eventQ.timeZone + "</b> \n"
      + "<b><u>Óraadónk hamarosan értesít, arról, hogy elfogadta - e a kérésed! </b></u>";
    this.store.dispatch(TeacherActions.sendEmailToAddresses({ data: this.emailDTO }));
  }

  sendEmailToTeacher() {
    this.emailByIdDTO.subject = 'Órát szeretnének venni Tőled!';
    this.emailByIdDTO.toAddresses = [];
    this.emailByIdDTO.userId = this.teacherId;
    this.emailByIdDTO.body = "A következ adatokkal szeretnének órát kérni tőled:\n<br>"
      + "<b>" + this.eventQ.eventName + "\n</b><br>"
      + "<b><u>Időpontja: </u>" + this.eventQ.eventStartDate + " - " + this.eventQ.eventEndDate + "</b><br>\n" + " Időzóna: " +
      "<b>" + this.eventQ.timeZone + "</b> \n"
      + "<b><u>Elfogadhatod vagy visszautasíthatod az alkalmazásunkban. </b></u>";
    this.store.dispatch(TeacherActions.sendEmailById({ data: this.emailByIdDTO }));
  }

  bookingClicked() {
    let eventsResult: CreateEventQ[] = [];
    let eventQ: CreateEventQ = this.fillEventValues();
    this.eventQ = JSON.parse(JSON.stringify(eventQ));
    if (this.teacherId && this.teacherId > 0) {
      eventQ.userId = this.teacherId;
    }

    if (this.data.event && this.data.event.lessonId && this.data.event.lessonId === LessonIdEnum.AVAILABLE) {
      if (
        moment(eventQ.eventStartDate).format('YYYY-MM-DD HH:mm:ss') === moment(this.data.event.eventStartDate).format('YYYY-MM-DD HH:mm:ss') &&
        moment(eventQ.eventEndDate).format('YYYY-MM-DD HH:mm:ss') === moment(this.data.event.eventEndDate).format('YYYY-MM-DD HH:mm:ss')) {
        let originalEventQ: CreateEventQ = JSON.parse(JSON.stringify(eventQ));

        originalEventQ.isAccepted = false;
        eventsResult.push(originalEventQ);
      }
      else if (
        moment(eventQ.eventStartDate).format('YYYY-MM-DD HH:mm:ss') === moment(this.data.event.eventStartDate).format('YYYY-MM-DD HH:mm:ss') &&
        moment(eventQ.eventEndDate).format('YYYY-MM-DD HH:mm:ss') <= moment(this.data.event.eventEndDate).format('YYYY-MM-DD HH:mm:ss')) {
        let tempEvent: CreateEventQ = JSON.parse(JSON.stringify(eventQ));
        let originalEventQ: CreateEventQ = JSON.parse(JSON.stringify(eventQ));

        tempEvent.lessonId = LessonIdEnum.AVAILABLE;
        tempEvent.eventStartDate = moment(eventQ.eventEndDate).format('YYYY-MM-DD HH:mm:ss');
        tempEvent.eventEndDate = moment(this.data.event.eventEndDate).format('YYYY-MM-DD HH:mm:ss');
        tempEvent.startHours = moment(eventQ.eventEndDate).format('HH:mm');
        tempEvent.endHours = moment(this.data.event.eventEndDate).format('HH:mm');
        tempEvent.eventName = this.translocoService!.translate('teacher.calendar.event.available');
        tempEvent.eventId = undefined;
        tempEvent.isAccepted = true;

        originalEventQ.isAccepted = false;

        eventsResult.push(tempEvent);
        eventsResult.push(originalEventQ);
      }
      else if (
        moment(eventQ.eventStartDate).format('YYYY-MM-DD HH:mm:ss') >= moment(this.data.event.eventStartDate).format('YYYY-MM-DD HH:mm:ss') &&
        moment(eventQ.eventEndDate).format('YYYY-MM-DD HH:mm:ss') === moment(this.data.event.eventEndDate).format('YYYY-MM-DD HH:mm:ss')) {
        let tempEvent: CreateEventQ = JSON.parse(JSON.stringify(eventQ));
        let originalEventQ: CreateEventQ = JSON.parse(JSON.stringify(eventQ));

        tempEvent.lessonId = LessonIdEnum.AVAILABLE;
        tempEvent.eventEndDate = moment(eventQ.eventStartDate).format('YYYY-MM-DD HH:mm:ss');
        tempEvent.eventStartDate = moment(this.data.event.eventStartDate).format('YYYY-MM-DD HH:mm:ss');
        tempEvent.endHours = moment(eventQ.eventStartDate).format('HH:mm');
        tempEvent.startHours = moment(this.data.event.eventStartDate).format('HH:mm');
        tempEvent.eventName = this.translocoService!.translate('teacher.calendar.event.available');
        tempEvent.eventId = undefined;
        tempEvent.isAccepted = true;

        originalEventQ.isAccepted = false;

        eventsResult.push(tempEvent);
        eventsResult.push(originalEventQ);
      }
      else if (
        moment(eventQ.eventStartDate).format('YYYY-MM-DD HH:mm:ss') >= moment(this.data.event.eventStartDate).format('YYYY-MM-DD HH:mm:ss') &&
        moment(eventQ.eventEndDate).format('YYYY-MM-DD HH:mm:ss') <= moment(this.data.event.eventEndDate).format('YYYY-MM-DD HH:mm:ss')) {
        let tempEventAfterTime: CreateEventQ = JSON.parse(JSON.stringify(eventQ));
        let tempEventBeforeTime: CreateEventQ = JSON.parse(JSON.stringify(eventQ));
        let originalEventQ: CreateEventQ = JSON.parse(JSON.stringify(eventQ));

        tempEventAfterTime.lessonId = LessonIdEnum.AVAILABLE;
        tempEventAfterTime.eventStartDate = moment(eventQ.eventEndDate).format('YYYY-MM-DD HH:mm:ss');
        tempEventAfterTime.eventEndDate = moment(this.data.event.eventEndDate).format('YYYY-MM-DD HH:mm:ss');
        tempEventAfterTime.endHours = moment(this.data.event.eventEndDate).format('HH:mm');
        tempEventAfterTime.startHours = moment(eventQ.eventEndDate).format('HH:mm');
        tempEventAfterTime.eventName = this.translocoService!.translate('teacher.calendar.event.available');
        tempEventAfterTime.eventId = undefined;
        tempEventAfterTime.isAccepted = true;

        tempEventBeforeTime.lessonId = LessonIdEnum.AVAILABLE;
        tempEventBeforeTime.eventEndDate = moment(eventQ.eventStartDate).format('YYYY-MM-DD HH:mm:ss');
        tempEventBeforeTime.eventStartDate = moment(this.data.event.eventStartDate).format('YYYY-MM-DD HH:mm:ss');
        tempEventBeforeTime.endHours = moment(eventQ.eventStartDate).format('HH:mm');
        tempEventBeforeTime.startHours = moment(this.data.event.eventStartDate).format('HH:mm');
        tempEventBeforeTime.eventName = this.translocoService!.translate('teacher.calendar.event.available');
        tempEventBeforeTime.eventId = undefined;
        tempEventBeforeTime.isAccepted = true;

        originalEventQ.isAccepted = false;

        eventsResult.push(tempEventAfterTime);
        eventsResult.push(tempEventBeforeTime);
        eventsResult.push(originalEventQ);
      }
    } else {
      eventsResult.push(eventQ);
    }

    if (eventsResult.length > 1) {
      this.store.dispatch(EventActions.createAvailableEvents({ createEventQ: eventsResult }));
    } else if (eventsResult.length === 1) {
      this.store.dispatch(EventActions.CreateEvent({ createEventQ: eventQ }));
    }
  }

  private patchEventValues(event: EventR): void {
    if (this.userData.roleId?.toString() === RoleEnum.TEACHER) {
      if (event.eventStartDate) {
        this.form.controls['clickedDate'].patchValue(moment(event.eventStartDate).format('YYYY-MM-DD'));
      }
      if (event.eventName) {
        this.form.controls['eventName'].patchValue(event.eventName);
      }
      if (event.startHours) {
        this.form.controls['startTime'].patchValue(moment(event.eventStartDate).format('HH:mm'));
      }
      if (event.endHours) {
        this.form.controls['endTime'].patchValue(moment(event.eventEndDate).format('HH:mm'));
      }
      if (event.selectedColor) {
        this.form.controls['selectedColor'].patchValue(event.selectedColor);
      }
      if (event.seatsNumber) {
        this.form.controls['seatsNumber'].patchValue(event.seatsNumber);
      }
      if (event.lessonId) {
        this.form.controls['lessonId'].patchValue(event.lessonId?.toString());
      }
      if (event.locationTypeId) {
        this.form.controls['locationTypeId'].patchValue(event.locationTypeId?.toString());
      }
      if (event.locationId) {
        this.form.controls['locationId'].patchValue(event.locationId?.toString());
      }
      if (event.price) {
        this.form.controls['price'].patchValue(event.price);
      }
      if (event.repeat) {
        this.form.controls['repeat'].patchValue(event.repeat ?? RepeatTypesEnum.NoRepeat);
      }
      if (event.timeZone) {
        this.form.controls['timeZone'].patchValue(event.timeZone);
      }
      if (event.eventDescription) {
        this.form.controls['eventDescription'].patchValue(event.eventDescription);
      }
    } else {
      if (event.eventStartDate) {
        this.form.controls['clickedDate'].patchValue(moment(event.eventStartDate).format('YYYY-MM-DD'));
      }
      if (event.eventName) {
        this.form.controls['eventName'].patchValue(event.eventName);
      }
      if (event.startHours) {
        this.form.controls['startTime'].patchValue(moment(event.eventStartDate).format('HH:mm'));
      }
      if (event.endHours) {
        this.form.controls['endTime'].patchValue(moment(event.eventEndDate).format('HH:mm'));
      }
      if (event.lessonId) {
        this.form.controls['lessonId'].patchValue(event.lessonId?.toString());
      }
      if (event.locationTypeId) {
        this.form.controls['locationTypeId'].patchValue(event.locationTypeId?.toString());
      }
      if (event.timeZone) {
        this.form.controls['timeZone'].patchValue(event.timeZone);
      }
    }

  }

  fillEventValues() {
    let eventQ: CreateEventQ = this.form.getRawValue();
    if (this.data && this.data.event && this.data.event.eventId) {
      eventQ.eventId = this.data.event.eventId;
    }
    if (this.form.controls['startTime'].value) {
      eventQ.startHours = this.form.controls['startTime'].value;
      eventQ.eventStartDate = this.utilService.createDateFromData(this.form.controls['startTime'].value, this.form.controls['clickedDate'].value);
    }
    if (this.form.controls['endTime'].value) {
      eventQ.endHours = this.form.controls['endTime'].value;
      eventQ.eventEndDate = this.utilService.createDateFromData(this.form.controls['endTime'].value, this.form.controls['clickedDate'].value);
    }
    eventQ.isAccepted = false;

    return eventQ;
  }

  private initTimeZones(): void {
    this.timeZones = moment.tz.names();
  }

  private formBuilder() {
    this.form = this.fb.group({
      clickedDate: new FormControl(moment().format('YYYY-MM-DD'), Validators.required),
      eventName: new FormControl(undefined, [Validators.required, Validators.maxLength(255), Validators.minLength(5)]),
      startTime: new FormControl(undefined, Validators.required),
      endTime: new FormControl(undefined, Validators.required),
      lessonId: new FormControl<number>(1, Validators.required),
      locationTypeId: new FormControl(undefined, Validators.required),
      repeat: new FormControl(RepeatTypesEnum.NoRepeat),
      timeZone: new FormControl(this.actualTimeZone, Validators.required),
    });

    if (this.data.event && this.data.event.lessonId === this.availableLessonId) {
      this.form.controls['clickedDate'].disable();
    }
    this.form.addValidators(eventDatesValidator(this.form.controls['startTime'], this.form.controls['endTime']));
  }

  subscriptionsInit() {
    this.userData = this.getUserDataFromStore(this.store);
    this.store.dispatch(LocationActions.getAllLocationTypes());
    this.store.dispatch(LessonActions.loadGetTeacherLessonsByTeacherId({ teacherId: this.teacherId }));

    this.subscription.add(
      this.form.controls['locationTypeId'].valueChanges.subscribe((value) => {
        if (this.userData.roleId?.toString() !== RoleEnum.STUDENT) {
          this.locationTypeChanged(Number(value));
        }
      }));
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onOk(): void {
    this.bookingClicked();
    this.dialogRef.close(true);
  }

  locationTypeChanged(selectedType: number) {
    this.store.dispatch(LocationActions.getAllLocationByTypeId({ locationTypeId: selectedType }));
  }
}
