export class TeacherAssessmentQ {
    teacherTextAssessment?: string;
    teacherNumberAssessment?: number;
    teacherId?: number;

    constructor(
        teacherTextAssessment: string,
        teacherNumberAssessment: number,
        teacherId: number
    ) {
        this.teacherTextAssessment = teacherTextAssessment;
        this.teacherNumberAssessment = teacherNumberAssessment;
        this.teacherId = teacherId
    }
}

export interface TeacherAssessmentR {
    teacherAssessmentId?: number;
    teacherTextAssessment?: string;
    teacherNumberAssessment: number;
    teacherId?: number;
    firstName?:string;
    lastName?:string;
    email?:string;
}