@if(ownTeacherSteps.length>0){
<div class="flex flex-wrap items-center mt-1 ml-2">
    <img src="../../../../assets/images/bulb.svg" class="h-6 w-6 mr-2 flex-none rounded-full" alt="bulb" loading="lazy">
    <span class="label-primary"> {{'first_steps.title' | transloco}}: {{getAllStepsCount()}}\{{getDoneStepsCount()}}
        {{'first_steps.done' | transloco}}
    </span>
</div>
<div class="flex items-center bg-light-green text-white text-sm font-bold rounded-md mt-2 px-1 py-1 ml-2" role="alert">
    <img src="./assets/images/information.svg" loading="lazy" alt="information" class="w-4 h-4 mr-2">
    <p>{{'first_steps.info' | transloco}}</p>
</div>
<ol class="items-center w-full mt-5 mb-5 ml-1  {{stepperDisplay}}">
    @for(step of ownTeacherSteps; track $index) {
    @if($index !== ownTeacherSteps.length-1){
    <li (click)="stepClicked(step.stepLink, $index+1)"
        class="flex w-full items-center text-white after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block">
        <span
            class="flex li items-center cursor-pointer justify-center w-10 h-10 bg-dark-green rounded-full lg:h-15 lg:w-36 shrink-0"
            [class.active]="$index+1 === currentStep">
            @if(!step.stepIsDone){
            <img src="./assets/images/stepper/{{$index+1}}.svg" class="h-6 w-6" loading="lazy" alt="stepper">
            }
            @else{
            <img src="./assets/images/stepper/7.svg" class="h-6 w-6" loading="lazy" alt="stepper">
            }
            <span class="m-2 content hidden md:hidden lg:block"> {{step.stepName}}</span>
        </span>
    </li>
    }@else{
    <li (click)="stepClicked(step.stepLink, $index+1)" class="flex items-center text-white w-full">
        <span
            class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-15 lg:w-36 dark:bg-gray-700 shrink-0">
            <span
                class="flex li items-center cursor-pointer justify-center w-10 h-10 bg-dark-green rounded-full lg:h-15 lg:w-36 shrink-0"
                [class.active]="$index+1 === currentStep">
                @if(!step.stepIsDone){
                <img src="./assets/images/stepper/{{$index+1}}.svg" class="h-6 w-6" loading="lazy" alt="stepper">
                }
                @else{
                <img src="./assets/images/stepper/7.svg" class="h-6 w-6" loading="lazy" alt="stepper">
                }
                <span class="m-2 content hidden md:hidden lg:block"> {{step.stepName}}</span>
            </span>
        </span>
    </li>
    }
    }
</ol>
}