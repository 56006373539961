import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { StepCardComponent } from './components/step-card/step-card.component';
import { StepCardsComponent } from './components/step-cards/step-cards.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormTitleComponent } from './components/bits/form-title/form-title.component';
import { IconButtonComponent } from './components/bits/icon-button/icon-button.component';
import { AttachmentFileComponent } from './components/attachment-file/attachment-file.component';
import { MainFilterCardComponent } from './components/main-filter-card/main-filter-card.component';
import { MainFilterCardsComponent } from './components/main-filter-cards/main-filter-cards.component';
import { SocialMediaListComponentComponent } from '../teacher/components/social-media-list-component/social-media-list-component.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormErrorPipe } from './pipes/form-error.pipe';
import { StepService } from './services/step/step-service';
import { TranslocoService } from '@ngneat/transloco';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DaysComponent } from './components/days/days.component';
import { PaginationComponent } from './components/pagination-component/pagination.component';
import { ScrollspyComponent } from './components/scrollspy/scrollspy.component';
import { StarSummaryRatingComponent } from './components/star-summary-rating/star-summary-rating.component';
import { LandingSocialComponent } from './components/landing-social/landing-social.component';
import { LandingCountsComponent } from './components/landing-counts/landing-counts.component';

@NgModule({
    declarations: [
        StepCardComponent,
        StepCardsComponent,
        FormTitleComponent,
        IconButtonComponent,
        AttachmentFileComponent,
        MainFilterCardsComponent,
        MainFilterCardComponent,
        SocialMediaListComponentComponent,
        FormErrorPipe,
        DaysComponent,
        ScrollspyComponent,
        StarSummaryRatingComponent,
        LandingSocialComponent,
        LandingCountsComponent
        ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        TranslocoModule,
        DragDropModule,
        PaginationComponent
        ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        TranslocoModule,
        StepCardComponent,
        StepCardsComponent,
        FormTitleComponent,
        IconButtonComponent,
        AttachmentFileComponent,
        MainFilterCardsComponent,
        MainFilterCardComponent,
        SocialMediaListComponentComponent,
        MatFormFieldModule,
        MatInputModule,
        FormErrorPipe,
        DragDropModule,
        DaysComponent,
        PaginationComponent,
        ScrollspyComponent,
        StarSummaryRatingComponent,
        LandingSocialComponent,
        LandingCountsComponent
        ],
    providers: [StepService, TranslocoService, NgxImageCompressService]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
