import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TeacherByFilterDTO } from '../../../teacher/models/teacher/teacher.model';

@Component({
  selector: 'teacher-cards-by-filter',
  templateUrl: './teacher-cards-by-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeacherCardsByFilterComponent {
  @Input()
  teachers:TeacherByFilterDTO[] = [];

}
