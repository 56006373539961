import { SeededTeachersCardsModel } from "src/app/shared/models/seeded-teachers-cards/seeded-teacher-cards.model";
import { SendEmailModel } from "../../../../shared/models/email/send-email-to-students.model";
import { EventR } from "../../../../shared/models/event/event.model";
import { TeacherAssessmentR } from "../../../../teacher/models/teacher/teacher-assessment.model";
import { TeacherSocialR } from "../../../../teacher/models/teacher/teacher-social.model";
import { TeacherByFilterDTO, TeacherR, TeacherTotalRatingR } from "../../../../teacher/models/teacher/teacher.model";
import { GetTeacherByIdDTO } from "src/app/shared/services/user/user.model";

export const TEACHER_FEATURE_KEY = 'TEACHER';

export interface State {
    loaded: boolean;
    error?: string | null;
    data: Date[] | null | EventR[] | TeacherTotalRatingR| TeacherByFilterDTO|TeacherAssessmentR[]|GetTeacherByIdDTO|number|TeacherR[]|TeacherSocialR|TeacherAssessmentR|number[]| SendEmailModel|SeededTeachersCardsModel[];
    teachersSuccessfulLessonsNumber?:number;
    registeredTeachersCount?:number;
    allCategoriesCount?:number;
    teacherById?:GetTeacherByIdDTO;
    teacherAssessments?: TeacherAssessmentR[];
}