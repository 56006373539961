import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { SendMessageComponent } from '../../../shared/components/send-message-component/send-message-component.component';
import { TOAST_STATE, ToastService } from '../../../shared/services/toast/toast.service';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'send-teacher-assessment-modal',
  templateUrl: 'send-teacher-assessment-modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, SendMessageComponent, DragDropModule]
})
export class SendTeacherAssessmentModalComponent extends BaseComponent implements OnDestroy {
  title!: string;
  override form!: FormGroup;
  starRatingValue = 0;
  teacherId = 0;

  constructor(
    public dialogRef: MatDialogRef<SendTeacherAssessmentModalComponent>,
    private route: ActivatedRoute,
    override toastService: ToastService,
    override store: Store,
    override action$?: Actions
  ) {
    super(store, toastService, action$)
    action$?.pipe(ofType(TeacherActions.insertTeacherAssessmentSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.teacherAssessmentId) {
        if (response.data.teacherAssessmentId > 0) {
          this.onCancel();
          this.toastService.showToast(TOAST_STATE.success, "Sikeres értékelés!");
          this.store.dispatch(TeacherActions.updateTeacherTotalRatings({ teacherId: this.teacherId }))
        }
        else {
          this.onCancel();
          this.toastService.showToast(TOAST_STATE.warning, "Hiba az értékelés során!");
        }
      } else if (response.data.teacherAssessmentId) {
        this.onCancel();
        this.toastService.showToast(TOAST_STATE.success, "Sikeresen frissítettük az értékelésed!");
      }
    })
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
