import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { UserDocumentR, UserDocumentUpdateQ } from "../../../shared/services/user/user.model";

@Injectable({
    providedIn: 'root'
})
export class TeacherDocumentService {

    constructor(private http: HttpClient) {

    }

    getTeacherDocuments() {
        return this.http.get<UserDocumentR[]>(Config.baseUrl + 'teacherdocument/getdocumentsbyid');
    }

    updateTeacherDocument(document: UserDocumentUpdateQ) {
        return this.http.post<number>(Config.baseUrl + 'teacherdocument/updatedocument',document);
    }

    deleteTeacherDocument(id: number) {
        return this.http.post<number>(Config.baseUrl + 'teacherdocument/deletedocument',id);
    }
}