import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'icon-button',
    template: `
        <button type="button" class="btn-primary" (click)="onClick()">
        <img src="../../../../../assets/images/plus.svg" class="w-6 h-6" loading="lazy" alt="plus">
        <span>{{label}}</span>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
    @Input() label!: string;
    @Output() hit = new EventEmitter<void>();

    onClick() {
        this.hit.emit();
    }
}
