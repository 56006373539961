import { OfflineLocation } from "../../../../teacher/models/locations/offline-location.model";
import { OnlineLocation } from "../../../../teacher/models/locations/online-location.model";


export const LOCATION_FEATURE_KEY = 'LOCATION';

export interface State{
    loaded:boolean;
    error?:string | null;
    data: OnlineLocation[] | OfflineLocation[] | null |number|OnlineLocation|OfflineLocation;
}