import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./admin.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as AdminActions from './admin.action';
import { GetAllTeacherDocumentsDTO } from "../../../../shared/services/user/user.model";

export const AdminAdapter: EntityAdapter<GetAllTeacherDocumentsDTO> =
    createEntityAdapter<GetAllTeacherDocumentsDTO>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const AdminReducer = createReducer(
    initialState,
    on(AdminActions.init, () =>
        ({ ...initialState })
    ),
    on(
        AdminActions.getAllTeachersDocumentsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        AdminActions.updateTeacherVerifySuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        AdminActions.getRequestedLessonsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        AdminActions.insertLessonFromTempSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        AdminActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action) {
    return AdminReducer(state, aciton);
}