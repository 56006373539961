<li class="border border-l-indigo-100 rounded-md">
  <div class="flex items-center">
    @if(student.profilePicture !==''){
    <img class="h-16 w-16 rounded-full" [src]="student.picture" alt="pic" loading="lazy">
    }@else{
    <img class="h-16 w-16 rounded-full" src="./assets/images/profile_anonym.svg" alt="pic" loading="lazy">
    }
    <div>
      <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-600">{{student.lastName}}
        {{student.firstName}}</h3>
      <p class="text-sm font-semibold leading-6 text-dark-green">{{student.email}}</p>
      @if(student.isStudentFirstLesson){
        <div class="items-center">
          <img class="h-6 w-6" src="../../../../assets/images/first_lesson_b.svg" alt="first_lesson" loading="lazy">
          <p class="text-xs font-semibold tracking-tight text-gray-600">{{'common.first_lesson' |transloco}}</p>
        </div>
        }
    </div>
  </div>
</li>