<div class="grid grid-cols-4 lgrid-rows-1 gap-4">
  @if(teacherSocialR.facebookLink && teacherSocialR.facebookLink !== ''){
    <a href="{{teacherSocialR.facebookLink}}" target="_blank" role="button">
      <img src="../../../../assets/images/social-form/facebook.svg" class="btn-icon w-8 h-8" loading="lazy" alt="facebook">
    </a>
  }
  @if(teacherSocialR.instagramLink && teacherSocialR.instagramLink !== ''){
    <a href="{{teacherSocialR.instagramLink}}" target="_blank" role="button">
      <img src="../../../../assets/images/social-form/insta.svg" class="btn-icon w-8 h-8" loading="lazy" alt="insta">
    </a>
  }
  @if(teacherSocialR.tikTokLink && teacherSocialR.tikTokLink !== ''){
    <a href="{{teacherSocialR.tikTokLink}}" target="_blank" role="button">
      <img src="../../../../assets/images/social-form/tiktok.svg" class="btn-icon w-8 h-8" loading="lazy" alt="tiktok">
    </a>
  }
  @if(teacherSocialR.youtubeLink && teacherSocialR.youtubeLink !== ''){
    <a href="{{teacherSocialR.youtubeLink}}" target="_blank" role="button">
      <img src="../../../../assets/images/social-form/youtube.svg" class="btn-icon w-8 h-8" loading="lazy" alt="youtube">
    </a>
  }
</div>