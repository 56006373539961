import { Component } from '@angular/core';

@Component({
  selector: 'app-aszf',
  standalone: true,
  imports: [],
  templateUrl: './aszf.component.html',
  styleUrl: './aszf.component.css'
})
export class AszfComponent {
  hu = `
  <html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1250">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List
href="Általános%20Szerződési%20Feltételek_ORAFOGLALO_v2_elemei/filelist.xml">
<!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>Dávid Venter</o:Author>
  <o:Template>Normal</o:Template>
  <o:LastAuthor>Csete Patrik</o:LastAuthor>
  <o:Revision>2</o:Revision>
  <o:TotalTime>147</o:TotalTime>
  <o:Created>2024-02-26T14:40:00Z</o:Created>
  <o:LastSaved>2024-02-26T14:40:00Z</o:LastSaved>
  <o:Pages>12</o:Pages>
  <o:Words>3075</o:Words>
  <o:Characters>21224</o:Characters>
  <o:Lines>176</o:Lines>
  <o:Paragraphs>48</o:Paragraphs>
  <o:CharactersWithSpaces>24251</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
 <o:OfficeDocumentSettings>
  <o:AllowPNG/>
 </o:OfficeDocumentSettings>
</xml><![endif]-->
<link rel=themeData
href="Általános%20Szerződési%20Feltételek_ORAFOGLALO_v2_elemei/themedata.thmx">
<link rel=colorSchemeMapping
href="Általános%20Szerződési%20Feltételek_ORAFOGLALO_v2_elemei/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:HyphenationZone>21</w:HyphenationZone>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>HU</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;
	mso-font-charset:2;
	mso-generic-font-family:auto;
	mso-font-pitch:variable;
	mso-font-signature:0 268435456 0 0 -2147483648 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:238;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;
	mso-font-charset:238;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1040178053 9 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
a:link, span.MsoHyperlink
	{mso-style-priority:99;
	color:#0563C1;
	mso-themecolor:hyperlink;
	text-decoration:underline;
	text-underline:single;}
a:visited, span.MsoHyperlinkFollowed
	{mso-style-noshow:yes;
	mso-style-priority:99;
	color:#954F72;
	mso-themecolor:followedhyperlink;
	text-decoration:underline;
	text-underline:single;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
span.GramE
	{mso-style-name:"";
	mso-gram-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;}
.MsoPapDefault
	{mso-style-type:export-only;
	margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 70.85pt 70.85pt 70.85pt;
	mso-header-margin:35.4pt;
	mso-footer-margin:35.4pt;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:49692513;
	mso-list-type:hybrid;
	mso-list-template-ids:-924796262 68026383 68026393 68026395 68026383 68026393 68026395 68026383 68026393 68026395;}
@list l0:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l0:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l0:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l0:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l0:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l0:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l0:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l0:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l0:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l1
	{mso-list-id:362442980;
	mso-list-type:hybrid;
	mso-list-template-ids:1500011552 68026383 68026393 68026395 68026383 68026393 68026395 68026383 68026393 68026395;}
@list l1:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l1:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l1:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l1:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l1:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l1:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l1:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l1:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l1:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l2
	{mso-list-id:761799981;
	mso-list-type:hybrid;
	mso-list-template-ids:1410754918 1802272270 68026393 68026395 68026383 68026393 68026395 68026383 68026393 68026395;}
@list l2:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	mso-ansi-font-weight:bold;}
@list l2:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l2:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l2:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l2:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l2:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l2:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l2:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l2:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l3
	{mso-list-id:1247230153;
	mso-list-type:hybrid;
	mso-list-template-ids:490380026 68026383 68026393 68026395 68026383 68026393 68026395 68026383 68026393 68026395;}
@list l3:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l3:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l3:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l3:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l3:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l3:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l3:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l3:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l3:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l4
	{mso-list-id:1555195440;
	mso-list-type:hybrid;
	mso-list-template-ids:1684801234 602708280 68026393 68026395 68026383 68026393 68026395 68026383 68026393 68026395;}
@list l4:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-weight:bold;}
@list l4:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l4:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l4:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l4:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l4:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l4:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l4:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l4:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l5
	{mso-list-id:1644579596;
	mso-list-type:hybrid;
	mso-list-template-ids:-1947055322 -855625496 68026393 68026395 68026383 68026393 68026395 68026383 68026393 68026395;}
@list l5:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-weight:bold;}
@list l5:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l5:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l5:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l5:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l5:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l5:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l5:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l5:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l6
	{mso-list-id:1803157577;
	mso-list-template-ids:1219019424;}
@list l6:level1
	{mso-level-number-format:bullet;
	mso-level-text:\F0B7;
	mso-level-tab-stop:36.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Symbol;}
@list l6:level2
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:72.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:"Courier New";
	mso-bidi-font-family:"Times New Roman";}
@list l6:level3
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:108.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
@list l6:level4
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:144.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
@list l6:level5
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:180.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
@list l6:level6
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:216.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
@list l6:level7
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:252.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
@list l6:level8
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:288.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
@list l6:level9
	{mso-level-number-format:bullet;
	mso-level-text:\F0A7;
	mso-level-tab-stop:324.0pt;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	font-family:Wingdings;}
ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Normál táblázat";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:0cm;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=HU link="#0563C1" vlink="#954F72" style='tab-interval:35.4pt;
word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal><b><span style='font-size:16.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Általános Szerződési Feltételek<o:p></o:p></span></b></p>

<p class=MsoNormal><span style='font-size:14.0pt;line-height:107%;font-family:
"Times New Roman",serif'>Az Általános Szerződési Feltételek („ÁSZF”)
tartalmazzák Venter Dávid egyéni vállalkozó, mint szolgáltató (továbbiakban
„Szolgáltató”) által nyújtott elektronikus szolgáltatásokat igénybe vevő
Felhasználó (a továbbiakban: „Felhasználó”) jogait és kötelezettségeit.
(Szolgáltató és Felhasználó a továbbiakban együttesen: „Felek”).<o:p></o:p></span></p>

<p class=MsoNormal><span style='font-size:14.0pt;line-height:107%;font-family:
"Times New Roman",serif'>Jelen ÁSZF hatálya a https://www.orafoglalo.hu
weboldalon keresztül elérhető, illetve hozzáférhető szolgáltatások
igénybevételére és annak feltételeire terjed ki. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Tájékoztatjuk, hogy jelen ÁSZF kizárólag elektronikus formában,
folyamatosan elérhető és letölthető az alábbi linken:&nbsp;</span><a
href="https://orafoglalo.hu/aszf"><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
HU'>https://orafoglalo.hu/aszf</span></a><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Jelen ÁSZF rendelkezései 2024.02.21. napjától kezdődően hatályosak.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>A jelen ÁSZF-ben nem szabályozott kérdésekre, valamint jelen ÁSZF
értelmezésére a magyar jog az irányadó, különös tekintettel a Polgári
Törvénykönyvről szóló 2013. évi V. törvény (továbbiakban: „<b>Ptk.</b>”)
vonatkozó rendelkezései, azzal, hogy a vonatkozó jogszabályok kötelező
rendelkezései külön kikötés nélkül is irányadók a szolgáltatásokat igénybe
vevőkre.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Jelen ÁSZF alapján létrejött szerződés magyar nyelven íródik, kizárólag
elektronikus formában kerül megkötésre, nem minősül írásbeli szerződésnek, így
utólag nem hozzáférhető és megtekinthető, azt nem iktatjuk. Szolgáltatóként nem
vetjük alá magunkat magatartási kódex rendelkezéseinek.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Amennyiben Ön úgy dönt, hogy igénybe kívánja venni az általunk biztosított
szolgáltatásokat, kérjük figyelmesen olvassa el jelen ÁSZF-<span class=SpellE>et</span>.
Felhívjuk a figyelmet arra, hogy szolgáltatásainak igénybevételével Ön
elfogadja a jelen ÁSZF-ben foglalt feltételeket.<o:p></o:p></span></p>

<b><span style='font-size:14.0pt;line-height:107%;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-ansi-language:HU;
mso-fareast-language:HU;mso-bidi-language:AR-SA'><br clear=all
style='mso-special-character:line-break;page-break-before:always'>
</span></b>

<p class=MsoNormal><b><span style='font-size:14.0pt;line-height:107%;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Szolgáltató adatai</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Név: <span class=SpellE>Venter</span> Dávid egyéni vállalkozó<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Székhely: <a name="_Hlk158821067">2800 Tatabánya, Kőrösi Csoma Sándor tér
2. 3./10.</a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Levelezési cím: 2800 Tatabánya, Kőrösi Csoma Sándor tér 2. 3./10.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><u><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Adószám: <o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Képviselő neve: Venter Dávid EV<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Elérhetőség:&nbsp;</span><a href="mailto:info@orafoglalo.hu"><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:HU'>info@orafoglalo.hu</span></a><u><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:blue;mso-fareast-language:HU'><o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><u><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Bankszámlaszám: <o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Ügyfélszolgálat elérhetősége:</span><u><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:blue;mso-fareast-language:HU'> </span></u><a
href="mailto:info@orafoglalo.hu"><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
HU'>info@orafoglalo.hu</span></a><u><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:blue;
mso-fareast-language:HU'><o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Telefon: +36206624634<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>E-mail:&nbsp;</span><a href="mailto:info@orafoglalo.hu"><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:HU'>info@orafoglalo.hu</span></a><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:yellow;mso-highlight:yellow;
mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Ügyfélszolgálat nyitvatartási ideje: H-P: 09:00-17:00<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Tárhelyszolgáltató: <a name="_Hlk158822619"></a><span class=SpellE><span
style='mso-bookmark:_Hlk158822619'>Rackforest</span></span><span
style='mso-bookmark:_Hlk158822619'> Zrt.1132 Budapest, Victor Hugo utca 11., 5.
emelet<o:p></o:p></span></span></p>

<span style='mso-bookmark:_Hlk158822619'></span>

<p class=MsoListParagraph style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;margin-left:18.0pt;mso-add-space:auto;text-indent:-18.0pt;line-height:
normal;mso-list:l2 level1 lfo7'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'><span style='mso-list:Ignore'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'>Fogalmak</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Szolgáltatás:</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;a Szolgáltató által nyújtott minden olyan szolgáltatás, amelyet a
Felhasználó igénybe vehet a Honlapon keresztül.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Felhasználó:</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;az olyan egyéni vállalkozó, jogi személy vagy szervezet, aki vagy
amely az önálló foglalkozása, üzletszerű, vagy gazdasági tevékenysége ellátása
körében és érdekében igénybe veszi a Szolgáltatást, amely a fogyasztóvédelemről
szóló 1997. </span><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:HU'>évi CLV.
törvény („<span class=SpellE>Fgytv</span>.”) 2. § <s>b)</s> 33. pontja szerinti
vállalkozó lehet, azonban az <span class=SpellE>Fgytv</span>. 2. § <s>a)</s>
10. pontja szerinti fogyasztó nem lehet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Szerződő Felek, Felek:</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'>&nbsp;Szolgáltató és Felhasználó együttes megnevezése.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Fiók:</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó által a regisztráció során, azáltal létrehozott
felhasználói fiók, mely tartalmazza a Felhasználó által megadott adatokat.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Ügyfél:</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Azon természetes személy, jogi személy vagy jogi személyiséggel nem
rendelkező jogalany, aki a Honlapon keresztül időpontot foglal.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Szolgáltatási csomag:</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'>&nbsp;A Honlapon közzétett előfizetői szolgáltatási
csomagok, melyek különböző tartalommal bírnak, kondíciókkal rendelkeznek. Ezen
szolgáltatási csomagokra fizet elő a Felhasználó. A Felhasználónak lehetősége
van arra, hogy a Szolgáltatás fennállása alatt Szolgáltatási csomagot váltson.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Felhasználói felület:</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'>&nbsp;A Felhasználó rendelkezése alatt álló, a
számítógép, számítógépes program, operációs rendszer azon elemeinek összessége,
amelyek a Felhasználóval való kommunikációért felelősek, és a berendezés vagy
program irányítását, vezérlését lehetővé teszik.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Szolgáltatási időszak:</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'>&nbsp;A pénzügyileg rendezett előfizetési időszak,
melynek tartama alatt nyújtja a Szolgáltató az előfizetett Szolgáltatási
csomagot. A szolgáltatási időszak minden esetben az adott naptári naptól a
csomagban vásárolt időtartamra szól. Tartama a Felhasználó választása szerint: havi
/ féléves / éves lehet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>2. Regisztráció<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>2.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> A Felhasználó a Szolgáltatást regisztráció után tudja használni. A
Felhasználó a regisztráció során köteles megadni e-mail címét és jelszavát. A
regisztráció során meg<span style='mso-tab-count:1'>   </span>adott email cím
és jelszó a továbbiakban a Felhasználó belépési adatai lesznek.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>2.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> Aki jogi személy nevében regisztrál a Honlapon, felelősséggel tartozik
azért, hogy jogosultsága van-e a vállalkozás nevében regisztrálni, Szolgáltató
ezen jogosultság fennállását nem vizsgálja.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>2.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> A Felhasználó felelősséget vállal azért, hogy bármely adat, melyet a
regisztráció során megad, pontos, valós és teljes, a Szolgáltatót semmilyen
felelősség nem terheli azért, ha a Felhasználó által megadott adatok
pontatlanok, valótlanok vagy hiányosak. A Felhasználó az adataiban
bekövetkezett változásokat – a változás bekövetkezésétől számított 15 napon
belül – köteles a Honlapon módosítani. Ezen kötelezettség elmulasztásából eredő
károkért a teljes felelősség Felhasználót terheli, a Szolgáltató ilyen károkért
felelősséget nem vállal.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>2.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> A Szolgáltató fenntartja a jogot arra, hogy törölje azon Felhasználók
regisztrációját, akik megítélése szerint más személy nevével, képmásával,
e-mail címével, vagy más személyes adatával, illetve jogával visszaélnek, így
különösen ha tudomása szerint az érintett Felhasználói név jogi oltalom alatt
áll, más személy jogos érdekét sérti, trágár, vagy obszcén jellegű szavakat,
kifejezéseket használ, rasszista, vallási, nemzeti, nemzetiségi, szexuális
hovatartozást gyalázó, becsületsértő, burkolt, vagy nyílt reklámhordozónak
tekinthető.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>2.5.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> Amennyiben a Felhasználó tudomást szerez arról, hogy a regisztráció során
megadott <span class=SpellE>jelszavához</span> jogosulatlan harmadik személy
hozzájuthatott, köteles haladéktalanul megváltoztatni jelszavát, ha pedig
feltételezhető, hogy a harmadik személy a jelszó használatával bármilyen módon
visszaélt, köteles erről egyidejűleg értesíteni a Szolgáltatót. A fenti
kötelezettségek elmulasztásából eredő károkért kizárólag a Felhasználót terheli
a felelősség, a Szolgáltató ilyen esetekért felelősséget nem vállal. A Felhasználó
felelősséggel tartozik a saját <span class=SpellE>jelszavának</span>, vagy
fiókjának illetéktelen, jogosulatlan használatával, valamint az ehhez
kapcsolódó bejelentési kötelezettség elmulasztásával a Szolgáltatónak vagy
bármely harmadik személynek okozott károkért.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3. Előfizetés</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3.1. </span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>A Szolgáltató 7 napos ingyenes próbaidőt biztosít a Felhasználó számára. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> Amennyiben a Felhasználó az előfizetés mellett dönt, a Felhasználó a
Honlapon közzétett szolgáltatási csomagok közül választhat. A szolgáltatási
csomag és az előfizetéstől díját a Felhasználó azonnal köteles megfizetni.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Amennyiben a próbaidő végén a Felhasználó nem kíván előfizetni a
szolgáltatási csomagok valamelyikére, fiókját a Szolgáltató felfüggeszti, majd fél
évet követően törli.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Amennyiben a felek a jelen ÁSZF rendelkezéseitől eltérően kívánnak
bármely kérdésről rendelkezni, úgy azt a Felhasználó és a Szolgáltató egy külön
szerződésben rendezi, mely tartalmazza a Szolgáltatási csomag pontos tartalmát,
árát, a Szolgáltatási csomagra vonatkozó funkciókat, valamint a Szolgáltatási
csomag részletes feltételeit is (a továbbiakban:&nbsp;<b>Egyedi szerződés</b>).<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3.5.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltatás, az egyes Szolgáltatási csomagok a Szolgáltatási
időszak Felhasználó által választott, és pénzügyileg rendezett tartamára állnak
fenn. A következő Szolgáltatási időszakra esedékes díj megfizetésével a jelen
ÁSZF szerinti szerződés automatikusan meghosszabbításra kerül a pénzügyileg
rendezett újabb Szolgáltatási időszak tartamával.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>3.6.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató jogosult új Szolgáltatási csomagokat bevezetni és a már
meglévő Szolgáltatási csomagokat megszüntetni. A Szolgáltató azon
Felhasználókat, akik egy megszűnő Szolgáltatási csomagot használnak,
automatikusan az általa kijelölt – hasonló tulajdonságokkal bíró - másik
szolgáltatási csomagba helyezi át a korábbi Szolgáltatási csomag pénzügyileg
rendezett lejártát követő naptól, és erről a Felhasználókat az általuk megadott
e-mail címen értesíti. Amennyiben a Felhasználó ezen áthelyezéssel nem ért
egyet, és egy másik meglévő Szolgáltatási csomagot kíván választani, ezt a
szándékát jelezheti a Szolgálatónak, és a Szolgáltató őt ebbe a csomagba
helyezi, illetőleg jogosult a Felhasználó felmondani a szerződést az
előfizetési idő végével.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4. Díjak megfizetése</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><i><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Órafoglaló szolgáltatásért járó díj megfizetése</span></i></b><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Honlap tartalmazza az egyes Szolgáltatási csomagok díjait és a
konkrét fizetési határidőket.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A szolgáltatási díjakat a Felhasználó a Szolgáltató által – a
Felhasználó által rendelkezésére bocsátott adatok alapján, melyekért
felelősséget vállal - kiállított számla ellenében köteles megfizetni. A díjak
megfizetése mindig a választott Szolgáltatási időszak elején történik.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4.3.</span></b><u><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A fizetés lehetséges módjai:<o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4.3.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Bankkártyás fizetés <span class=SpellE>Stripe-on</span> keresztül: A
vásárlásra szóló fizetési megbízással a Felhasználó (Kártyatulajdonos) megbízza
a Fizetési Szolgáltatót, hogy a szolgáltatás ellenértékét a Szolgáltató, mint
kedvezményezett javára juttassa el és számolja el. Sikeres fizetést követően a
Szolgáltató eljuttatja a számlát a Felhasználó számára.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4.3.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Ismétlődő fizetés <span class=SpellE>Stripe</span> <span
class=SpellE>Gatewayen</span> keresztül: a Felhasználó a megrendelés folyamán
hozzájárul, hogy a Szolgáltató a jövőben esedékessé váló összegekre ismétlődő
fizetést kezdeményezzen, és elfogadja, hogy ezzel előre meghatározott
időközönként, előre meghatározott összegekkel beterhelésre kerül majd a
bankkártyájához tartozó bankszámlaszám. Sikeres fizetést követően a Szolgáltató
eljuttatja a számlát a Felhasználó számára.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>4.3.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A 4.3.3. és 4.3.3. pontokban leírt online fizetést a <span
class=SpellE>Stripe</span> <span class=SpellE>Payment</span> Inc. (mint
Fizetési Szolgáltató) biztosítja. A Felhasználó által megadott bankkártya
adatokhoz a Szolgáltató nem fér hozzá. Fizetéskor a <span class=SpellE>Stripe</span>
Inc. jelenik meg a Felhasználó bankszámlakivonatán.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>5. Késedelmes díjfizetés, nemfizetés</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>5.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Amennyiben a Felhasználó a szolgáltatási csomag fizetési <span
class=SpellE>határidejének</span> lejártától számított 10 napon belül sem
fizeti meg a Szolgáltatás díját, tehát 10 naptári napot meghaladó fizetési
késedelembe esik, a Szolgáltató a Felhasználó Fiókját felfüggesztheti.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>5.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Amennyiben a Felhasználó a felfüggesztést követő 10 naptári napon
belül sem fizeti meg az esedékessé vált összeget, a Szolgáltató a jelen ÁSZF
szerinti szerződést felmondhatja, és a Fiókot annak teljes adattartalmával
együtt véglegesen törölheti, valamint a tartozás teljesítése, a követelése
megfizetése iránt jogi eljárást kezdeményezhet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>6. Szerzői jogok</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>6.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltatás szerzői jogvédelem alatt áll. A Szolgáltatáshoz
kapcsolható weboldalakon megjelenített, nem a Felhasználóktól származó valamennyi
tartalomnak és bármely szerzői műnek, illetve más szellemi alkotásnak a
Szolgáltató a szerzői jogi jogosultja vagy a feljogosított felhasználója
(ideértve többek közt valamennyi grafikát és egyéb anyagokat, a Szolgáltatáshoz
kapcsolható weboldalak elrendezését, szerkesztését, a használt szoftveres és
egyéb megoldásokat, ötletet, megvalósítást).<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>6.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató valamennyi, a Szolgáltatáshoz kapcsolható weboldalakon
szereplő, nem a Felhasználótól származó védjegy (márkanév, <span class=SpellE>logo</span>
stb.) használatára jogosult. A regisztráció, a Szolgáltatás használata, illetve
az ÁSZF egyetlen rendelkezése sem biztosít jogot a Felhasználónak a
Szolgáltatáshoz kapcsolható weboldalakon szereplő bármely kereskedelmi névnek
vagy védjegynek, más oltalom alatt álló megjelölésnek bármely használatára,
hasznosítására.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>6.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó a Felek közötti jogviszony fennállásához kötött
határozott idejű, nem kizárólagos, a szolgáltatás teljesítése céljából
átruházható felhasználási jogot szerez <span class=GramE>a</span> Órafoglaló
szolgáltatásra vonatkozóan, és azt csak a jelen ÁSZF céljával összefüggésben
jogosult felhasználni. A felhasználási jog kiterjed különösen a többszörözésre,
valamint harmadik személy részére felhasználási jog engedésére. A harmadik
személy felhasználási joga kizárólag az Órafoglaló szolgáltatás
felhasználásával készült hirdetési felület és egyben időpontfoglaló rendszer
használatára jogosítja az Ügyfelet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>6.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltatás rendeltetésszerű használatával járó megjelenítésen, az
ehhez szükséges ideiglenes többszörözésen és a magáncélú másolatkészítésen túl
ezen szellemi alkotások a Szolgáltató előzetes írásbeli engedélye nélkül
semmilyen egyéb formában nem használhatók fel vagy hasznosíthatók, így
különösen nem tölthetőek le, elektronikusan nem tárolhatóak, nem dolgozhatóak
fel, és nem értékesíthetőek.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7. Felhasználó kötelezettségei</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> A Felhasználó köteles gondoskodni arról, hogy a Szolgáltatás használata
során harmadik személyek jogait se közvetlenül, se közvetett módon ne érje
sérelem, továbbá arról, hogy a jogszabályi rendelkezések maradéktalanul
betartásra kerüljenek.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Felhasználó felelős azért, hogy az Órafoglaló felületén megjelenített
szabad időpontok és elérhetőség a valóságnak megfelelnek, ezen adatok
helytállóságáért a Szolgáltató felelősséget nem vállal sem a Felhasználó, sem
Felhasználó Ügyfelei irányába. Amennyiben valamilyen oknál fogva egy foglalás
mégsem lehetséges, vagy a foglalási időpont megváltozik, a Felhasználó köteles
ezt jelezni Ügyfeleinek, és a változtatás érdekében a szükséges lépéseket
megtenni. Ezen intézkedések elmaradásából eredő károkért Szolgáltató
felelősséget nem vállal.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó nem oszthat meg, nem tölthet fel, nem továbbíthat, nem
küldhet, és egyéb módon sem tehet harmadik személyek számára elérhetővé olyan
tartalmat, mely jogellenes, káros, vulgáris, obszcén, fenyegető vagy sértő
tartalmú, illetve mellyel mások magánélethez fűződő vagy bármely személyhez
fűződő jogát, más jogát, jogos érdekét sérti vagy veszélyezteti, továbbá
védjegyoltalom, iparjogvédelmi oltalom vagy más oltalom alatt áll.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó nem oszthat meg, nem tölthet fel, nem továbbíthat, nem
küldhet, és egyéb módon sem tehet harmadik személyek számára elérhetővé olyan
tartalmat, mely számítógépes vírust, férget, vagy más, káros természetű kódot
tartalmaz.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7.5.&nbsp;</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>A Felhasználó nem oszthat meg, nem tölthet fel, nem továbbíthat, nem
küldhet, és egyéb módon sem tehet harmadik személyek számára elérhetővé olyan
marketing vagy promóciós célú tartalmat, melyhez a harmadik személy nem járult
hozzá (spam, <span class=SpellE>affiliate</span> link, hírlevél).<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>7.6.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó teljes mértékben maga felelős az ezen 7. pontot sértő
magatartásért. Szolgáltató nem felelős azon tartalmakért sem, melyeket
Felhasználó a Szolgáltatás igénybevételével közöl a saját fogyasztóival,
felhasználóival.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>8. Üzemeltetésre vonatkozó rendelkezések</span></b><span style='font-size:
14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>8.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A rendszer folyamatosan, az év minden napján, napi 24 órán keresztül
üzemel, legalább 99 %-os rendelkezésre állást biztosítva. Ez azt jelenti, hogy
a szolgáltatást Felhasználó és Ügyfél bármikor használhatják, figyelemmel az
üzemeltetéssel kapcsolatosan esetlegesen felmerülő korlátozásokra, így az
üzemszünetre, karbantartásra és hibaelhárításra. Üzemeltető ugyanakkor kizárja
a felelősségét, amennyiben a jelen ÁSZF-ben meghatározott rendelkezésre állást
nem áll módjában folyamatosan biztosítania.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>8.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Szolgáltató célja, hogy a rendszer folyamatosan és megfelelő módon <span
class=SpellE>működjön</span> és ennek érdekében mindent elkövet, hogy a
rendszer a lehető legrövidebb leállásokkal, üzemszünetekkel funkcionáljon és a
szolgáltatás mielőbb újra üzemelhessen, így minél kevesebb esetben merüljön fel
a működőképességével kapcsolatban probléma.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>8.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Szolgáltató a rendszer folyamatos és megfelelő működőképességének
fenntartása érdekében időszakos karbantartást végez. Szolgáltató a karbantartás
tervezett időpontjáról a karbantartást megelőzően legalább 1 nappal értesíti
Felhasználót.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>8.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Az üzemszünet során megkísérelt, de emiatt nem teljesített
időpontfoglalások, illetve az ezzel kapcsolatos károk és igények tekintetében
Szolgáltató kizárja felelősségét.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>9. A szerződés megszüntetése</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>9.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;(tudja magát törölni véglegesen, nem kell írni senkinek- érvényes
előfizetésnél így járt, pénzt nem adunk vissza) A Felhasználó a Szolgáltató
részére azt az </span><a href="mailto:info@orafoglalo.hu"><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:HU'>info@orafoglalo.hu</span></a><span
style='font-size:14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black;mso-fareast-language:HU'> email címre megküldött értesítéssel
bármikor, 15 napos felmondási idővel felmondhatja a jelen ÁSZF szerinti
szerződést, kérheti előfizetése és regisztrációja törlését. Az előfizetés és
regisztráció törlése a már megkezdett szolgáltatási időszak díjának megfizetése
alól nem mentesít.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>9.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató fenntartja magának a jogot, hogy amennyiben egy
Felhasználó az ÁSZF-ben foglaltakat megszegi, vagy a szolgáltatást <span
class=SpellE>visszaélésszerűen</span> használja, a Felhasználó szolgáltatáshoz
történő hozzáférését előzetes figyelmeztetés vagy tájékoztatás nélkül
végérvényesen korlátozza, regisztrációját az összes kapcsolódó adattal együtt
törölje. Amennyiben a Szolgáltató úgy dönt, hogy él ezzel a jogával, az
azonnali hatályú felmondásának minősül és a Felhasználó az általa megfizetett
díj arányos részének visszakövetelésére nem jogosult.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>9.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Szolgáltató a már befizetett díjakat nem téríti vissza sem a
Felhasználó, sem a Szolgáltató oldaláról történő felmondás esetében.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>10. A szolgáltatás megváltoztatása</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>10.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató fenntartja a jogot arra, hogy bármikor, bármilyen
változtatást, javítást hajtson végre a Szolgáltatáson, a Felhasználó előzetes
figyelmeztetése nélkül, ilyen változásokról a Szolgáltató utólag köteles
tájékoztatni a Felhasználókat&nbsp;<b>e-mail útján</b>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>10.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató fenntartja a jogot arra, hogy a Szolgáltatást más <span
class=SpellE>domain</span>-név alá helyezze át, mely áthelyezésről a
Szolgáltató írásban értesíti a Felhasználókat.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>11. Adatbeviteli hibák javítása</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>11.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'> Amennyiben a Felhasználó által a regisztráció során megadott adatokban
változás történik, azt köteles a Felhasználó 8 munkanapon belül köteles
javítani, vagy írásos formában a Szolgáltató részére az alábbi e-mail címen jelezni
</span><a href="mailto:info@orafoglalo.hu"><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-fareast-language:HU'>info@orafoglalo.hu</span></a><span style='font-size:
14.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'> . Amennyiben a Felhasználó az
adatváltozással kapcsolatos jelzési kötelezettségének a fenti határidőben nem
tesz eleget, úgy a Szolgáltató nem felel a Felhasználót ért azon károkért,
amelyek az adatváltozási kötelezettség bejelentésének elmulasztásából erednek.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>11.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Az adatokkal kapcsolatos jelzést követően a Szolgáltató módosítja a
megváltozott adatokat, azzal kapcsolatosan további teendője a Felhasználónak
nincsen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>12. Panaszügyintézés</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>A Szolgáltató a tevékenységével kapcsolatos Felhasználói kifogásra az </span><a
href="mailto:info@orafoglalo.hu"><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
HU'>info@orafoglalo.hu</span></a><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'> email címre történt előterjesztést követő legkésőbb 7
munkanapon belül érdemben válaszol, megjelölve a jogérvényesítés lehetősége
esetén a jogérvényesítés lehetséges helyét, módját, valamint az arra nyitva
álló határidőket.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13. Felelősségi szabályok</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó elfogadja azt, hogy a Szolgáltatást kizárólag saját felelősségére
használja. A Szolgáltató a Szolgáltatást az általa meghatározott módon és
rendelkezésre állással biztosítja.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató nem felel azért, hogy a Felhasználó által kívánt üzleti
célt a Felhasználó a Szolgáltatással eléri-e vagy sem, valamint azért sem, hogy
a Felhasználó milyen üzleti haszonra tesz szert a Szolgáltatás eredményeképpen.
A Szolgáltató nem garantálja azt Felhasználónak, hogy a Szolgáltatást a
Felhasználó saját Ügyfelei használni fogják, illetőleg, hogy azon keresztül új
Ügyfelekre, több megrendelésre tesz szert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltató nem felelős a Szolgáltatásban beálló, önhibáján kívüli,
időszakos üzemzavarért, illetve az ebből eredő kárért, elmaradt bevételért,
elmaradt haszonért. A Szolgáltató kötelezettséget vállal arra, hogy amint ilyen
üzemzavart érzékel, annak tényét azonnal jelzi a Felhasználónak, továbbá 15
napon belül kivizsgálja a hiba okát, és intézkedik kijavítása érdekében.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Bármely információ, adat, fájl, melyet a Felhasználó a Szolgáltatás
használata során letölt vagy melyhez más módon hozzájut, kizárólag a
Felhasználó saját felelősségére érhető el és használható, ezen adatokért
Szolgáltató felelősséget nem vállal.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13.5.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Szolgáltatónak a jelen ÁSZF alapján támasztott bármilyen,
kötelezettségvállalása arra az összegre korlátozódik, amelyet a Felhasználó a
Szolgáltatás használatáért a Szolgáltatónak kifizetett. A Szolgáltató semmilyen
esetben nem felelős a szerződés megkötésének időpontjában az ésszerű módon
előre nem látható veszteségekért vagy károkért.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>13.6.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felhasználó egyedül felelős minden olyan kárért, mely a
Szolgáltatói szerződés vagy a jelen ÁSZF, különösen annak 4. pontja megsértése
esetén keletkezik. Amennyiben harmadik személy a Szolgáltatóval szemben
érvényesít igényt, a Felhasználó köteles mentesíteni a Szolgáltatót minden
ilyen igény alól, peres eljárás során a perbe belépni, a tartozást
Szolgáltatótól átvállalni, illetve, amennyiben ez nem lehetséges, akkor utólag
megtéríteni Szolgálatónak a harmadik személy által ellene érvényesített igényeket.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>14. Kapcsolattartás</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>14.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A jogviszonnyal kapcsolatban a kapcsolattartás elsődlegesen
e-mailben, elektronikus úton történik, ezért a hibásan megadott, vagy nem valós
e-mail címből eredő következményekért Szolgáltató nem felel.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>14.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Jelen ÁSZF teljesítése során a regisztrációkor megadott e-mail címre
történő elektronikus levélküldés írásbelinek minősül.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>14.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A jelen ÁSZF teljesítésével összefüggésben egymásnak e-mail útján
megküldött nyilatkozatok a küldést követő 3. (harmadik) munkanapon
kézbesítettnek tekintendők, amennyiben nem merült fel az elektronikus
kézbesítést meghiúsító akadály.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>15. Joghatóság, alkalmazandó jog</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>15.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A jelen ÁSZF-re, a Szolgáltatói szerződésre, illetve az ezen
szerződésekkel kapcsolatban támadt bármely jogvita elbírálására a magyar jog az
irányadó.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>15.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A Felek a magyar bíróságok joghatóságának vetik alá magukat. A jelen
ÁSZF alapján keletkező jogvita elbírálására a felek kikötik a Tatabányai Járásbíróság
kizárólagos illetékességét, amennyiben pedig az a vonatkozó hatásköri szabályok
alapján nem lehetséges, a hatáskörrel bíró, illetékes magyar bíróságok járnak
el az ügyben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>17. Érvénytelenségi klauzula</span></b><span style='font-size:14.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>Amennyiben a jelen ÁSZF bármely rendelkezése érvénytelen vagy
végrehajthatatlan, vagy azzá válik, az a többi rendelkezést nem érinti, azok
érvényben és hatályban maradnak. Ebben az esetben a Feleknek az
érvénytelen/végrehajthatatlan rendelkezés helyett el kell fogadniuk egy, az
érvénytelen vagy végrehajthatatlan rendelkezéshez hasonló olyan rendelkezést,
amely leginkább összhangban áll a Felek szerződési céljaival, és a korábbi,
érvénytelennek vagy végrehajthatatlannak bizonyult rendelkezéshez a legközelebb
áll tartalmában.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18. Záró rendelkezések</span></b><span style='font-size:14.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black;
mso-fareast-language:HU'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18.1.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Jelen ÁSZF minden rendelkezése önállóan értelmezendő. Abban az
esetben, ha valamely bíróság vagy hatóság döntéséből eredően egyes részei a
továbbiakban nem alkalmazandóak, a fennmaradó rendelkezések továbbra is
érvényesek és joghatás kiváltására alkalmasak maradnak.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18.2.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Felhasználó nem minősül fogyasztónak, így a fogyasztóvédelmi
jogszabályok jelen szerződéses viszonyra nem irányadóak.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18.3.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Jelen ÁSZF-ben nem szabályozott kérdésekben a Polgári Törvénykönyvről
szóló 2013. évi V. törvény rendelkezései az irányadóak.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18.4.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;A jelen ÁSZF szerinti szerződés a Felek között az ÁSZF Felhasználó
általi elfogadásával jön létre.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18.5.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Szolgáltató fenntartja a jogot jelen ÁSZF rendelkezéseinek egyoldalú
módosítására, mely változásokról a módosított ÁSZF hatálybalépést megelőző 15
nappal tájékoztatja a Felhasználót, megjelölve az ÁSZF módosuló részeit, és a
módosított ÁSZF elérhetőségét, valamint a módosítás hatályba lépésének napját
is.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>18.6.</span></b><span style='font-size:14.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
HU'>&nbsp;Nem vonatkozik a 15 napos határidő az újonnan bevezetésre kerülő
szolgáltatásokra. Nem minősül az ÁSZF módosításának az, ha a Szolgáltató az
ÁSZF-ben esetlegesen előforduló elütéseket, helyesírási hibákat korrigál, vagy
olyan pontosításokat eszközöl, amelyek az ÁSZF szerint létrejövő szerződés
teljesítésére, annak bármely elemére nincsenek releváns kihatással. Nem minősül
az ÁSZF módosításának az sem, ha a Szolgáltató saját magára vonatkozó adatot
korrigál. Az így korrigált ÁSZF közlése, továbbá azon módosított ÁSZF közlése,
amely esetben a jogszabályi változás, hatósági aktus, illetve állásfoglalás,
vagy egyébként jogszabályból folyó kötelezettség teljesítése tette szükségessé
a módosítást, az ÁSZF-ben egyébként irányadó 15 napos határidőnél rövidebb is
lehet. Felhasználó mindezt kifejezetten tudomásul veszi és elfogadja.<o:p></o:p></span></p>

</div>

</body>

</html>

`
}
