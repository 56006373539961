import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";

@Injectable({
    providedIn: 'root'
})
export class NewsLetterService {

    constructor(private http: HttpClient) {

    }

    insertNewsLetterSubscriber(email:string) {
        return this.http.post<number>(Config.baseUrl + 'newsletter/insert',email);
    }

    deleteNewsLetterSubscriber(email:string) {
        return this.http.post<number>(Config.baseUrl + 'newsletter/delete',email);
    }
}