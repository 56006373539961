import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { DeleteTeacherLessonQ, LessonR } from "../../../teacher/models/lesson/lesson.model";

@Injectable({
    providedIn: 'root'
})
export class LessonService {

    constructor(private http: HttpClient) {

    }

    getAllLessonCategoryCount() {
        return this.http.get<number>(Config.baseUrl + 'lesson/count');
    }

    getAllLessonByLessonTypeId(lessonTypeId: number) {
        if (lessonTypeId === undefined) {
            return this.http.get<LessonR[]>(Config.baseUrl + 'lesson/getlessonsbylessontypeid?lessonTypeId=');
        }
        else {
            return this.http.get<LessonR[]>(Config.baseUrl + 'lesson/getlessonsbylessontypeid?lessonTypeId=' + lessonTypeId);
        }
    }

    getAllLessonByLessonTypeIds(lessonTypeIds: number[]) {
        return this.http.post<LessonR[]>(Config.baseUrl + 'lesson/getlessonsbylessontypeids',lessonTypeIds);
    }

    getTeacherLessonsByTeacherId(teahcerId: number) {
        return this.http.get<LessonR[]>(Config.baseUrl + 'lesson/getlessonsbyteacherid?teacherId=' + teahcerId);
    }

    GetTeacherLessonsByTeacher() {
        return this.http.get<LessonR[]>(Config.baseUrl + 'lesson/getlessonsbyteacher');
    }

    deleteTeacherLesson(dto: DeleteTeacherLessonQ) {
        return this.http.post<number>(Config.baseUrl + 'lesson/deleteteacherlesson',dto);
    }
}