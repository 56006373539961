import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { LocationTypeR } from "../../../teacher/models/locations/location-type.model";
import { InsertLocationModelQ } from "../../../teacher/models/locations/location.model";
import { OfflineLocation, OfflineLocationCityR } from "../../../teacher/models/locations/offline-location.model";
import { OnlineLocation } from "../../../teacher/models/locations/online-location.model";
import { OnlinePlatform } from "../../../teacher/models/locations/platform-model";

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor(private http: HttpClient) {

    }

    getAllLocationTypes() {
        return this.http.get<LocationTypeR[]>(Config.baseUrl + 'location/getall');
    }

    getAllLocationByTypeId(locationTypeId: number) {
        return this.http.post<OnlineLocation[]|OfflineLocation[]>(Config.baseUrl + 'location/getlocationsbytype',locationTypeId);
    }

    getAllOfflineLocations() {
        return this.http.get<OfflineLocationCityR[]>(Config.baseUrl + 'location/getofflinelocations');
    }

    getAllPlatforms() {
        return this.http.get<OnlinePlatform[]>(Config.baseUrl + 'location/getallplatforms');
    }

    insertLocations(insertLocationModelQ: InsertLocationModelQ) {
        const body=insertLocationModelQ;
        return this.http.post<number>(Config.baseUrl + 'location/insert',body);
    }

    updateOnlineLocation(onlineLocation: OnlineLocation) {
        const body=onlineLocation;
        return this.http.post<number>(Config.baseUrl + 'location/updateonlinelocation',body);
    }

    updateOfflineLocation(offlineLocation: OfflineLocation) {
        const body=offlineLocation;
        return this.http.post<number>(Config.baseUrl + 'location/updateofflinelocation',body);
    }

    deleteOnlineLocation(locationId: number) {
        const body=locationId;
        return this.http.post<number>(Config.baseUrl + 'location/deleteonlinelocation',body);
    }

    deleteOfflineLocation(locationId: number) {
        const body=locationId;
        return this.http.post<number>(Config.baseUrl + 'location/deleteofflinelocation',body);
    }

    getOnlineLocationById(locationId: number) {
        return this.http.post<OnlineLocation>(Config.baseUrl + 'location/getonlinebyid',locationId);
    }

    getOfflineLocationById(locationId: number) {
        return this.http.post<OfflineLocation>(Config.baseUrl + 'location/getofflinebyid',locationId);
    }
}