import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { TeacherAssessmentQ, TeacherAssessmentR } from "../../../teacher/models/teacher/teacher-assessment.model";
import { InsertTeacherCallBackDTO, TeacherByFilterDTO, TeacherR, TeacherTotalRatingR } from "../../../teacher/models/teacher/teacher.model";
import { TeacherSocialQ, TeacherSocialR } from "../../../teacher/models/teacher/teacher-social.model";
import { filterQ } from "../../../shared/models/filter/filter.model";
import { GetTeacherByIdDTO, UserDocumentQ } from "../../../shared/services/user/user.model";
import { SendEmailByIdDTO, SendEmailModel } from "../../../shared/models/email/send-email-to-students.model";
import { LessonInsertQ, RequestedLessonInsertQ } from "../../models/lesson/lesson.model";
import { SeededTeachersCardsModel } from "src/app/shared/models/seeded-teachers-cards/seeded-teacher-cards.model";
import { CreateCalendarEventsDTO } from "src/app/shared/models/event/event.model";

@Injectable({
    providedIn: 'root'
})
export class TeacherService {

    constructor(private http: HttpClient) {

    }

    getAllTeachersCount() {
        return this.http.get<number>(Config.baseUrl + 'teacher/count');
    }

    getTeachersSuccessfulLessonsNumber() {
        return this.http.get<number>(Config.baseUrl + 'teacher/lessonnumber');
    }

    getTeacherTextAssessmentsById(teacherId: number) {
        return this.http.get<TeacherAssessmentR[]>(Config.baseUrl + 'teacher/getassessmentsbyteacherid?teacherId=' + teacherId.toString());
    }

    getTeacherById(teacherId: number) {
        return this.http.get<GetTeacherByIdDTO>(Config.baseUrl + 'teacher/getbyid?teacherId=' + teacherId.toString());
    }

    getTeacherByFilters(filter: filterQ) {
        return this.http.post<TeacherByFilterDTO[]>(Config.baseUrl + 'teacher/getbyfilters', filter);
    }

    getAllTeacherNumberAssessments(teacherId: number) {
        return this.http.post<number[]>(Config.baseUrl + 'teacher/getallassessments', teacherId);
    }

    insertTeacherAssessment(teacherAssessment: TeacherAssessmentQ) {
        return this.http.post<TeacherAssessmentR>(Config.baseUrl + 'teacher/insertteacherassessment', teacherAssessment);
    }

    insertTeacherSocials(teacherSocials: TeacherSocialQ) {
        return this.http.post<TeacherSocialR>(Config.baseUrl + 'teacher/insertteachersocials', teacherSocials);
    }

    insertTeacherLessons(lessonInsertQ: LessonInsertQ[]) {
        return this.http.post<number>(Config.baseUrl + 'teacher/insertteacherlessons', lessonInsertQ);
    }

    insertTeacherDocuments(dto: UserDocumentQ) {
        return this.http.post<number>(Config.baseUrl + 'teacher/insertteacherdocuments', dto);
    }

    getTeacherCallBackCount(dto:InsertTeacherCallBackDTO){
        return this.http.post<number>(Config.baseUrl + 'teacher/getcallbackcount', dto);
    }

    insertTeacherCallBack(dto:InsertTeacherCallBackDTO){
        return this.http.post<number>(Config.baseUrl + 'teacher/insertcallback', dto);
    }

    getTeacherSocials(teahcerId?: number) {
        if (teahcerId) {
            return this.http.post<TeacherSocialR>(Config.baseUrl + 'teacher/getteachersocialsbyid', teahcerId);
        } else {
            return this.http.get<TeacherSocialR>(Config.baseUrl + 'teacher/getteachersocials');
        }
    }

    updateTeacherTotalRatings(teacherId: number) {
        return this.http.post<number>(Config.baseUrl + 'teacher/updatetotalratings', teacherId);
    }

    getTeacherTotalRatings(teacherId: number) {
        return this.http.post<TeacherTotalRatingR>(Config.baseUrl + 'teacher/get-total-ratings', teacherId);
    }

    sendEmailToAddresses(email: SendEmailModel) {
        const body = JSON.stringify(email);
        return this.http.post<number>(Config.baseUrl + 'teacher/sendemail', body);
    }

    sendEmailById(email: SendEmailByIdDTO) {
        const body = JSON.stringify(email);
        return this.http.post<number>(Config.baseUrl + 'teacher/sendemailbyid', body);
    }

    insertTeacherLessonRequests(dto: RequestedLessonInsertQ) {
        return this.http.post<number>(Config.baseUrl + 'teacher/insertteacherlessonsrequets', dto);
    }

    getSeededTeachers() {
        return this.http.get<SeededTeachersCardsModel[]>(Config.baseUrl + 'teacher/getseeded');
    }

    createCalendarEventAsync(dto: CreateCalendarEventsDTO) {
        return this.http.post<number>(Config.baseUrl + 'teacher/eventstoemail',dto);
    }
}