<div class="lg:flex md:flex lg:items-start py-12 2xl:px-20 lg:px-6 px-4 md:px-6 px-4">
    <div class="grid lg:grid-rows-1 lg:grid-cols-2 md:grid-rows-2 md:grid-cols-1">
        <div class="text-center lg:mr-12">
            @if(summaryRating>0){
            <span class="text-7xl font-bold ">{{summaryRating}} </span>
            }
            @if(summaryRating <=0){ <span class="text-xl font-bold "> {{'shared.reviews_summary.no_rating' | transloco}}
                </span>
                }
                <br>
                <app-star-summary-rating [rating]="summaryRating"></app-star-summary-rating>
                <p>{{numberAssessments[5]}} {{'shared.reviews_summary.rating_text' | transloco}}</p>
        </div>
        <table class="table-auto">
            <tbody>
                <tr>
                    <td class="w-24 pr-4">5 {{'shared.reviews_summary.stars' | transloco}}</td>
                    <td class="w-64">
                        <div class="bar-container rounded">
                            <div class="rounded" [style]="barStylings[4]"></div>
                        </div>
                    </td>
                    <td class="w-2 pl-4">{{numberAssessments[4]}}</td>
                </tr>
                <tr>
                    <td class="w-24 pr-4">4 {{'shared.reviews_summary.stars' | transloco}}</td>
                    <td class="w-64">
                        <div class="bar-container rounded">
                            <div class="rounded" [style]="barStylings[3]"></div>
                        </div>
                    </td>
                    <td class="w-2 pl-4">{{numberAssessments[3]}}</td>
                </tr>
                <tr>
                    <td class="w-24 pr-4">3 {{'shared.reviews_summary.stars' | transloco}}</td>
                    <td class="w-64">
                        <div class="bar-container rounded">
                            <div class="rounded" [style]="barStylings[2]"></div>
                        </div>
                    </td>
                    <td class="w-2 pl-4">{{numberAssessments[2]}}</td>
                </tr>
                <tr>
                    <td class="w-24 pr-4">2 {{'shared.reviews_summary.stars' | transloco}}</td>
                    <td class="w-64">
                        <div class="bar-container rounded">
                            <div class="rounded" [style]="barStylings[1]"></div>
                        </div>
                    </td>
                    <td class="w-2 pl-4">{{numberAssessments[1]}}</td>
                </tr>
                <tr>
                    <td class="w-24 pr-4">1 {{'shared.reviews_summary.stars' | transloco}}</td>
                    <td class="w-64">
                        <div class="bar-container rounded">
                            <div class="rounded" [style]="barStylings[0]"></div>
                        </div>
                    </td>
                    <td class="w-2 pl-4">{{numberAssessments[0]}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="lg:flex md:flex lg:items-start py-12 2xl:px-20 lg:px-6 px-4 md:px-6 px-4">
    @if(textAssessments.length > 0){
    <div class="grid grid-cols-1 mt-5 grid-rows-3 w-full">
        @for(assessment of textAssessments; track $index){
        <div class="grid grid-cols-1 grid-rows-2 w-full">
            <div>
                @if(assessment.email && assessment.email !==''){
                <p class="text-xl font-bold text-gray-700">
                    {{assessment.lastName }} {{assessment.firstName }}
                </p>
                }@else{
                <p class="text-xl font-bold text-gray-700">
                    {{'common.deleted' | transloco}} {{'common.user' | transloco}}
                </p>
                }
            </div>
        </div>
        <div>
            <app-star-summary-rating [rating]="assessment.teacherNumberAssessment"></app-star-summary-rating>
        </div>
        <div class="border-b mb-2">
            <p class="mt-1 max-w-2xl text-s text-gray-500 break-words">{{assessment.teacherTextAssessment}}</p>
        </div>
        }
    </div>
    }@else{
    <div class="grid grid-cols-3 gap-4 m-5 grid-rows-1 w-full">
        <div class="col-span-3 text-center">
            <p class="mt-1 max-w-2xl text-s leading-6 text-gray-500">{{'shared.reviews_summary.no_assessment' |
                transloco}}</p>
        </div>
    </div>
    }
</div>