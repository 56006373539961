import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Observable, Subject, takeUntil } from "rxjs";
import { RoleEnum } from "../../shared/enums/roles.enum";
import * as fromUser from '../../shared/services/user/user-store/user.selector';
import { Store } from "@ngrx/store";

@Injectable({
    providedIn: 'root'
})
export class TeacherAuthGuard {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roleId!: number;
    constructor(
        private router: Router,
        public route: Router,
        private store: Store
    ) {

    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.getUserDataFromStore();
        if(!this.roleId){
           const roleId =  Number(localStorage.getItem('roleId') as string);
           if(roleId){
            this.roleId = roleId;
           }
        }
        
        if (this.roleId) {
            let checkRole = this.roleId.toString() === RoleEnum.TEACHER;
            if (checkRole === true) {
                return true;
            }
            else {
                this.router.navigateByUrl('/sign-in');
                return false;
            }
        } else {
            this.router.navigateByUrl('/sign-in');
            return false;
        }
    }

    getUserDataFromStore() {
        const userData = this.store.select(fromUser.getUserData);

        userData
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
                if (response != null && response !== undefined) {
                    if (response.roleId) {
                        this.roleId = response.roleId;
                    }
                }
            })
    }
}