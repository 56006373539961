import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { StudentR } from '../../models/student/student.model';
import { CommonModule } from '@angular/common';
import { UtilService } from '../../services/utils/utils.serivce';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'student-card',
  templateUrl: './student-card.component.html',
  standalone: true,
  imports: [CommonModule,TranslocoModule],
  providers: [UtilService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentCardComponent {
  utilService = inject(UtilService)

  private _s: StudentR = {};
  get student(): StudentR {
    return this._s;
  }
  @Input() set student(value: StudentR) {
    this._s = {
      ...value,
      picture: this.utilService.decodeBase64ImageFileToSecurityTrustResource(value.profilePicture!)
    }
  }
}
