import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
    name: 'formError'
})
export class FormErrorPipe implements PipeTransform {

    transform(control: AbstractControl): string {
        if (!control || !control.errors) { return ''; }

        const error = Object.keys(control.errors).map((v) => v).join(', ');
        return error;
    }

}