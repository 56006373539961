import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ToastComponent } from './shared/services/toast/toast.component';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { TeacherService } from './teacher/services/teacher/teacher.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LessonService } from './teacher/services/lesson/lesson-service';
import { LessonTypeService } from './teacher/services/lesson-type/lesson-type.service';
import { LocationService } from './teacher/services/location/location.service';
import { StoreModule } from '@ngrx/store';
import * as lessonReducer from './teacher/services/lesson/lesson-store/lesson.reducer';
import * as lessonState from './teacher/services/lesson/lesson-store/lesson.state';
import { EffectsModule } from '@ngrx/effects';
import { LessonEffects } from './teacher/services/lesson/lesson-store/lesson.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TeacherEffects } from './teacher/services/teacher/teacher-store/teacher.effects';
import * as teacherReducer from './teacher/services/teacher/teacher-store/teacher.reducer';
import * as teacherState from './teacher/services/teacher/teacher-store/teacher.state';
import { LocationEffects } from './teacher/services/location/location-store/location.effects';
import * as locationReducer from './teacher/services/location/location-store/location.reducer';
import * as locationState from './teacher/services/location/location-store/location.state';
import { AuthEffects } from './shared/services/auth/authentication-store/authentication.effects';
import * as AuthReducer from './shared/services/auth/authentication-store/authentication.reducer';
import * as AuthState from './shared/services/auth/authentication-store/authentication.state';
import { AuthenticationService } from './shared/services/auth/authentication.service';
import { LoaderInterceptor } from './shared/custom-interceptor';
import { UserEffects } from './shared/services/user/user-store/user.effects';
import * as UserReducer from './shared/services/user/user-store/user.reducer';
import * as UserState from './shared/services/user/user-store/user.state';
import { EventEffects } from './shared/services/event/event-store/event.effects';
import * as EventReducer from './shared/services/event/event-store/event.reducer';
import * as EventState from './shared/services/event/event-store/event.state';
import { NotificationEffects } from './shared/services/notificaiton/notification-store/notification.effects';
import * as NotificationReducer from './shared/services/notificaiton/notification-store/notification.reducer';
import * as NotificationState from './shared/services/notificaiton/notification-store/notification.state';
import { CookieService } from 'ngx-cookie-service';
import { TeacherDocumentService } from './teacher/services/teacher-document/teacher-document.service';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@ngneat/transloco';
import { SignUpComponent } from './shared/components/sign-up/sign-up.component';
import { AcceptCookiesComponent } from './shared/components/accept-cookies/accept-cookies.component';
import { BaseComponent } from './shared/components/base-component/base-component.component';
import { BaseFormComponent } from './shared/components/base-form-component/base-form-component.component';
import { MenuItemsService } from './shared/services/menu-items/menu-items.service';
import { SendMessageComponent } from './shared/components/send-message-component/send-message-component.component';
import { SignInComponent } from './shared/components/sign-in/sign-in.component';
import { SignOutComponent } from './shared/components/sign-out/sign-out.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { StarRatingComponent } from './shared/components/star-rating-component/star-rating-component.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { StudentAuthGuard } from './student/guards/student-auth-guard.component';
import { TeacherAuthGuard } from './teacher/guard/teacher-auth-guard.component';
import { AuthGuard } from './shared/guards/auth-guard.component';
import { SharedModule } from './shared/shared.module';
import { LandingHomeComponent } from './landing/home/home.component';
import { StudentCardComponent } from './shared/components/student-card/student-card.component';
import { StepperComponent } from './shared/components/stepper/stepper.component';
import { StepEffects } from './shared/services/step/step-store/step.effects';
import * as StepReducer from './shared/services/step/step-store/step.reducer';
import * as StepState from './shared/services/step/step-store/step.state';
import { LessonTypeEffects } from './teacher/services/lesson-type/lesson-type-store/lesson-type.effects';
import * as lessonTypeReducer from './teacher/services/lesson-type/lesson-type-store/lesson-type.reducer';
import * as lessonTypeState from './teacher/services/lesson-type/lesson-type-store/lesson-type.state';
import { TeacherDocumentEffects } from './teacher/services/teacher-document/teacher-document-store/teacher-document.effects';
import * as teacherDocumentReducer from './teacher/services/teacher-document/teacher-document-store/teacher-document.reducer';
import * as teacherDocumentState from './teacher/services/teacher-document/teacher-document-store/teacher-document.state';
import { NewsLetterEffects } from './shared/services/news-letter/news-letter-store/news-letter.effects';
import * as newsLetterReducer from './shared/services/news-letter/news-letter-store/news-letter.reducer';
import * as newsLetterState from './shared/services/news-letter/news-letter-store/news-letter.state';
import { AdminEffects } from './admin/services/admin/admin-store/admin.effects';
import * as adminReducer from './admin/services/admin/admin-store/admin.reducer';
import * as adminState from './admin/services/admin/admin-store/admin.state';
import { NgxStripeModule } from 'ngx-stripe';
import { Config } from './config/appconfig';
import { NewsletterSubscriptionComponent } from './shared/components/newsletter-subscription/newsletter-subscription/newsletter-subscription.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { SeededTeachersCardsComponent } from './shared/components/seeded-teachers-cards/seeded-teachers-cards.component';
import { SignUpOfComponent } from './shared/components/sign-up-of/sign-up-of.component';
import { SignUpProviderComponent } from './shared/components/sign-up-provider/sign-up-provider.component';
import { LocalSpinnerComponent } from './shared/components/local-spinner/local-spinner.component';
import { ConfirmEmailComponent } from './shared/components/confirm-email/confirm-email.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignInComponent,
    SignUpOfComponent,
    SignUpProviderComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    ToastComponent,
    SpinnerComponent,
    LocalSpinnerComponent,
    SignOutComponent,
    FooterComponent,
    AcceptCookiesComponent,
    BaseComponent,
    BaseFormComponent,
    LandingHomeComponent,
    StepperComponent,
    NewsletterSubscriptionComponent,
    ConfirmEmailComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSelectModule,
    HttpClientModule,
    MatAutocompleteModule,
    StarRatingComponent,
    SendMessageComponent,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ name: 'DevTools', logOnly: true }),
    StoreModule.forFeature(lessonState.LESSON_FEATURE_KEY, lessonReducer.reducer),
    EffectsModule.forFeature([LessonEffects]),
    StoreModule.forFeature(teacherState.TEACHER_FEATURE_KEY, teacherReducer.reducer),
    EffectsModule.forFeature([TeacherEffects]),
    StoreModule.forFeature(locationState.LOCATION_FEATURE_KEY, locationReducer.reducer),
    EffectsModule.forFeature([LocationEffects]),
    StoreModule.forFeature(AuthState.TOKEN_FEATURE_KEY, AuthReducer.reducer),
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(UserState.USER_FEATURE_KEY, UserReducer.reducer),
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature(EventState.EVENT_FEATURE_KEY, EventReducer.reducer),
    EffectsModule.forFeature([EventEffects]),
    StoreModule.forFeature(NotificationState.NOTIFICATION_FEATURE_KEY, NotificationReducer.reducer),
    EffectsModule.forFeature([NotificationEffects]),
    StoreModule.forFeature(StepState.STEP_FEATURE_KEY, StepReducer.reducer),
    EffectsModule.forFeature([StepEffects]),
    StoreModule.forFeature(lessonTypeState.LESSON_TYPE_FEATURE_KEY, lessonTypeReducer.reducer),
    EffectsModule.forFeature([LessonTypeEffects]),
    StoreModule.forFeature(teacherDocumentState.TEACHER_DOCUMENT_FEATURE_KEY, teacherDocumentReducer.reducer),
    EffectsModule.forFeature([TeacherDocumentEffects]),
    StoreModule.forFeature(newsLetterState.NEWS_LETTER_FEATURE_KEY, newsLetterReducer.reducer),
    EffectsModule.forFeature([NewsLetterEffects]),
    StoreModule.forFeature(adminState.ADMIN_FEATURE_KEY, adminReducer.reducer),
    EffectsModule.forFeature([AdminEffects]),
    TranslocoRootModule,
    StudentCardComponent,
    SeededTeachersCardsComponent,
    NgxStripeModule.forRoot(Config.stripePublicKey),
    NgxGoogleAnalyticsModule.forRoot(Config.googleAnalyticsKey),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    AuthenticationService,
    TeacherService,
    LessonService,
    LessonTypeService,
    LocationService,
    TeacherService,
    CookieService,
    TranslocoService,
    MenuItemsService,
    TeacherDocumentService,
    TeacherAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    StudentAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
