import { Component, OnInit } from '@angular/core';
import { BaseComponent } from './shared/components/base-component/base-component.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'Orafoglalo_frontend';


  constructor(
    override translocoService: TranslocoService
  ) {
    super(undefined, undefined, undefined, translocoService, undefined);
  }

  ngOnInit(): void {
    this.translocoService.setAvailableLangs(['hu', 'en', 'de']);
    this.translocoService.setDefaultLang('hu');
    this.translocoService.setActiveLang('hu');
  }
}
