<div class="bg-white p-5 sm-modal cursor-move" cdkDragRootElement=".cdk-overlay-pane" cdkDrag cdkDragHandle>
    <div>
        <h4>
            {{ title | transloco }}
        </h4>
    </div>

    <div class="modal-content">
        <send-message-component> </send-message-component>
    </div>

    <div class="mt-6 flex items-center justify-between">
        <button type="button" class="btn-cancel" (click)="onCancel()">{{ 'common.close' | transloco }}</button>
    </div>
</div>