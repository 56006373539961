<div class="bg-white p-5 sm-modal overflow-scroll" cdkDragRootElement=".cdk-overlay-pane" cdkDrag cdkDragHandle>
    <div>
        <h4 class="text-base leading-6 font-bold text-dark-green">
            {{ data.title | transloco }}
        </h4>
    </div>

    <div class="modal-content ">
        <form [formGroup]="form">
            <div class="mt-2" [class.error]="form.controls['subject'].invalid && form.controls['subject'].touched">
                <label for="subject" class="label text-base leading-6 font-bold text-dark-green">{{
                    'teacher_event_list.send_email_modal.email.subject' | transloco
                    }}</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input type="text" name="subject" id="subject" class="input-text" formControlName="subject"
                        placeholder="{{ 'teacher_event_list.send_email_modal.email.subject_placeholder' | transloco }}">

                    @if(form.controls['subject'].touched){
                    <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                        getError(form.controls['subject'])
                        }}</span>
                    }
                </div>
            </div>
            <div class="mt-2" [class.error]="form.controls['reply_address'].invalid && form.controls['reply_address'].touched">
                <label for="reply_address" class="label text-base leading-6 font-bold text-dark-green">{{
                    'teacher_event_list.send_email_modal.email.reply_address' | transloco
                    }}</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input type="email" name="reply_address" id="reply_address" class="input-text"
                        formControlName="reply_address"
                        placeholder="{{ 'teacher_event_list.send_email_modal.email.reply_address_placeholder' | transloco }}">

                    @if(form.controls['reply_address'].touched){
                        <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                            getError(form.controls['reply_address'])
                            }}</span>
                    }
                </div>
            </div>

            <div class="mt-2" [class.error]="form.controls['body'].invalid">
                <label for="body" class="label text-base leading-6 font-bold text-dark-green">{{
                    'teacher_event_list.send_email_modal.email.body' | transloco }}</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <textarea id="body" name="body" rows="3" class="textarea" formControlName="body"
                        placeholder="{{ 'teacher_event_list.send_email_modal.email.body_placeholder' | transloco }}"></textarea>
                    <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{ getError(form.controls['body'])
                        }}</span>
                </div>
            </div>
        </form>
    </div>

    <div class="mt-6 flex items-center justify-between">
        <button type="button" class="btn-primary" [disabled]="form.invalid" (click)="onSend()">{{ 'common.send' |
            transloco }}</button>
        <button type="button" class="btn-cancel" (click)="onCancel()">{{ 'common.close' | transloco }}</button>
    </div>
</div>