<button type="button" (click)="eventClicked()" [title]="event.eventName" [disabled]="isDisabled"
    class="border-t border-b border-r border-light-green relative text-left z-1 p-2 cursor-pointer lg:h-17 md:h-17 w-full h-15 overflow-y-hidden hover:bg-dark-yellow">
    <div class="flex flex-wrap justify-start">
        <div class="absolute inset-y-0 left-0">
            <p class="button w-1 h-full" [class.active]="event.lessonId === availableLessonId"
                [class.inactive]="event.lessonId !== availableLessonId">
            </p>
        </div>
        <div class="ml-1 text-dark-green text-wrap text-xs w-full font-bold lg:block md:hidden hidden">
            @if(event.eventName && event.eventName.length > 37){
            {{event.eventName | slice:0:37}}...
            }@else{
            {{event.eventName}}
            }
        </div>
        <div class="ml-1 text-dark-green text-wrap text-base w-full font-bold lg:hidden md:block hidden">
            @if(event.eventName && event.eventName.length > 50){
            {{event.eventName | slice:0:50}}...
            }@else{
            {{event.eventName}}
            }
        </div>
        <div class="ml-1 text-dark-green text-wrap text-xl w-full font-bold lg:hidden md:hidden block">
            @if(event.eventName && event.eventName.length > 100){
            {{event.eventName | slice:0:100}}...
            }@else{
            {{event.eventName}}
            }
        </div>
    </div>
    <div class="ml-1 flex flex-wrap justify-start pt-2">
        <div class="text-dark-green text-start text-wrap lg:text-xs md:text-sm lg:text-base pr-1 w-[50%]">
            {{event.eventStartDate | date:'HH:mm'}} - {{event.eventEndDate | date:'HH:mm'}}
        </div>
        @if(event.locationTypeId && event.locationTypeId !== phoneLocationType){
        <p class="text-dark-green text-end text-wrap text-xs pr-1 w-[50%]">
            {{event.availableSeatsNumber}} {{'common.seat' | transloco}}
        </p>
        }@else if(event.locationTypeId && event.locationTypeId === phoneLocationType){
        <p class="text-lg lg:text-xs md:text-sm ">
            {{'teacher.timetable.ask_phone' | transloco}}
        </p>
        }

    </div>
</button>