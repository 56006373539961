import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function upperCaseValidator(control: AbstractControl): ValidatorFn {
  return (): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);

    return hasUpperCase ? null : { noUpperCase: true };
  };
}