import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as StepActions from './step.action';
import { catchError, concatMap, map, of } from "rxjs";
import { StepService } from "../step-service";


@Injectable()
export class StepEffects {

    loadGetStepsByRole$ = createEffect(() => {
        return this.action$.pipe(
            ofType(StepActions.loadGetStepsByRole),
            concatMap((action) => this.stepService.getStepsByRole(action.data).pipe(
                map((data) => {
                    return StepActions.loadGetStepsByRoleSuccess({ data });
                }),
                catchError(() => of(StepActions.failure({ error: "Load steps failure" })))
            )
            )
        );
    });

    loadGetTeacherStepsByUser$ = createEffect(() => {
        return this.action$.pipe(
            ofType(StepActions.loadGetTeacherSteps),
            concatMap(() => this.stepService.getTeacherStepsByUser().pipe(
                map((data) => {
                    return StepActions.loadGetTeacherStepsSuccess({ data });
                }),
                catchError(() => of(StepActions.failure({ error: "Load steps failure" })))
            )
            )
        );
    });

    insertTeacherSteps$ = createEffect(() => {
        return this.action$.pipe(
            ofType(StepActions.loadInsertTeacherSteps),
            concatMap((action) => this.stepService.insertTeacherSteps(action.data).pipe(
                map((data) => {
                    return StepActions.loadInsertTeacherStepsSuccess({ data });
                }),
                catchError(() => of(StepActions.failure({ error: "Load steps failure" })))
            )
            )
        );
    });

    updateTeacherStep$ = createEffect(() => {
        return this.action$.pipe(
            ofType(StepActions.loadUpdateTeacherStep),
            concatMap((action) => this.stepService.updateTeacherStep(action.data).pipe(
                map((data) => {
                    return StepActions.loadUpdateTeacherStepSuccess({ data });
                }),
                catchError(() => of(StepActions.failure({ error: "Load steps failure" })))
            )
            )
        );
    });


    constructor(
        private action$: Actions,
        private stepService: StepService
    ) { }
}