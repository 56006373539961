import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./authentication.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as TokenActions from '../authentication-store/authentication.action';

export const TokenAdapter: EntityAdapter<string> =
    createEntityAdapter<string>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const TokenReducer = createReducer(
    initialState,
    on(TokenActions.init, () =>
        ({ ...initialState })
    ),
    on(
        TokenActions.LoadTokenSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.LoadGoogleSignInSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.LoadFacebookSignInSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.LoadSignUpSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.LoadGoogleSignUpSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.LoadCheckUserInDatabaseSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.ForgotPasswordEmailSendSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.ConfirmEmailSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.ForgotPasswordValidateSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.SetUserPasswordSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TokenActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return TokenReducer(state,aciton);
}