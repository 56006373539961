import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface Step {
  title: string;
  content: string;
}

const StudentSteps: Step[] = [
  {
    title: 'student.home.step.simple.title',
    content: 'student.home.step.simple.content'
  },
  {
    title: 'student.home.step.fast.title',
    content: 'student.home.step.fast.content'
  },
  {
    title: 'student.home.step.cozy.title',
    content: 'student.home.step.cozy.content'
  },
  {
    title: 'student.home.step.free.title',
    content: 'student.home.step.free.content'
  }
]

const TeacherSteps: Step[] = [
  {
    title: 'teacher.home.step.simple.title',
    content: 'teacher.home.step.simple.content'
  },
  {
    title: 'teacher.home.step.fast.title',
    content: 'teacher.home.step.fast.content'
  },
  {
    title: 'teacher.home.step.cozy.title',
    content: 'teacher.home.step.cozy.content'
  },
  {
    title: 'teacher.home.step.free.title',
    content: 'teacher.home.step.free.content'
  }
]

@Component({
  selector: 'step-cards',
  templateUrl: './step-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepCardsComponent {
  @Input() isStudent: boolean = true;

  steps: Step[] = StudentSteps;

  ngOnChanges() {
    this.steps = this.isStudent ? StudentSteps : TeacherSteps;
  }
}
