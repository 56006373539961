import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { GetNotificationsDTO, NotificationQ, NotificationR, NotificationU } from "./notification.model";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) {

    }

    getNotificationByUserId(dto: GetNotificationsDTO) {
        return this.http.post<NotificationR[]>(Config.baseUrl + 'notification/getbyuser',dto);
    }

    getNewNotificationsCountByUserId() {
        return this.http.get<number>(Config.baseUrl + 'notification/getcountbyuser');
    }

    insertNotification(notification:NotificationQ) {
        return this.http.post<NotificationR>(Config.baseUrl + 'notification/insert', notification);
    }

    updateNotificationIsNew(notification:NotificationU) {
        return this.http.post<NotificationR>(Config.baseUrl + 'notification/updateisnew', notification);
    }
}