<div class="grid grid-rows-3 gap-4 m-5">
    <div class="row-start-1 lg:w-2/3 md:w-2/3 w-full justify-self-center">
        <div
            class="max-w justify-center p-6 m-6 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 sign-up-background">
            <div>
                <h3 class="text-white text-center mb-6 text-2xl font-bold">{{'common.login' | transloco}}</h3>
            </div>
            <form [formGroup]="form" class="group">
                @if(!isOfLogin){
                <div class="mb-6">
                    <button type="button" (click)="OfLoginClicked()"
                        class="login_button_firebase flex items-center justify-center w-full px-4 py-2 flex gap-2 rounded-lg font-bold text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                        <img class="h-6 w-auto cursor-pointer" src="./assets/images/orafoglalo_logo.png"
                            alt="Órafoglaló" loading="lazy" routerLink="/">
                        <p class="justify-self-center">Órafoglaló {{'common.login' | transloco}}</p>
                    </button>
                </div>
                <div class="mb-6">
                    <button type="button" class="login_button_google w-full
                             flex items-center justify-center px-4 py-2 border gap-2 border-slate-200
                              rounded-lg font-bold text-slate-700 hover:border-slate-400 hover:text-slate-900 
                              hover:shadow transition duration-150" (click)="googleLogin()">
                        <img class="w-6 h-6" src="../../../../assets/images/socials/google-color.svg" loading="lazy"
                            alt="google logo">
                        <p>Google {{'common.login' | transloco}}</p>
                    </button>
                </div>
                <div class="mb-6">
                    <button type="button" style="background-color: #2374F2" (click)="facebookLogin()"
                        class="login_button_google flex items-center justify-center w-full px-4 py-2 flex gap-2 rounded-lg font-bold text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                        <img src="../../../../assets/images/socials/facebook.svg" class="w-6 h-6" loading="lazy"
                            alt="facebook logo">
                        <span>Facebook {{'common.login' | transloco}}</span>
                    </button>
                </div>
                }
                @if(isOfLogin){
                <div class="justify-items-center">
                    <div class="w-full mb-6">
                        <label for="email" class="mb-2 text-sm font-medium text-white dark:text-white">{{'common.email'
                            | transloco}}: </label>
                        <input type="email" id="email" (keydown.enter)="preventEnter($event)" class="
                                bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green
                                focus:border-light-green w-full p-2.5
                                invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                            placeholder="Kérlek írd be az e-mail címed" formControlName="email" #email required>
                    </div>
                    <div class="w-full mb-6">
                        <label for="password" class="block text-sm font-medium text-white leading-6">
                            {{'common.password'
                            | transloco}}</label>
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <input [type]="type" formControlName="password" name="password" id="password"
                                class="input-text" placeholder="*****" (keydown.enter)="preventEnter($event)">
                            <button type="button" class="absolute inset-y-0 right-3 flex items-center" (click)="eyeClicked()">
                                @if(type === 'password'){
                                <img src="../../../../assets/images/eye.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                    alt="eye">
                                }@else if(type === 'text'){
                                <img src="../../../../assets/images/eye-slash.svg" class="h-7 w-7 btn-icon"
                                    loading="lazy" alt="eye">
                                }
                            </button>
                        </div>
                    </div>
                    <div class="mb-6">
                        <button type="button" class="login_button_firebase w-full 
                            text-white font-bold px-4 py-2 rounded 
                            disabled:pointer-events-none disabled:opacity-30" [disabled]="!form.valid" (click)="login()">
                            <p class="justify-self-center">{{'common.login' | transloco}}</p>
                        </button>
                    </div>
                    <div class="mb-6">
                        <button type="button"
                            class="bg-light-yellow hover:bg-dark-yellow justify-self-center w-full text-white font-bold px-4 py-2 rounded"
                            (click)="backClicked()">
                            <p class="justify-self-center">{{'common.back' | transloco}}</p>
                        </button>
                    </div>
                </div>
                }
                <div class="flex items-center justify-between mb-6 mt-2">
                    <div class="flex items-center">
                        <div>
                            <input id="remember" formControlName="remember_me" aria-describedby="remember"
                                type="checkbox"
                                class="w-4 h-4 border text-light-green rounded bg-gray-50 focus:ring-3 focus:ring-light-green">
                        </div>
                        <div class="ml-3 text-lg">
                            <label for="remember" class="text-light-green dark:text-gray-300">{{'common.remember_me'
                                | transloco}}</label>
                        </div>
                    </div>
                    <button type="button" (click)="forgotPasswordClick()">
                        <p class="text-lg font-medium text-light-green hover:underline dark:text-primary-500">
                            {{'common.forgot_password' | transloco}}
                        </p>
                    </button>
                </div>
                <div class="text-white text-center">
                    <p class="w-full"> {{'common.not_account' | transloco}} <a
                            class="text-lg font-medium text-light-green hover:underline dark:text-primary-500"
                            href="/sign-up">{{'common.register' | transloco}}!</a></p>
                </div>

            </form>
        </div>
    </div>
</div>