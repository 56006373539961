import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import * as TokenActions from '../../../shared/services/auth/authentication-store/authentication.action';
import * as LessonTypeActions from '../../../teacher/services/lesson-type/lesson-type-store/lesson-type.action';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { BaseComponent } from '../base-component/base-component.component';
import { MenuItem, MenuItemQ } from '../../models/menu/menu.model';
import { GetNotificationsDTO, NotificationToScrollDTO, NotificationU } from '../../services/notificaiton/notification.model';
import { UtilService } from '../../services/utils/utils.serivce';
import { ToastService } from '../../services/toast/toast.service';
import { SignalRService } from '../../services/signal-r/signal-r.service';
import { MenuItemsService } from '../../services/menu-items/menu-items.service';
import { JwtDecode } from '../../models/jwt-token/jwt-token.model';
import * as UserActions from '../../../shared/services/user/user-store/user.action';
import * as NotificationActions from '../../../shared/services/notificaiton/notification-store/notification.action';
import { TranslocoService } from '@ngneat/transloco';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  selectedMenuItem = 0;
  isDropdownOpen = false;
  isDropdownLanguagesOpen = false;
  isMobileDropdownOpen = false;
  isNotificationsOpen = false;
  showMoreNotificationsVisible = false;
  newNotificationsCount = 0;
  safeImg!: SafeResourceUrl;
  profilePicture!: string;
  mainNavBarItems: MenuItem[] = [];
  sideNavBarItems: MenuItem[] = [];
  notifications: NotificationToScrollDTO[] = [];
  pageNumber = 1;
  notificationsDto: GetNotificationsDTO = {
    pageSize: 3,
    pageNumber: 1,
    createdToUserId: 0
  }
  menuItemQ: MenuItemQ = {
    roleId: 3,
    menuItemTypeId: 1
  }
  notificationUpdateQ: NotificationU = {
    notificationId: 0,
    isNew: false
  }
  userId = 0;

  constructor(
    public route: Router,
    private eRef: ElementRef,
    override store: Store,
    private utilService: UtilService,
    override loaderService: LoaderService,
    override toastService: ToastService,
    override signalService: SignalRService,
    private menuItemsService: MenuItemsService,
    override translocoService: TranslocoService,
    override action$?: Actions
  ) {
    super(store, toastService, action$, translocoService, undefined, signalService, undefined, loaderService);

    action$?.pipe(ofType(UserActions.GetProfilePictureSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.profilePictureBase64 !== null && response.data.profilePictureBase64 !== '') {
        this.safeImg = this.utilService.decodeBase64ImageFileToSecurityTrustResource(response.data.profilePictureBase64);
      }
    })

    action$?.pipe(ofType(NotificationActions.loadNotificationsByUserIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.length > 0) {
        this.notifications = [...this.notifications, ...JSON.parse(JSON.stringify(response.data))];
      }
    })

    action$?.pipe(ofType(NotificationActions.loadNotificationsCountByUserIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        this.newNotificationsCount = response.data;
      }
    })

    action$?.pipe(ofType(TokenActions.LoadTokenSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        let tokenR = response.data;
        let decodedToken = this.utilService.decodeToken(tokenR.token) as JwtDecode;

        const roleId = decodedToken.RoleId;

        this.store.dispatch(UserActions.GetProfilePicture());

        if (roleId) {
          this.sideNavBarItems = this.menuItemsService.getMenuItems(roleId?.toString(), 2);
        }
      }
    })

    action$?.pipe(ofType(TokenActions.LoadFacebookSignInSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        let tokenR = response.data;
        let decodedToken = this.utilService.decodeToken(tokenR.token) as JwtDecode;

        const roleId = decodedToken.RoleId;

        this.store.dispatch(UserActions.GetProfilePicture());

        if (roleId) {
          this.sideNavBarItems = this.menuItemsService.getMenuItems(roleId?.toString(), 2);
        }
      }
    })

    action$?.pipe(ofType(TokenActions.LoadGoogleSignInSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        let tokenR = response.data;
        let decodedToken = this.utilService.decodeToken(tokenR.token) as JwtDecode;

        const roleId = decodedToken.RoleId;

        this.store.dispatch(UserActions.GetProfilePicture());

        if (roleId) {
          this.sideNavBarItems = this.menuItemsService.getMenuItems(roleId?.toString(), 2);
        }
      }
    })

    action$?.pipe(ofType(UserActions.loadUserDataSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.notificationsDto = JSON.parse(JSON.stringify(this.notificationsDto))
        if (response.data.profilePicture) {
          this.safeImg = this.utilService.decodeBase64ImageFileToSecurityTrustResource(response.data.profilePicture);
        }
        if (response.data.userId) {
          this.userId = response.data.userId;
          this.signalService.invokeAddUserToOwnGroup(this.userId);
        }

        this.notificationsDto.createdToUserId = this.userId;
        this.store.dispatch(NotificationActions.loadNotificationsByUserId({ data: this.notificationsDto }));
        this.store.dispatch(NotificationActions.loadNotificationsCountByUserId());

        if (response.data.roleId) {
          this.sideNavBarItems = this.menuItemsService.getMenuItems(response.data.roleId.toString(), 2);
        }
      }
    })

    action$?.pipe(ofType(LessonTypeActions.loadLessonTypesSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.checkActualUrl();
    })

    action$?.pipe(ofType(TeacherActions.loadGetAllTeachersCountSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.checkActualUrl();
    })
  }

  getSafeImage(profilePicture: string) {
    return this.utilService.decodeBase64ImageFileToSecurityTrustResource(profilePicture);
  }

  async ngOnInit(): Promise<void> {
    await this.checkSignalRConnection();
    this.getMainMenuItems();
    this.subscriptionInit();
    this.checkActualUrl();
    this.signalService.hubConnection.on('ReceiveUserNotification', (notificationCount: number, notification: NotificationToScrollDTO) => {
      this.newNotificationsCount = notificationCount
      this.notifications.unshift(notification)
    })

    this.signalService.hubConnection.on('ReceiveUserNewNotificationsCount', (notificationCount: number) => {
      this.newNotificationsCount = notificationCount;
    })

    this.signalService.hubConnection.on('ReceiveNotificationsToScroll', (notifications: NotificationToScrollDTO[]) => {
      this.notifications = [...this.notifications, ...JSON.parse(JSON.stringify(notifications))];
      this.loaderService.hideLocalLoader();
    })
  }

  checkActualUrl() {
    const url = location.pathname;
    if (url) {
      this.checkMenuItemLink(url);
    }
  }

  checkMenuItemLink(menuItemLink: string) {
    const idx = this.mainNavBarItems.findIndex(x => x.menuItemLink === menuItemLink);
    if (idx > -1) {
      this.mainMenuItemClick(idx)
    }
  }

  loginClick() {
    this.route.navigateByUrl('/sign-in')
  }

  getMainMenuItems() {
    this.mainNavBarItems = this.menuItemsService.getMenuItems("3", 1);
  }

  setLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
    this.toggleDropdownLanguages();
  }

  subscriptionInit() {
    const token = localStorage.getItem('token');
    let decodedToken!: JwtDecode;
    let expDate!: Date;
    if (token) {
      decodedToken = this.utilService.decodeToken(token) as JwtDecode;
      expDate = this.utilService.decodeTokenExpired(decodedToken.exp);
    }

    if (!token) {
      this.sideNavBarItems = this.menuItemsService.getMenuItems("3", 2);
    }
    else if (expDate < new Date()) {
      this.sideNavBarItems = this.menuItemsService.getMenuItems("3", 2);
    }
    else {
      const userDataObsv = this.getUserDataFromStore(this.store);
      if (!userDataObsv) {
        this.store.dispatch(UserActions.loadUserData());
      }
    }

  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;

    if (this.isDropdownOpen === true) {
      this.isNotificationsOpen = false;
      this.isDropdownLanguagesOpen = false;
      this.isMobileDropdownOpen = false;
    }
  }

  toggleDropdownLanguages() {
    this.isDropdownLanguagesOpen = !this.isDropdownLanguagesOpen;

    if (this.isDropdownLanguagesOpen === true) {
      this.isNotificationsOpen = false;
      this.isMobileDropdownOpen = false;
      this.isDropdownOpen = false;
    }
  }

  toggleNotifications() {
    this.isNotificationsOpen = !this.isNotificationsOpen;
    if (this.isNotificationsOpen === true) {
      this.isDropdownLanguagesOpen = false;
      this.isMobileDropdownOpen = false;
      this.isDropdownOpen = false;
    }
  }

  setToggleFalse() {
    this.isDropdownOpen = false;
  }

  toggleMobileDropdown() {
    this.isMobileDropdownOpen = !this.isMobileDropdownOpen;
    if (this.isMobileDropdownOpen === true) {
      this.isNotificationsOpen = false;
      this.isDropdownLanguagesOpen = false;
      this.isDropdownOpen = false;
    }
  }

  newNotificationClick(notificationId: number) {
    let idx = this.notifications.findIndex(x => x.notificationId === notificationId);
    if (idx > -1) {
      if (this.notifications[idx].isNew) {
        this.notifications[idx].isNew = false;
        this.signalService.invokeUpdateUserNotificationCount(notificationId, this.userId);
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    const element = event.target as HTMLElement;

    if (!this.eRef.nativeElement.contains(element)) {
      this.isNotificationsOpen = false;
      this.isDropdownLanguagesOpen = false;
      this.isMobileDropdownOpen = false;
      this.isDropdownOpen = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.isNotificationsOpen = false;
      this.isDropdownLanguagesOpen = false;
      this.isMobileDropdownOpen = false;
      this.isDropdownOpen = false;
    }
  }

  onScroll(event: any) {
    const element = event.target;
    if ((element.scrollHeight - element.scrollTop === element.clientHeight) && this.showMoreNotificationsVisible == true) {
      this.pageNumber = this.pageNumber + 1;
      this.notificationsDto = JSON.parse(JSON.stringify(this.notificationsDto))
      this.notificationsDto.pageNumber = this.pageNumber;
      this.notificationsDto.pageSize = 3;
      this.notificationsDto.createdToUserId = this.userId;

      this.loaderService.showLocalLoader();

      this.signalService.invokeGetNotificationsToScroll(this.notificationsDto);
    }
  }

  showMoreNotificationsClick() {
    this.pageNumber = this.pageNumber + 1;
    this.notificationsDto = JSON.parse(JSON.stringify(this.notificationsDto))
    this.notificationsDto.pageNumber = this.pageNumber;
    this.notificationsDto.pageSize = 3;
    this.notificationsDto.createdToUserId = this.userId;
    this.showMoreNotificationsVisible = true;

    this.signalService.invokeGetNotificationsToScroll(this.notificationsDto);
  }

  mainMenuItemClick(idx: number) {
    this.selectedMenuItem = idx;
    this.isNotificationsOpen = false;
    this.isDropdownLanguagesOpen = false;
    this.isDropdownOpen = false;
    if (this.isMobileDropdownOpen === true) {
      this.isMobileDropdownOpen = false;
    }
  }
}
