import { createAction, props } from "@ngrx/store";
import { DeleteTeacherLessonQ, LessonR } from "../../../../teacher/models/lesson/lesson.model";

export const init = createAction(
    '[Lesson/API] Load Lesson',
    props<{ id: number }>()
);

export const loadGetTeacherLessonsByTeacherId = createAction(
    '[Lesson/API] Load Get Teacher Lessons By TeacherId',
    props<{ teacherId: number }>()
);

export const loadGetTeacherLessonsByTeacherIdSuccess = createAction(
    '[Lesson/API] Load Get Teacher Lessons By TeacherId Success',
    props<{ data: LessonR[] }>()
);

export const getAllLessonCategoryCount = createAction(
    '[Lesson/API] Load Get All Lesson Category Count'
);

export const getAllLessonCategoryCountSuccess = createAction(
    '[Lesson/API] Load Get All Lesson Category Count Success',
    props<{ data: number }>()
);

export const getAllLessonByLessonTypeId = createAction(
    '[Lesson/API] Load Get All Lesson By LessonTypeId',
    props<{ lessonTypeId: number }>()
);

export const getAllLessonByLessonTypeIdSuccess = createAction(
    '[Lesson/API] Load Get All Lesson By LessonTypeId Success',
    props<{ data: LessonR[] }>()
);

export const getTeacherLessonsByTeacher = createAction(
    '[Lesson/API] Load Get Teacher Lessons By Teacher'
);

export const getTeacherLessonsByTeacherSuccess = createAction(
    '[Lesson/API] Load Get Teacher Lessons By Teacher Success',
    props<{ data: LessonR[] }>()
);


export const getAllLessonByLessonTypeIds = createAction(
    '[Lesson/API] Load Get All Lesson By LessonTypeIds',
    props<{ lessonTypeIds: number[] }>()
);

export const getAllLessonByLessonTypeIdsSuccess = createAction(
    '[Lesson/API] Load Get All Lesson By LessonTypeIds Success',
    props<{ data: LessonR[] }>()
);

export const deleteTeacherLesson = createAction(
    '[Lesson/API] Load Delete Teacher Lesson',
    props<{ data: DeleteTeacherLessonQ }>()
);

export const deleteTeacherLessonSuccess = createAction(
    '[Lesson/API] Load Delete Teacher Lesson Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Lesson/API] Load Lessons Failure',
    props<{ error: any }>()
);