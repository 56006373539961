import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastService } from '../../../shared/services/toast/toast.service';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { GetTeacherByIdDTO } from 'src/app/shared/services/user/user.model';

@Component({
  selector: 'app-teacher-details',
  templateUrl: './teacher-details.component.html',
  styleUrls: ['./teacher-details.component.css']
})
export class TeacherDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isCollapsed = false;
  isProfExpCollapsed = false;
  selectedTeacher: GetTeacherByIdDTO = {
    successfulLessonsNumber: 0
  };
  teacherId = 0;

  constructor(
    private route: ActivatedRoute,
    override store: Store,
    override toastService: ToastService,
    override action$?: Actions
  ) {
    super(store, toastService, action$);

    action$?.pipe(ofType(TeacherActions.loadGetTeacherByIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data && response) {
        this.selectedTeacher = response.data;
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      })
    this.subscriptionInit();
  }

  subscriptionInit() {
    this.store.dispatch(TeacherActions.loadGetTeacherById({teacherId: this.teacherId}));
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  
  toggleProfExpCollapse(): void {
    this.isProfExpCollapsed = !this.isProfExpCollapsed;
  }
}
