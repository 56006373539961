import { createFeatureSelector, createSelector } from "@ngrx/store";
import { USER_FEATURE_KEY, State } from "./user.state";

export const getUserSate = createFeatureSelector<State>(USER_FEATURE_KEY);

export const getUserLoaded = createSelector(
    getUserSate,
    (state:State) => state.loaded
)

export const getUser = createSelector(
    getUserSate,
    (state:State) => state.data
)

export const getUserData = createSelector(
    getUserSate,
    (state:State) => state.userData
)