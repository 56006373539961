import { ProfilePictureR } from "../../../../shared/models/sign-up/sign-up.model";
import { LandingCountsDTO, UpdateUserSeededDTO, UpdateUserSubscriptionDTO, UserDataR } from "../user.model";
import { MenuItem } from "../../../../shared/models/menu/menu.model";

export const USER_FEATURE_KEY = 'USER';

export interface State{
    loaded:boolean;
    error?:string | null;
    data: UserDataR |null|ProfilePictureR| MenuItem[]|number|boolean|UpdateUserSubscriptionDTO|UpdateUserSeededDTO|LandingCountsDTO;
    userData?: UserDataR;
}