<div class="md:flex items-start py-12 2xl:px-20 md:px-6 px-4">
    <div class="xl:w-2/6 lg:w-2/5 md:block block img">
        @if (safeImg) {
        <img class="rounded-full w-96 h-96" [src]="this.safeImg" loading="lazy" alt="pics">
        }
        @else {
        <img src="./assets/images/profile_anonym.svg" class="w-96 h-96" loading="lazy" alt="anonym">
        }
    </div>
    <div class="xl:w-2/5 md:w-2/3 lg:ml-8 md:ml-6 md:mt-0 mt-6">
        <div class="border-b border-gray-200 pb-6">
            <h1 class="lg:text-2xl text-xl font-semibold lg:leading-6 leading-7 text-gray-800 dark:text-white mt-2">
                {{selectedTeacher.firstName }} {{selectedTeacher.lastName}}</h1>
        </div>
        <div class="py-4 border-b border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-800">{{'teacher.teacher_detail_list.teaching' |
                transloco}}: </p>
            <div class="px-6 py-1">
                @if(selectedTeachersLessons){
                @for(lesson of selectedTeachersLessons; track $index){
                <span
                    class="inline-block bg-dark-green rounded-full px-2 py-1 text-sm font-semibold text-white mr-1 mb-1">#{{lesson.lessonName}}</span>
                }
                }
            </div>
        </div>
        <div class="py-4 border-b border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-800">
                {{'teacher.teacher_detail_list.done_lessons_number' | transloco}}: </p>
            <div class="flex items-center justify-center">
                <p class="text-sm leading-none text-gray-600 font-semibold mr-3">
                    {{selectedTeacher.successfulLessonsNumber}}</p>
            </div>
        </div>
        <div class="py-4 border-b border-t border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-800">{{'teacher.teacher_detail_list.socials' |
                transloco}}: </p>
            <div class="justify-center align-middle">
                <social-media-list-component></social-media-list-component>
            </div>
        </div>
        <div class="border-b border-gray-200 flex items-center justify-between">
            <p class="xl:pr-48 text-base lg:leading-tight leading-normal text-dark-green mt-7">
                {{'teacher.teacher_detail_list.last_assessment' | transloco}}:</p>
            <p class="text-base py-4 leading-4 mt-7 text-gray-600 font-semibold dark:text-gray-500">
                @if(teacherLastAssessment){
                @if(teacherLastAssessment.email && teacherLastAssessment.email!==''){
                {{teacherLastAssessment.lastName }} {{teacherLastAssessment.firstName }}
                }@else{
                {{'common.deleted' | transloco}} {{'common.user' | transloco}}
                }
            <p class="mt-1 max-w-2xl text-s text-gray-500 break-words">{{teacherLastAssessment.teacherTextAssessment}}
            </p>
            }@else{
            {{'teacher.teacher_detail_list.no_assessment' | transloco}}
            }
            </p>
        </div>
        @if(selectedTeacher.isPhonePublic && selectedTeacher.phoneNumber && selectedTeacher.isPhonePublic === true){
        <div class="py-4 border-b border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-800">
                {{'teacher.teacher_detail_list.phone' | transloco}}: </p>
            <div class="flex items-center justify-center">
                <p class="text-sm leading-none text-gray-600 font-semibold mr-3">
                    {{selectedTeacher.phoneNumber}}</p>
            </div>
        </div>
        }

        @if(selectedTeacher.isEmailPublic && selectedTeacher.email && selectedTeacher.isEmailPublic === true){
        <div class="py-4 border-b border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-800">
                {{'teacher.teacher_detail_list.email' | transloco}}: </p>
            <div class="flex items-center justify-center">
                <p class="text-sm leading-none text-gray-600 font-semibold mr-3">
                    {{selectedTeacher.email}}</p>
            </div>
        </div>
        <div class="py-4 border-b border-t border-gray-200 items-center">
            <div class="py-1 text-center">
                <button type="button" (click)="sendEmailToTeacherClick()" class="btn-primary mr-2" type="button">
                    <p class="justify-self-center"> {{'teacher.teacher_detail_list.send_email' | transloco}} </p>
                </button>
            </div>
        </div>
        }
        <div class="py-4 border-b border-t border-gray-200 items-center">
            <div class="py-1 text-center">
                <button type="button" (click)="sendReviewClick()" class="btn-primary mr-2" type="button">
                    <p class="justify-self-center"> {{'teacher.teacher_detail_list.send_assessment' | transloco}} </p>
                </button>
            </div>
        </div>

    </div>
</div>
<div class="md:flex items-start justify-center py-1 2xl:px-20 md:px-6 px-4">
    <app-scrollspy [menuItems]="menuItems"></app-scrollspy>
</div>