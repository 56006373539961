import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SeededTeacherWithLessonsModel } from '../../models/seeded-teachers-cards/seeded-teacher-cards.model';
import { UtilService } from '../../services/utils/utils.serivce';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'seeded-teachers-cards',
  standalone: true,
  imports: [CommonModule, TranslocoModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UtilService],
  templateUrl: './seeded-teachers-cards.component.html',
  styleUrl: './seeded-teachers-cards.component.css'
})
export class SeededTeachersCardsComponent implements OnInit {
  utilService = inject(UtilService);
  queryParams: { teacherId: number; } | undefined;
  teacherLessonNames: string[] = [];
  private _s: SeededTeacherWithLessonsModel = {
    userId: 0
  };

  ngOnInit(): void {
    this.queryParams = { teacherId: this.seededTeacher.userId }
    this.createLessons();
  }

  createLessons() {
    if (this.seededTeacher.lessonNames && this.seededTeacher.lessonNames.length > 0) {
      this.teacherLessonNames = this.seededTeacher.lessonNames?.split(',');
    }
  }

  get seededTeacher(): SeededTeacherWithLessonsModel {
    return this._s;
  }

  @Input() set seededTeacher(value: SeededTeacherWithLessonsModel) {
    this._s = {
      ...value,
      picture: this.utilService.decodeBase64ImageFileToSecurityTrustResource(value.profilePicture!)
    }
  }
}
