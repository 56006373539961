<div class="grid grid-rows-2 gap-4 m-5">
    <div class="row-start-1 lg:w-2/3 md:w-2/3 w-full justify-self-center">
        <div
            class="max-w justify-center p-6 m-6 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 sign-up-background">
            <div>
                <h3 class="text-white text-center mb-6 text-2xl font-bold">{{'common.registration' | transloco}}</h3>
            </div>
            <form [formGroup]="form" class="group" (ngSubmit)="submit()">
                <div class="grid grid-cols-1 gap-4 ">
                    <div class="w-full mb-6"
                        [class.error]="form.controls['phoneNumber'].invalid && form.controls['phoneNumber'].touched">
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="phoneNumber"
                                class=" mb-2 text-sm font-medium text-white dark:text-white">{{'common.phone_number' |
                                transloco}}:
                            </label>
                            <input type="text" id="phoneNumber" class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green focus:border-light-green  w-full p-2.5
                            invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                                placeholder="+36201025100" formControlName="phoneNumber" required>
                            @if(form.controls['phoneNumber'].touched){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                                getError(form.controls['phoneNumber'])
                                }}</span>
                            }
                        </div>
                    </div>

                    <label for="roleType" class="mb-2 text-sm font-medium text-white dark:text-white">
                        {{'common.registration_goal' | transloco}}
                    </label>
                    <select id="roleType" formControlName="roleId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-light-green focus:border-light-green block w-full p-2.5
                        invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500">
                        <option value="1" selected>{{'common.teacher_role' | transloco}}</option>
                        <option value="2">{{'common.student_role' | transloco}}</option>
                    </select>
                    <label for="public" class="mb-2 text-sm font-medium text-white dark:text-white">{{'common.public' |
                        transloco}}:
                    </label>
                    <div class="flex flex-wrap"
                        [class.error]="form.controls['isAszfAccepted'].errors && form.controls['isAszfAccepted'].touched">
                        <input type="checkbox" formControlName="isAszfAccepted"
                            class="w-6 h-6 me-2 bg-gray-100 rounded text-light-green focus:ring-light-green" />
                        <label for="isAszfAccepted" class="mb-2 text-sm font-medium text-white dark:text-white">
                            {{'common.isAszfAccepted' | transloco}}
                        </label>
                        @if(form.errors?.['isAszfNoAccepted']){
                        <span class="ml-5 text-red-600 text-xs"> {{'validation.error.aszf' | transloco}}</span>
                        }
                    </div>
                    @if(selectedRole === '1'){
                    <div class="flex flex-wrap">
                        <input type="checkbox" formControlName="isPhonePublic"
                            class="w-6 h-6 me-2 bg-gray-100 rounded text-light-green focus:ring-light-green" />
                        <label for="isPhonePublic" class="mb-2 text-sm font-medium text-white dark:text-white">
                            {{'common.isPhonePublicLabel' | transloco}}
                        </label>
                    </div>

                    <div class="flex flex-wrap">
                        <input type="checkbox" formControlName="isEmailPublic"
                            class="w-6 h-6 me-2 bg-gray-100 rounded text-light-green focus:ring-light-green" />
                        <label for="isEmailPublic" class="mb-2 text-sm font-medium text-white dark:text-white">
                            {{'common.isEmailPublicLabel' | transloco}}
                        </label>
                    </div>
                    }

                    <button [disabled]="!form.valid" type="submit" class="login_button_firebase disabled:pointer-events-none disabled:opacity-30
                        justify-self-center w-full text-white font-bold px-4 py-2 rounded">
                        <p class="justify-self-center">{{'common.registration' | transloco}}</p>
                    </button>
                    <button type="button"
                        class="bg-light-yellow hover:bg-dark-yellow justify-self-center w-full text-white font-bold px-4 py-2 rounded"
                        (click)="backClicked()">
                        <p class="justify-self-center">{{'common.back' | transloco}}</p>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>