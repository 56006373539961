import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpComponent } from './shared/components/sign-up/sign-up.component';
import { TeacherDetailsComponent } from './teacher/components/teacher-details/teacher-details.component';
import { SignInComponent } from './shared/components/sign-in/sign-in.component';
import { SignOutComponent } from './shared/components/sign-out/sign-out.component';
import { StudentAuthGuard } from './student/guards/student-auth-guard.component';
import { TeacherAuthGuard } from './teacher/guard/teacher-auth-guard.component';
import { LandingHomeComponent } from './landing/home/home.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { AszfComponent } from './shared/components/aszf/aszf.component';
import { TeacherFilterComponent } from './student/components/teacher-filter/teacher-filter.component';
import { SignUpOfComponent } from './shared/components/sign-up-of/sign-up-of.component';
import { SignUpProviderComponent } from './shared/components/sign-up-provider/sign-up-provider.component';
import { ConfirmEmailComponent } from './shared/components/confirm-email/confirm-email.component';

const routes: Routes = [
  {
    path: '',
    component: LandingHomeComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'sign-up-of',
    component: SignUpOfComponent
  },
  {
    path: 'sign-up-provider',
    component: SignUpProviderComponent
  },
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {
    path: 'sign-out',
    component: SignOutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailComponent
  },
  {
    path: 'aszf',
    component: AszfComponent
  },
  {
    path: 'teacher-details',
    component: TeacherDetailsComponent
  },
  {
    path: 'teacher-filter',
    component: TeacherFilterComponent
  },
  {
    path: 'student',
    loadChildren: () => import('./student/student.module').then(m => m.StudentModule),
    canActivate: [StudentAuthGuard]
  },
  {
    path: 'teacher',
    loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule),
    canActivate: [TeacherAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
