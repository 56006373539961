import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeacherR } from '../../../teacher/models/teacher/teacher.model';
import { LessonR } from '../../../teacher/models/lesson/lesson.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';

@Component({
  selector: 'app-teacher-filter',
  templateUrl: './teacher-filter.component.html'
})
export class TeacherFilterComponent extends BaseComponent implements OnInit {
  idFromQuery!: number;
  teachers: TeacherR[] = [];
  selectedTeachersLessons: LessonR[] = [];
  constructor(
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.idFromQuery = params['id'] as number;
      })
  }

  teacherListVisible(teachers: TeacherR[]) {
    this.teachers = teachers;
  }
}
