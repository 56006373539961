import { createAction, props } from "@ngrx/store";
import { CreateEventQ, DeleteConnectedEventsDTO, DeletedEventDetailsModel, EventDetailsToJoinEventDTO, EventR, EventScheduleR, EventsToCalendarDTO, EventsToTimeTableDTO, GetConnectedEventsQ, GetConnectedEventsToUpdateQ, GetConnectedEventsToUpdateR, JoinEventDTO, StudentEventListDTO, StudentEventListQ, TeacherEventListDTO, TeacherEventListModel, TeacherEventListQ, UpdateEventIsAcceptedQ, UpdateEventIsArchiveQ, UpdateEventIsDoneQ } from "../../../../shared/models/event/event.model";
import { GetUsersDataByEventIdR } from "../../user/user.model";

export const init = createAction(
    '[Event/API] Init Event',
    props<{ id: number }>()
);

export const getEvent = createAction(
    '[Event/API] Load Event',
    props<{ eventId: string }>()
);

export const getEventSuccess = createAction(
    '[Event/API] Load Event Success',
    props<{ data: EventR }>()
);

export const getEventsBySchedule = createAction(
    '[Event/API] Load Get Events By Scedule',
    props<{ data: EventScheduleR }>()
);

export const getEventsByScheduleSuccess = createAction(
    '[Event/API] Load Get Events By Scedule Success',
    props<{ data: EventsToTimeTableDTO[] }>()
);

export const getEventsToCalendar = createAction(
    '[Event/API] Load Get Events To Calendar',
    props<{ data: EventScheduleR }>()
);

export const getEventsToCalendarSuccess = createAction(
    '[Event/API] Load Get Events To Calendar Success',
    props<{ data: EventsToCalendarDTO[] }>()
);

export const CreateEvent = createAction(
    '[Event/API] Create Event',
    props<{ createEventQ: CreateEventQ }>()
);

export const CreateEventSuccess = createAction(
    '[Event/API] Create Event Success',
    props<{ data: EventR }>()
);

export const CreateEvents = createAction(
    '[Event/API] Create Events',
    props<{ createEventQ: CreateEventQ[] }>()
);

export const CreateEventsSuccess = createAction(
    '[Event/API] Create Events Success',
    props<{ data: number }>()
);

export const createAvailableEvents = createAction(
    '[Event/API] Create Available Events',
    props<{ createEventQ: CreateEventQ[] }>()
);

export const createAvailableEventsSuccess = createAction(
    '[Event/API] Create Available Events Success',
    props<{ data: number }>()
);

export const JoinEvent = createAction(
    '[Event/API] Join Events',
    props<{ dto: JoinEventDTO }>()
);

export const JoinEventSuccess = createAction(
    '[Event/API] Join Events Success',
    props<{ data: number }>()
);

export const GetEventByTeacher = createAction(
    '[Event/API] Get Event By Teacher',
    props<{ data: TeacherEventListQ }>()
);

export const GetEventByTeacherSuccess = createAction(
    '[Event/API] Get Event By Teacher Success',
    props<{ data: TeacherEventListDTO }>()
);

export const GetArchiveEventsTeacherEventsList = createAction(
    '[Event/API] Get Archive Events To Teacher Events List',
    props<{ data: TeacherEventListQ }>()
);

export const GetArchiveEventsTeacherEventsListSuccess = createAction(
    '[Event/API] Get Archive Events To Teacher Events List Success',
    props<{ data: TeacherEventListDTO }>()
);

export const GetUnAcceptedEventsTeacherEventsList = createAction(
    '[Event/API] Get UnAccepted Events To Teacher Events List',
    props<{ data: TeacherEventListQ }>()
);

export const GetUnAcceptedEventsTeacherEventsListSuccess = createAction(
    '[Event/API] Get UnAccepted Events To Teacher Events List Success',
    props<{ data: TeacherEventListDTO }>()
);

export const failure = createAction(
    '[Event/API] Load Event Failure',
    props<{ error: any }>()
);

export const DeleteEvent = createAction(
    '[Event/API] Delete Event',
    props<{ data: number }>()
);

export const DeleteEventSuccess = createAction(
    '[Event/API] Delete Event Success',
    props<{ data: number }>()
);

export const DeleteEvents = createAction(
    '[Event/API] Delete Events',
    props<{ data: string }>()
);

export const DeleteEventsSuccess = createAction(
    '[Event/API] Delete Events Success',
    props<{ data: number }>()
);

export const GetUsersDataByEventId = createAction(
    '[Event/API] Load Get Emails By EventId',
    props<{ data: number }>()
);

export const GetUsersDataByEventIdSuccess = createAction(
    '[Event/API] Get Emails By EventId Success',
    props<{ data: GetUsersDataByEventIdR[] }>()
);

export const UpdateEventIsAccepted = createAction(
    '[Event/API] Load Update Event IsAccepted',
    props<{ data: UpdateEventIsAcceptedQ }>()
);

export const UpdateEventIsAcceptedSuccess = createAction(
    '[Event/API] Load Update Event IsAccepted Success',
    props<{ data: number }>()
);

export const UpdateEventIsDone = createAction(
    '[Event/API] Load Update Event IsDone',
    props<{ data: UpdateEventIsDoneQ }>()
);

export const UpdateEventIsDoneSuccess = createAction(
    '[Event/API] Load Update Event IsDone Success',
    props<{ data: number }>()
);

export const UpdateEventIsArchive = createAction(
    '[Event/API] Load Update Event IsArchive',
    props<{ data: UpdateEventIsArchiveQ }>()
);

export const UpdateEventIsArchiveSuccess = createAction(
    '[Event/API] Load Update Event IsArchive Success',
    props<{ data: number }>()
);


export const DeleteStudentFromEvent = createAction(
    '[Event/API] Load Delete Student From Event',
    props<{ data: number }>()
);

export const DeleteStudentFromEventSuccess = createAction(
    '[Event/API] Load Delete Student From Event Success',
    props<{ data: number }>()
);


export const getEventDetailsToJoinEvent = createAction(
    '[Event/API] Load Get Event Details To Join Event',
    props<{ data: number }>()
);

export const getEventDetailsToJoinEventSuccess = createAction(
    '[Event/API] Load Get Event Details To Join Event Success',
    props<{ data: EventDetailsToJoinEventDTO }>()
);

export const checkUserExistOnEvent = createAction(
    '[Event/API] Load Check User Exists On Event',
    props<{ data: number }>()
);

export const checkUserExistOnEventSuccess = createAction(
    '[Event/API] Load Check User Exists On Event Success',
    props<{ data: number }>()
);

export const getConnectedEvents = createAction(
    '[Event/API] Load Get Connected Events',
    props<{ data: GetConnectedEventsQ }>()
);

export const getConnectedEventsSuccess = createAction(
    '[Event/API] Load Get Connected Events Success',
    props<{ data: DeleteConnectedEventsDTO[] }>()
);

export const getConnectedEventsToUpdate = createAction(
    '[Event/API] Load Get Connected Events To Update',
    props<{ data: GetConnectedEventsToUpdateQ }>()
);

export const getConnectedEventsToUpdateSuccess = createAction(
    '[Event/API] Load Get Connected Events To Update Success',
    props<{ data: GetConnectedEventsToUpdateR }>()
);

export const GetEventsByStudentId = createAction(
    '[Event/API] Get Event By Student Id',
    props<{ data: StudentEventListQ }>()
);

export const GetEventsByStudentIdSuccess = createAction(
    '[Event/API] Get Event By Student Id Success',
    props<{ data: StudentEventListDTO }>()
);


export const GetArchiveEventsByStudentId = createAction(
    '[Event/API] Get Archive Event By Student Id',
    props<{ data: StudentEventListQ }>()
);

export const GetArchiveEventsByStudentIdSuccess = createAction(
    '[Event/API] Get Archive Event By Student Id Success',
    props<{ data: StudentEventListDTO }>()
);


export const GetUnAcceptedEventsByStudentId = createAction(
    '[Event/API] Get UnAccepted Event By Student Id',
    props<{ data: StudentEventListQ }>()
);

export const GetUnAcceptedEventsByStudentIdSuccess = createAction(
    '[Event/API] Get UnAccepted Event By Student Id Success',
    props<{ data: StudentEventListDTO }>()
);

export const GetStudentsEmailsByEventIds = createAction(
    '[Event/API] Get Students Emails By Event Ids',
    props<{ data: string }>()
);

export const GetStudentsEmailsByEventIdsSuccess = createAction(
    '[Event/API] Get Students Emails By Event Ids Success',
    props<{ data: string[] }>()
);

export const GetDeletedEventsDetails = createAction(
    '[Event/API] Get Deleted Events Details',
    props<{ data: string }>()
);

export const GetDeletedEventsDetailsSuccess = createAction(
    '[Event/API] Get Deleted Events Details Success',
    props<{ data: DeletedEventDetailsModel[] }>()
);