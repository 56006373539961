import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private messageSubject = new BehaviorSubject<string>('');
  private apiLocalCount = 0;
  private isLoadingLocalSubject = new BehaviorSubject<boolean>(false);
  private messageLocalSubject = new BehaviorSubject<string>('');

  isLoading$ = this.isLoadingSubject.asObservable();
  message$ = this.messageSubject.asObservable();
  isLocalLoading$ = this.isLoadingLocalSubject.asObservable();
  messageLocal$ = this.messageLocalSubject.asObservable();

  constructor(
    private zone: NgZone
  ) { }

  showLoader(message?:string) {
    this.zone.run(() => {
      if (this.apiCount === 0) {
        this.isLoadingSubject.next(true);
        if(message){
          this.messageSubject.next(message);
        }
      }
      this.apiCount++;
    });
  }

  hideLoader() {
    this.zone.run(() => {
      this.apiCount--;
      if (this.apiCount === 0) {
        this.isLoadingSubject.next(false);
        this.messageSubject.next('');
      }
    });
  }

  showLocalLoader(message?:string) {
    this.zone.run(() => {
      if (this.apiLocalCount === 0) {
        this.isLoadingLocalSubject.next(true);
        if(message){
          this.messageLocalSubject.next(message);
        }
      }
      this.apiLocalCount++;
    });
  }

  hideLocalLoader() {
    this.zone.run(() => {
      this.apiLocalCount--;
      if (this.apiLocalCount === 0) {
        this.isLoadingLocalSubject.next(false);
        this.messageLocalSubject.next('');
      }
    });
  }
}