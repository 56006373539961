import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as LocaitonActions from '../location-store/location.action';
import { catchError, concatMap, map, of } from "rxjs";
import { LocationService } from "../location.service";


@Injectable()
export class LocationEffects {

    getAllLocationTypes$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.getAllLocationTypes),
            concatMap(() => this.locaitonService.getAllLocationTypes().pipe(
                map((data) => {
                    return LocaitonActions.getAllLocationTypesSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    getAllLocationByTypeId$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.getAllLocationByTypeId),
            concatMap((action) => this.locaitonService.getAllLocationByTypeId(action.locationTypeId).pipe(
                map((data) => {
                    return LocaitonActions.getAllLocationByTypeIdSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    
    getAllOfflineLocations$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.getAllOfflineLocations),
            concatMap(() => this.locaitonService.getAllOfflineLocations().pipe(
                map((data) => {
                    return LocaitonActions.getAllOfflineLocationsSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    getAllPlatforms$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.getAllPlatforms),
            concatMap(() => this.locaitonService.getAllPlatforms().pipe(
                map((data) => {
                    return LocaitonActions.getAllPlatformsSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    insertLocations$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.insertLocations),
            concatMap((action) => this.locaitonService.insertLocations(action.data).pipe(
                map((data) => {
                    return LocaitonActions.insertLocationsSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    updateOnlineLocation$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.updateOnlineLocation),
            concatMap((action) => this.locaitonService.updateOnlineLocation(action.data).pipe(
                map((data) => {
                    return LocaitonActions.updateOnlineLocationSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    updateOfflineLocation$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.updateOfflineLocation),
            concatMap((action) => this.locaitonService.updateOfflineLocation(action.data).pipe(
                map((data) => {
                    return LocaitonActions.updateOfflineLocationSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    deleteOfflineLocation$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.deleteOfflineLocation),
            concatMap((action) => this.locaitonService.deleteOfflineLocation(action.data).pipe(
                map((data) => {
                    return LocaitonActions.deleteOfflineLocationSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    deleteOnlineLocation$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.deleteOnlineLocation),
            concatMap((action) => this.locaitonService.deleteOnlineLocation(action.data).pipe(
                map((data) => {
                    return LocaitonActions.deleteOnlineLocationSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    
    getOnlineLocationById$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.getOnlineLocationById),
            concatMap((action) => this.locaitonService.getOnlineLocationById(action.data).pipe(
                map((data) => {
                    return LocaitonActions.getOnlineLocationByIdSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });

    getOfflineLocationById$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LocaitonActions.getOfflineLocationById),
            concatMap((action) => this.locaitonService.getOfflineLocationById(action.data).pipe(
                map((data) => {
                    return LocaitonActions.getOfflineLocationByIdSuccess({ data });
                }),
                catchError(() => of(LocaitonActions.failure({ error: "Load Locaitons failure" })))
            )
            )
        );
    });
    
    constructor(
        private action$: Actions,
        private locaitonService: LocationService
    ) { }
}