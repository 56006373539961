import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as LessonTypeActions from './lesson-type.action';
import { catchError, concatMap, map, of } from "rxjs";
import { LessonTypeService } from "../lesson-type.service";


@Injectable()
export class LessonTypeEffects {

    loadLessonTypes$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonTypeActions.loadLessonTypes),
            concatMap(() => this.lessonTypeService.getAllLessonTypes().pipe(
                map((data) => {
                    return LessonTypeActions.loadLessonTypesSuccess({ data });
                }),
                catchError(() => of(LessonTypeActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });


    constructor(
        private action$: Actions,
        private lessonTypeService: LessonTypeService
    ) { }
}