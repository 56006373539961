import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as TeacherActions from './teacher.action';
import { catchError, concatMap, map, of } from "rxjs";
import { TeacherService } from "../teacher.service";


@Injectable()
export class TeacherEffects {
    loadTeacherAssessments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadTeacherAssessments),
            concatMap((action) => this.teacherService.getTeacherTextAssessmentsById(action.id).pipe(
                map((data) => {
                    return TeacherActions.loadTeacherAssessmentsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getAllTeachersCount$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadGetAllTeachersCount),
            concatMap(() => this.teacherService.getAllTeachersCount().pipe(
                map((data) => {
                    return TeacherActions.loadGetAllTeachersCountSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getTeachersSuccessfulLessonsNumber$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadGetTeachersSuccessfulLessonsNumber),
            concatMap(() => this.teacherService.getTeachersSuccessfulLessonsNumber().pipe(
                map((data) => {
                    return TeacherActions.loadGetTeachersSuccessfulLessonsNumberSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getTeacherById$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadGetTeacherById),
            concatMap((action) => this.teacherService.getTeacherById(action.teacherId).pipe(
                map((data) => {
                    return TeacherActions.loadGetTeacherByIdSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getTeacherByFilters$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadGetTeacherByFilters),
            concatMap((action) => this.teacherService.getTeacherByFilters(action.filter).pipe(
                map((data) => {
                    return TeacherActions.loadGetTeacherByFiltersSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getAllTeacherNumberAssessments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadGetAllTeacherNumberAssessments),
            concatMap((action) => this.teacherService.getAllTeacherNumberAssessments(action.teacherId).pipe(
                map((data) => {
                    return TeacherActions.loadGetAllTeacherNumberAssessmentsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getAllTeacherTextAssessments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.loadGetTeacherTextAssessmentsById),
            concatMap((action) => this.teacherService.getTeacherTextAssessmentsById(action.teacherId).pipe(
                map((data) => {
                    return TeacherActions.loadGetTeacherTextAssessmentsByIdSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    insertTeacherAssessment$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.insertTeacherAssessment),
            concatMap((action) => this.teacherService.insertTeacherAssessment(action.teacherAssessment).pipe(
                map((data) => {
                    return TeacherActions.insertTeacherAssessmentSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    insertTeacherCallBack$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.insertTeacherCallBack),
            concatMap((action) => this.teacherService.insertTeacherCallBack(action.data).pipe(
                map((data) => {
                    return TeacherActions.insertTeacherCallBackSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getTeacherCallBackCount$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.getTeacherCallBackCount),
            concatMap((action) => this.teacherService.getTeacherCallBackCount(action.data).pipe(
                map((data) => {
                    return TeacherActions.getTeacherCallBackCountSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    insertTeacherSocials$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.insertTeacherSocials),
            concatMap((action) => this.teacherService.insertTeacherSocials(action.teacherSocials).pipe(
                map((data) => {
                    return TeacherActions.insertTeacherSocialsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    insertTeacherLessons$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.insertTeacherLessons),
            concatMap((action) => this.teacherService.insertTeacherLessons(action.data).pipe(
                map((data) => {
                    return TeacherActions.insertTeacherLessonsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    insertTeacherDocuments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.insertTeacherDocuments),
            concatMap((action) => this.teacherService.insertTeacherDocuments(action.documents).pipe(
                map((data) => {
                    return TeacherActions.insertTeacherDocumentsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getTeacherSocials$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.getTeacherSocials),
            concatMap((action) => this.teacherService.getTeacherSocials(action.teacherId).pipe(
                map((data) => {
                    return TeacherActions.getTeacherSocialsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    updateTeacherTotalRatings$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.updateTeacherTotalRatings),
            concatMap((action) => this.teacherService.updateTeacherTotalRatings(action.teacherId).pipe(
                map((data) => {
                    return TeacherActions.updateTeacherTotalRatingsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getTeacherTotalRatings$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.getTeacherTotalRatings),
            concatMap((action) => this.teacherService.getTeacherTotalRatings(action.teacherId).pipe(
                map((data) => {
                    return TeacherActions.getTeacherTotalRatingsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    sendEmailToAddresses$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.sendEmailToAddresses),
            concatMap((action) => this.teacherService.sendEmailToAddresses(action.data).pipe(
                map((data) => {
                    return TeacherActions.sendEmailToAddressesSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    sendEmailById$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.sendEmailById),
            concatMap((action) => this.teacherService.sendEmailById(action.data).pipe(
                map((data) => {
                    return TeacherActions.sendEmailByIdSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    insertTeacherLessonRequests$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.insertTeacherLessonRequests),
            concatMap((action) => this.teacherService.insertTeacherLessonRequests(action.data).pipe(
                map((data) => {
                    return TeacherActions.insertTeacherLessonRequestsSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    getSeededTeachers$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.getSeededTeachers),
            concatMap(() => this.teacherService.getSeededTeachers().pipe(
                map((data) => {
                    return TeacherActions.getSeededTeachersSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    createCalendarEventAsync$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherActions.createCalendarEventAsync),
            concatMap((action) => this.teacherService.createCalendarEventAsync(action.data).pipe(
                map((data) => {
                    return TeacherActions.createCalendarEventAsyncSuccess({ data });
                }),
                catchError(() => of(TeacherActions.failure({ error: "Load Teachers failure" })))
            )
            )
        );
    });

    constructor(
        private action$: Actions,
        private teacherService: TeacherService
    ) { }
}