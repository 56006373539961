import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'attachment-file',
  templateUrl: './attachment-file.component.html'
})
export class AttachmentFileComponent {

  @Input()
  file!: File;

  @Input()
  idx = 0;

  @Input()
  isDeleted = false;

  @Output() pdfDeleted = new EventEmitter<number>();

  xButtonClick(): void {
    this.isDeleted = true;
    this.pdfDeleted.emit(this.idx);
  }

}
