import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'step-card',
  templateUrl: './step-card.component.html',
  styleUrls: ['./step-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepCardComponent {
  @Input()
  title!: string;

  @Input()
  content!: string;

  @Input()
  number!: number;

}
