import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./lesson-type.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as LessonTypeActions from './lesson-type.action';
import { LessonTypeR } from '../../../../teacher/models/lesson-type/lesson-type.model';

export const LessonTypeAdapter: EntityAdapter<LessonTypeR> =
    createEntityAdapter<LessonTypeR>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const LessonTypeReducer = createReducer(
    initialState,
    on(LessonTypeActions.init, () =>
        ({ ...initialState })
    ),
    on(
        LessonTypeActions.loadLessonTypesSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return LessonTypeReducer(state,aciton);
}