import { Component, OnDestroy, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import * as fromTeacher from '../../../teacher/services/teacher/teacher-store/teacher.selector';
import * as LessonActions from '../../../teacher/services/lesson/lesson-store/lesson.action';
import * as fromLesson from '../../../teacher/services/lesson/lesson-store/lesson.selector';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UtilService } from '../../../shared/services/utils/utils.serivce';
import * as UserActions from '../../../shared/services/user/user-store/user.action';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { TOAST_STATE, ToastService } from '../../../shared/services/toast/toast.service';
import { LessonR } from '../../models/lesson/lesson.model';
import { TeacherAssessmentR } from '../../models/teacher/teacher-assessment.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SendTeacherAssessmentModalComponent } from '../../../student/modals/send-teacher-assessment-modal/send-teacher-assessment-modal.component';
import { SendEmailToAddressModalComponent } from 'src/app/shared/modals/send-email-to-address/send-email-to-address.component';
import { GetTeacherByIdDTO, UserDataR } from 'src/app/shared/services/user/user.model';
import * as fromUser from '../../../shared/services/user/user-store/user.selector';

@Component({
  selector: 'teacher-detail-list-component',
  templateUrl: './teacher-detail-list-component.component.html',
  styleUrls: ['./teacher-detail-list-component.component.css']
})
export class TeacherDetailListComponentComponent extends BaseComponent implements OnInit, OnDestroy {
  showSendTeacherAssessmentModalRef: MatDialogRef<SendTeacherAssessmentModalComponent, boolean> | undefined = undefined;
  showSendEmailToTeacherModalRef: MatDialogRef<SendEmailToAddressModalComponent, boolean> | undefined = undefined;
  menuItems = ["Rólam", "Órarend", "Értékelések"]
  selectedTeacher: GetTeacherByIdDTO = {
    successfulLessonsNumber: 0
  };
  selectedTeachersLessons: LessonR[] = [];
  teacherAllAssessments: TeacherAssessmentR[] = [];
  teacherLastAssessment!: TeacherAssessmentR;
  teacherId = 0;
  safeImg!: SafeResourceUrl;
  userData!: UserDataR;

  constructor
    (
      private route: ActivatedRoute,
      override store: Store,
      private utilService: UtilService,
      override toastService: ToastService,
      private dialogService: MatDialog,
      override action$?: Actions
    ) {
    super(store, toastService, action$);
    action$?.pipe(ofType(TeacherActions.loadGetTeacherTextAssessmentsByIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data.length > 0 && response.data) {
        this.teacherLastAssessment = response.data[0];
      }
    })

    action$?.pipe(ofType(TeacherActions.insertTeacherAssessmentSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.store.dispatch(TeacherActions.loadGetTeacherTextAssessmentsById({ teacherId: this.teacherId }));
    })

    action$?.pipe(ofType(UserActions.loadUserDataSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        this.userData = response.data;
      }
    })

    action$?.pipe(ofType(UserActions.GetProfilePictureToDetailsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.profilePictureBase64 !== null && response.data.profilePictureBase64 !== '') {
        this.safeImg = this.utilService.decodeBase64ImageFileToSecurityTrustResource(response.data.profilePictureBase64);
      }
    })

    action$?.pipe(ofType(LessonActions.loadGetTeacherLessonsByTeacherIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.length > 0) {
        this.selectedTeachersLessons = response.data;
      }
    })

    action$?.pipe(ofType(TeacherActions.loadGetTeacherByIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.successfulLessonsNumber) {
        this.selectedTeacher = JSON.parse(JSON.stringify(response.data));
        if (response.data.successfulLessonsNumber && response.data.successfulLessonsNumber < 0) {
          this.selectedTeacher.successfulLessonsNumber = 0;
        }
      }
    })
  }


  async ngOnInit(): Promise<void> {
    await this.checkSignalRConnection();
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      })
    this.subscriptionInit();
  }

  subscriptionInit() {
    const teacherData = this.store.select(fromTeacher.getTeacherById);

    teacherData
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response != null && response !== undefined) {
          this.selectedTeacher = JSON.parse(JSON.stringify(response));
        }
      })

    const userData = this.store.select(fromUser.getUserData);

    userData
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response != null && response !== undefined) {
          this.userData = response
        }
      })

    this.store.dispatch(UserActions.GetProfilePictureToDetails({ data: this.teacherId }));
    this.store.dispatch(LessonActions.loadGetTeacherLessonsByTeacherId({ teacherId: this.teacherId }));
  }

  sendEmailToTeacherClick() {
    this.openSendEmailToTeacherModal();
  }

  openSendEmailToTeacherModal() {
    if (this.showSendEmailToTeacherModalRef !== undefined) {
      return;
    }

    this.showSendEmailToTeacherModalRef = this.dialogService
      .open(SendEmailToAddressModalComponent, {
        panelClass: 'resizable-mat-dialog-panel',
        data: {
          title: 'teacher.teacher_detail_list.send_email',
          toAddress: this.selectedTeacher.email
        }
      });

    this.showSendEmailToTeacherModalRef.afterClosed()
      .pipe(take(1))
      .subscribe((r) => {
        if (r && r === true) {
          this.toastService.showToast(TOAST_STATE.success, "Sikeres e-mail küldés!");
        } else if (r !== undefined && r !== false) {
          this.toastService.showToast(TOAST_STATE.warning, "Hiba az e-mail küldés során!");
        }
        this.showSendEmailToTeacherModalRef = undefined;
      });
  }

  sendReviewClick() {
    if (this.checkUserData() === true) {
      this.openShowSendTeacherAssessmentModal();
    }

  }

  openShowSendTeacherAssessmentModal() {
    if (this.showSendTeacherAssessmentModalRef !== undefined) {
      return;
    }

    this.showSendTeacherAssessmentModalRef = this.dialogService
      .open(SendTeacherAssessmentModalComponent, {
        panelClass: 'resizable-mat-dialog-panel',
        data: {
          title: 'teacher.modifyDocuments.showDocumentModal.title'
        }
      });

    this.showSendTeacherAssessmentModalRef.afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.showSendTeacherAssessmentModalRef = undefined;
        this.store.dispatch(TeacherActions.loadTeacherAssessments({ id: this.teacherId }));
        this.store.dispatch(TeacherActions.loadGetAllTeacherNumberAssessments({ teacherId: this.teacherId }));
      });
  }

  checkUserData(): boolean {
    let isStudent = false;
    if (this.userData) {
      if (this.userData.roleId === 2) {
        isStudent = true;
      } else {
        this.toastService.showToast(TOAST_STATE.warning, "Kérjük jelentkezz be a funkció használatához!");
        isStudent = false;
      }
    } else {
      this.toastService.showToast(TOAST_STATE.warning, "Kérjük jelentkezz be a funkció használatához!");
      isStudent = false;
    }

    return isStudent;
  }
}
