import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as TokenActions from '../authentication-store/authentication.action';
import { catchError, concatMap, map, of } from "rxjs";
import { AuthenticationService } from "../authentication.service";


@Injectable()
export class AuthEffects {

    loadTokens$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadToken),
            concatMap((action) => this.authenticationService.SignIn(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadTokenSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    loadGoogleSignIn$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadGoogleSignIn),
            concatMap((action) => this.authenticationService.GoogleSignIn(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadGoogleSignInSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    loadFacebookSignIn$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadFacebookSignIn),
            concatMap((action) => this.authenticationService.FacebookSignIn(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadFacebookSignInSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    loadSignUp$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadSignUp),
            concatMap((action) => this.authenticationService.SignUp(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadSignUpSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    loadGoogleSignUp$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadGoogleSignUp),
            concatMap((action) => this.authenticationService.GoogleSignUp(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadGoogleSignUpSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    loadFacebookSignUp$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadFacebookSignUp),
            concatMap((action) => this.authenticationService.FacebookSignUp(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadFacebookSignUpSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    loadCheckUserInDatabase$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.LoadCheckUserInDatabase),
            concatMap((action) => this.authenticationService.CheckUserInDatabase(action.data).pipe(
                map((data) => {
                    return TokenActions.LoadCheckUserInDatabaseSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    ForgotPasswordEmailSend$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.ForgotPasswordEmailSend),
            concatMap((action) => this.authenticationService.ForgotPasswordEmailSend(action.data).pipe(
                map((data) => {
                    return TokenActions.ForgotPasswordEmailSendSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    ForgotPasswordValidate$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.ForgotPasswordValidate),
            concatMap((action) => this.authenticationService.ForgotPasswordValidate(action.data).pipe(
                map((data) => {
                    return TokenActions.ForgotPasswordValidateSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    ConfirmEmail$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.ConfirmEmail),
            concatMap((action) => this.authenticationService.ConfirmEmail(action.data).pipe(
                map((data) => {
                    return TokenActions.ConfirmEmailSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    SetUserPassword$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TokenActions.SetUserPassword),
            concatMap((action) => this.authenticationService.SetUserPassword(action.data).pipe(
                map((data) => {
                    return TokenActions.SetUserPasswordSuccess({ data });
                }),
                catchError(() => of(TokenActions.failure({ error: "Load tokens failure" })))
            )
            )
        );
    });

    constructor(
        private action$: Actions,
        private authenticationService: AuthenticationService
    ) { }
}