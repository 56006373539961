import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'form-title',
    template: `
        <h2 class="text-lg font-semibold leading-7 text-dark-green">{{title}}</h2>
        @if (subTitle) {
            <p class="mt-1 text-sm leading-6 text-gray-600">{{subTitle}}</p>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTitleComponent {
    @Input() title!: string;
    @Input() subTitle?: string = undefined;
}
