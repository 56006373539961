import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { EmailDTO, SetPasswordDTO, User } from "../user/user.model";
import { SignInModel, SignUpModel, TokenR } from "../../models/sign-up/sign-up.model";
import { ConfirmEmailDTO, ForgotPasswordDTO, ForgotPasswordValidateDTO } from "../../models/auth/forgot-password.model";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpClient) {

    }

    SignUp(signUpModel: SignUpModel) {
        const body = signUpModel;

        return this.http.post<EmailDTO>(Config.baseUrl + 'authentication/signup', body);
    }

    GoogleSignUp(signUpModel: SignUpModel) {
        return this.http.post<EmailDTO>(Config.baseUrl + 'authentication/googlesignup', signUpModel);
    }

    FacebookSignUp(signUpModel: SignUpModel) {
        return this.http.post<EmailDTO>(Config.baseUrl + 'authentication/facebooksignup', signUpModel);
    }

    SignIn(signInModel: SignInModel) {
        const body = signInModel;

        return this.http.post<TokenR>(Config.baseUrl + 'authentication/login', body);
    }

    GoogleSignIn(signInModel: SignInModel) {
        const body = signInModel;

        return this.http.post<TokenR>(Config.baseUrl + 'authentication/googlelogin', body);
    }

    FacebookSignIn(signInModel: SignInModel) {
        const body = signInModel;

        return this.http.post<TokenR>(Config.baseUrl + 'authentication/facebooklogin', body);
    }

    CheckUserInDatabase(email: string) {
        return this.http.post<boolean>(Config.baseUrl + 'authentication/checkuserbyemaillocal', email);
    }

    ForgotPasswordEmailSend(dto: ForgotPasswordDTO) {
        return this.http.post<boolean>(Config.baseUrl + 'authentication/forgotpassword', dto);
    }

    ForgotPasswordValidate(dto: ForgotPasswordValidateDTO) {
        return this.http.post<boolean>(Config.baseUrl + 'authentication/forgotpasswordvalidate', dto);
    }

    ConfirmEmail(dto: ConfirmEmailDTO) {
        const link = '?token=' + dto.token + '&email=' + dto.email;
        return this.http.get<boolean>(Config.baseUrl + 'authentication/confirm-email' + link);
    }

    SetUserPassword(dto: SetPasswordDTO) {
        return this.http.post<number>(Config.baseUrl + 'authentication/setpassword', dto);
    }
}