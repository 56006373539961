import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'pagination',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaginationComponent implements OnChanges {
  @Input() totalCount = 0;
  @Input() firstElementNumberOnPage = 0;
  @Input() lastElementNumberOnPage = 0;
  @Input() totalPageNumbers = 1;
  @Input() selectedPageNumber = 1;
  @Input() maxPageSize = 3;
  @Output() clickedPageNumber = new EventEmitter<number>();
  totalPageNumbersArray: (number | string)[] = [];

  ngOnChanges(): void {
    this.totalPageNumbersArray = this.generatePageNumbers(this.selectedPageNumber, this.totalPageNumbers);
  }

  pageClick(pageNumber: number | string) {
    if (typeof pageNumber === 'number') {
      this.selectedPageNumber = pageNumber;
      this.clickedPageNumber.emit(pageNumber);
    }
  }

  previousClick() {
    if(this.selectedPageNumber - 1 >= 1){
      this.selectedPageNumber = this.selectedPageNumber - 1;
      this.clickedPageNumber.emit(this.selectedPageNumber);
    }
  }

  nextClick() {
    if( this.selectedPageNumber + 1 <=this.totalPageNumbers){
      this.selectedPageNumber = this.selectedPageNumber + 1;
      this.clickedPageNumber.emit(this.selectedPageNumber);
    }
  }

  firstPageClick() {
    this.selectedPageNumber = 1;
    this.clickedPageNumber.emit(this.selectedPageNumber);
  }

  lastPageClick() {
    this.selectedPageNumber = this.totalPageNumbers;
    this.clickedPageNumber.emit(this.totalPageNumbers);
  }

  generatePageNumbers(currentPage: number, totalPages: number): (number | string)[] {
    let pages: (number | string)[] = [];

    if (totalPages <= this.maxPageSize) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      let startPage = Math.max(currentPage - 1, 2);
      let endPage = Math.min(currentPage + 1, totalPages - 1);

      if (currentPage === 1) {
        endPage = 3;
      } else if (currentPage === totalPages) {
        startPage = totalPages - 2;
      }

      if (startPage > 2) {
        pages.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push('...');
      }

      pages.push(totalPages);
    }

    return pages;
  }

}
