<button type="button" [routerLink]="['/','teacher-details']" [queryParams]="queryParams">
    <li class="border border-l-indigo-100 p-4 rounded-md hover:bg-light-green bg-opacity-30 h-36 overflow-auto">
        <div class="flex items-center gap-x-6">
            @if(seededTeacher.profilePicture !==''){
            <img class="h-16 w-16 rounded-full" [src]="seededTeacher.picture" alt="pic" loading="lazy">
            }@else{
            <img class="h-16 w-16 rounded-full" src="./assets/images/profile_anonym.svg" alt="pic" loading="lazy">
            }
            <div>
                <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-300">
                    {{seededTeacher.lastName}} {{seededTeacher.firstName}}</h3>
                <p class="text-sm font-semibold leading-6 text-white">{{seededTeacher.email}}</p>
            </div>
        </div>
        @if(this.seededTeacher.lessonNames && this.seededTeacher.lessonNames.length>0){
        <div class="flex items-center gap-x-6 flex-wrap">
            @for(lesson of teacherLessonNames; track $index){
            <p class="text-sm font-semibold leading-6 text-white">#{{lesson}}</p>
            }
        </div>
        }
    </li>
</button>