import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'main-filter-card',
  templateUrl: './main-filter-card.component.html',
  styleUrls: ['./main-filter-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainFilterCardComponent {
  @Input()
  title!: string;

  @Input()
  id!: string;

  constructor(private router: Router) { }

  filterCardClick(): void {
    this.router.navigate(['./teacher-filter'], { queryParams: { lessonTypeIds: this.id } })
  }
}
