<div class="bg-white px-8 items-center">
    <img src="../../../../../assets/images/subscribe.png" loading="lazy" alt="subscribe">

    <div class="text-dark-green text-3xl md:text-5xl font-extrabold">
        {{'common.newsletter_title' | transloco}}
    </div>
    <form [formGroup]="form">
        <input type="email" formControlName="email" placeholder="add meg az email címed..."
            class="w-full border-none focus:shadow-none focus:ring-0" />
        @if(form.errors?.['invalidEmail']){
        <span class="ml-5 text-red-600 text-xs"> {{'validation.error.email' | transloco}}</span>
        }
        <button type="submit" class="mx-2 w-36 h-8 bg-dark-green border-none focus:shadow-none focus:ring-0" (click)="subscribe()">
            <div class="img-wrapper-1">
                <div class="img-wrapper w-6 h-6">
                    <img src="../../../../../assets/images/newsletter/send.svg" loading="lazy" alt="send">
                </div>
            </div>
            <span class="text-base">{{'common.subscribe' | transloco}}</span>
        </button>
    </form>
</div>