import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../components/base-component/base-component.component';
import { ToastService } from '../../services/toast/toast.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.component.css']
})
export class AcceptCookiesComponent extends BaseComponent implements OnInit {
  
  constructor(
    override store: Store,
    override toastService: ToastService,
    private cookiService: CookieService
  ) {
    super(store, toastService);

  }

  ngOnInit(): void {
    this.checkUserCookiesSetting();
  }

  checkUserCookiesSetting() {
    let cookiesSetting = this.cookiService.get('isCookiesAccepted');
    if (cookiesSetting && cookiesSetting.toString().toLocaleLowerCase() === 'false') {
      this.setDisplayCookieModal('block')
    }
    else if (cookiesSetting && cookiesSetting.toString().toLocaleLowerCase() === 'true') {
      this.setDisplayCookieModal('none');
    }
  }

  setDisplayCookieModal(display: string) {
    let modal = document.getElementById("cookieSeciton");
    if (modal) {
      modal.style.display = display;
    }
  }

  cookiesAccepted() {
    this.cookiService.set('isCookiesAccepted','true');
    this.setDisplayCookieModal('none');
  }

  cookiesDenied() {
    this.setDisplayCookieModal('none');
  }

}
