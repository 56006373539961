import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as TeacherDocumentActions from './teacher-document.action';
import { catchError, concatMap, map, of } from "rxjs";
import { TeacherDocumentService } from "../teacher-document.service";


@Injectable()
export class TeacherDocumentEffects {

    getTeacherDocuments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherDocumentActions.getTeacherDocuments),
            concatMap(() => this.teacherDocumentService.getTeacherDocuments().pipe(
                map((data) => {
                    return TeacherDocumentActions.getTeacherDocumentsSuccess({ data });
                }),
                catchError(() => of(TeacherDocumentActions.failure({ error: "Load Teacher Document" })))
            )
            )
        );
    });

    updateTeacherDocument$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherDocumentActions.updateTeacherDocument),
            concatMap((action) => this.teacherDocumentService.updateTeacherDocument(action.document).pipe(
                map((data) => {
                    return TeacherDocumentActions.updateTeacherDocumentSuccess({ data });
                }),
                catchError(() => of(TeacherDocumentActions.failure({ error: "Load Teacher Document failure" })))
            )
            )
        );
    });

    deleteTeacherDocument$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TeacherDocumentActions.deleteTeacherDocument),
            concatMap((action) => this.teacherDocumentService.deleteTeacherDocument(action.id).pipe(
                map((data) => {
                    return TeacherDocumentActions.deleteTeacherDocumentSuccess({ data });
                }),
                catchError(() => of(TeacherDocumentActions.failure({ error: "Load Teacher Document failure" })))
            )
            )
        );
    });


    constructor(
        private action$: Actions,
        private teacherDocumentService: TeacherDocumentService
    ) { }
}