import { Component } from '@angular/core';
import { Config } from '../../../config/appconfig';

@Component({
  selector: 'landing-social',
  templateUrl: './landing-social.component.html',
  styleUrl: './landing-social.component.css'
})
export class LandingSocialComponent {
  facebookLink = Config.facebookLink;
  instagramLink = Config.instagramLink;
  youtubeLink = Config.youtubeLink;
  tiktokLink = Config.tiktokLink;
}
