<div class="grid lg:grid-rows-3 lg:grid-cols-4 md:grid-rows-5 md:grid-cols-2 grid-rows-8 grid-cols-1 gap-2 mt-10">
    <label class="lg:col-span-4 md:col-span-2 col-span-1">{{'student.times.days' | transloco}} </label>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': mondaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="mondayClicked(1)">
            <span class="text-dark-green break-words">{{'student.times.monday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': tuesdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="tuesdayClicked(2)">
            <span class="text-dark-green break-words">{{'student.times.tuesday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': wednesdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="wednesdayClicked(3)">
            <span class="text-dark-green break-words">{{'student.times.wednesday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': thursdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="thursdayClicked(4)">
            <span class="text-dark-green break-words">{{'student.times.thursday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': fridaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="fridayClicked(5)">
            <span class="text-dark-green break-words">{{'student.times.friday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': saturdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="saturdayClicked(6)">
            <span class="text-dark-green break-words">{{'student.times.saturday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-none w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-none"
            [ngStyle]="{'background-color': sundaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="sundayClicked(7)">
            <span class="text-dark-green break-words">{{'student.times.sunday' | transloco}}</span>
        </button>
    </div>
</div>