import { createAction, props } from "@ngrx/store";
import { UserDocumentR, UserDocumentUpdateQ } from "../../../../shared/services/user/user.model";

export const init = createAction(
    '[Teacher Document/API] Load Teacher Document',
    props<{ id: number }>()
);

export const getTeacherDocuments = createAction(
    '[Teacher Document/API] Load Get Teacher Documents'
);

export const getTeacherDocumentsSuccess = createAction(
    '[Teacher Document/API] Load Get Teacher Documents Success',
    props<{ data: UserDocumentR[] }>()
);

export const updateTeacherDocument = createAction(
    '[Teacher Document/API] Load Update Teacher Document',
    props<{ document: UserDocumentUpdateQ }>()
);

export const updateTeacherDocumentSuccess = createAction(
    '[Teacher Document/API] Load Update Teacher Document Success',
    props<{ data: number }>()
);

export const deleteTeacherDocument = createAction(
    '[Teacher Document/API] Load Delete Teacher Document',
    props<{ id: number }>()
);

export const deleteTeacherDocumentSuccess = createAction(
    '[Teacher Document/API] Load Delete Teacher Document Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Teacher Document/API] Load Teacher Document Failure',
    props<{ error: any }>()
);