import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function eventDatesValidator(control1: AbstractControl, control2: AbstractControl): ValidatorFn {
  return (): ValidationErrors | null => {
    let startDate = control1.value;
    let endDate = control2.value;
    let startDateHigher = false;

    if (startDate && endDate) {
        let startDateHourMinute = startDate.split(':');
        let endDateDateHourMinute = endDate.split(':');
        if (Number(startDateHourMinute[0]) != Number(endDateDateHourMinute[0])) {
            if (Number(startDateHourMinute[0]) > Number(endDateDateHourMinute[0])) {
                startDateHigher = false;
            }
            else {
                startDateHigher = true;
            }
        }
    }

    return startDateHigher
        ? null
        : { StartDateHigher: true };
  };
}