import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./teacher.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as TeacherActions from './teacher.action';
import { EventR } from '../../../../shared/models/event/event.model';

export const TeacherAdapter: EntityAdapter<Date[] | EventR[]> =
    createEntityAdapter<Date[] | EventR[]>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const TeacherReducer = createReducer(
    initialState,
    on(TeacherActions.init, () =>
        ({ ...initialState })
    ),
    on(
        TeacherActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    ),
    on(
        TeacherActions.loadTeacherAssessmentsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data,
            teacherAssessments:data
        })
    ),
    on(
        TeacherActions.loadGetAllTeachersCountSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data,
            registeredTeachersCount:data,
            teachersSuccessfulLessonsNumber:data
        })
    ),
    on(
        TeacherActions.loadGetTeachersSuccessfulLessonsNumberSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data,
            registeredTeachersCount:data,
            teachersSuccessfulLessonsNumber:data
        })
    ),
    on(
        TeacherActions.loadGetTeacherByIdSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            teacherById: data
        })
    ),
    on(
        TeacherActions.loadGetTeacherByFiltersSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.loadGetAllTeacherNumberAssessmentsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.insertTeacherAssessmentSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.getTeacherTotalRatingsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.insertTeacherCallBackSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.getTeacherCallBackCountSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.sendEmailByIdSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.getTeacherSocialsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.insertTeacherSocialsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.insertTeacherLessonsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.insertTeacherDocumentsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.updateTeacherTotalRatingsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.sendEmailToAddressesSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.insertTeacherLessonRequestsSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.getSeededTeachersSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    ),
    on(
        TeacherActions.createCalendarEventAsyncSuccess, (state, { data }) => ({
            ...state,
            loaded: true,
            data: data
        })
    )
)

export function reducer(state: State | undefined, aciton: Action) {
    return TeacherReducer(state, aciton);
}