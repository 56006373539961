import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TEACHER_FEATURE_KEY, State } from "./teacher.state";

export const getTeacherSate = createFeatureSelector<State>(TEACHER_FEATURE_KEY);

export const getTeacherLoaded = createSelector(
    getTeacherSate,
    (state:State) => state.loaded
)

export const getTeacher = createSelector(
    getTeacherSate,
    (state:State) => state.data
)

export const getTeachersSuccessfulLessonsNumber = createSelector(
    getTeacherSate,
    (state:State) => state.teachersSuccessfulLessonsNumber
)

export const getRegisteredTeachersCount = createSelector(
    getTeacherSate,
    (state:State) => state.registeredTeachersCount
)

export const getAllCategoriesCount = createSelector(
    getTeacherSate,
    (state:State) => state.allCategoriesCount
)

export const getTeacherById = createSelector(
    getTeacherSate,
    (state:State) => state.teacherById
)

export const getTeacherAssessments = createSelector(
    getTeacherSate,
    (state:State) => state.teacherAssessments
)