import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-summary-rating',
  templateUrl: './star-summary-rating.component.html',
  styleUrls: ['./star-summary-rating.component.css'],
})
export class StarSummaryRatingComponent {
  @Input() rating: number = 0;

  get fullStarsArray(): any[] {
    const count = Math.floor(this.rating);
    if(count >0){
      return new Array(count);
    }
    return [];
  }

  get hasHalfStar(): boolean {
    const decimalPart = this.rating % 1;
    return decimalPart > 0 && decimalPart <= 0.5;
  }

  get emptyStarsArray(): any[] {
    const count = Math.floor(5 - this.rating);
    if(count >0){
      return new Array(count);
    }
    return [];
  }
}
