import { createAction, props } from "@ngrx/store";
import { EmailDTO, SetPasswordDTO, User } from "../../user/user.model";
import { SignInModel, SignUpModel, TokenR } from "../../../../shared/models/sign-up/sign-up.model";
import { ConfirmEmailDTO, ForgotPasswordDTO, ForgotPasswordValidateDTO } from "../../../../shared/models/auth/forgot-password.model";

export const init = createAction(
    '[Token/API] Init Token'
);

export const LoadToken = createAction(
    '[Token/API] Load Token',
    props<{data: SignInModel}>()
);

export const LoadTokenSuccess = createAction(
    '[Token/API] Load Token Success',
    props<{ data: TokenR }>()
);

export const LoadGoogleSignIn = createAction(
    '[Token/API] Load Google Sign In',
    props<{data: SignInModel}>()
);

export const LoadGoogleSignInSuccess = createAction(
    '[Token/API] Load Google Sign In Success',
    props<{ data: TokenR }>()
);

export const LoadFacebookSignIn = createAction(
    '[Token/API] Load Facebook Sign In',
    props<{data: SignInModel}>()
);

export const LoadFacebookSignInSuccess = createAction(
    '[Token/API] Load Facebook Sign In Success',
    props<{ data: TokenR }>()
);

export const LoadSignUp = createAction(
    '[Token/API] Load SignUp',
    props<{data: SignUpModel}>()
);

export const LoadSignUpSuccess = createAction(
    '[Token/API] Load SignUp Success',
    props<{ data: EmailDTO }>()
);

export const LoadGoogleSignUp = createAction(
    '[Token/API] Load Google SignUp',
    props<{data: SignUpModel}>()
);

export const LoadGoogleSignUpSuccess = createAction(
    '[Token/API] Load Google SignUp Success',
    props<{ data: EmailDTO }>()
);

export const LoadFacebookSignUp = createAction(
    '[Token/API] Load Facebook SignUp',
    props<{data: SignUpModel}>()
);

export const LoadFacebookSignUpSuccess = createAction(
    '[Token/API] Load Facebook SignUp Success',
    props<{ data: EmailDTO }>()
);

export const LoadCheckUserInDatabase = createAction(
    '[Token/API] Load Check User In Database',
    props<{data: string}>()
);

export const LoadCheckUserInDatabaseSuccess = createAction(
    '[Token/API] Load Check User In Database Success',
    props<{ data: boolean }>()
);

export const ForgotPasswordEmailSend = createAction(
    '[Token/API] Load Forgot Password Email Send',
    props<{data: ForgotPasswordDTO}>()
);

export const ForgotPasswordEmailSendSuccess = createAction(
    '[Token/API] Load Forgot Password Email Send Success',
    props<{ data: boolean }>()
);


export const ForgotPasswordValidate = createAction(
    '[Token/API] Load Forgot Password Validate',
    props<{data: ForgotPasswordValidateDTO}>()
);

export const ForgotPasswordValidateSuccess = createAction(
    '[Token/API] Load Forgot Password Validate Success',
    props<{ data: boolean }>()
);

export const ConfirmEmail = createAction(
    '[Token/API] Load Confirm Email',
    props<{data: ConfirmEmailDTO}>()
);

export const ConfirmEmailSuccess = createAction(
    '[Token/API] Load Confirm Email Success',
    props<{ data: boolean }>()
);

export const SetUserPassword = createAction(
    '[Token/API] Load Set Password',
    props<{data: SetPasswordDTO}>()
);

export const SetUserPasswordSuccess = createAction(
    '[Token/API] Load Set Password Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Token/API] Load Token Failure',
    props<{ error: any }>()
);