<div class="grid grid-flow-row grid-flow-row grid-cols-1 gap-y-40 shadow-md">
  <div class="row-start-1 bg-white rounded-lg m-2">
    <teacher-detail-list-component></teacher-detail-list-component>
  </div>
  <div class="row-start-2 lg:w-2/3 md:w-2/3 w-full justify-self-center rounded-lg">
    <section id="rólam" class="bg-white rounded overflow-hidden shadow-lg">
        <div class="px-6">
          <div class="font-bold lg:text-xl mg:text-xl m-2">{{'teacher.teacher_details.introduction' | transloco}}</div>
          @if(selectedTeacher.about) {
          <p class="text-gray-700 text-base text-dark-green break-words text-justify">
            @if(!isCollapsed){
            @if(selectedTeacher.about!.length > 250){
            {{selectedTeacher.about | slice:0:250}}...
            }@else{
            {{selectedTeacher.about}}
            }
            }@else{
            {{selectedTeacher.about}}
            }
          </p>
          @if(selectedTeacher.about!.length > 250){
          <div class="px-6 pt-4 pb-2">
            <button type="button" (click)="toggleCollapse()">
              @if(!isCollapsed && selectedTeacher.about && selectedTeacher.about!.length > 45){
              <p class="font-bold text-light-green">{{'common.show_more' | transloco}}</p>
              }@else{
              <p class="font-bold text-light-green">{{'common.show_less' | transloco}}</p>
              }
            </button>
          </div>
          }
          }
        </div>
    </section>
  </div>
  <div class="row-start-3 lg:w-2/3 md:w-2/3 w-full justify-self-center rounded-lg">
    <section id="szakmai-tapasztalat" class="bg-white rounded overflow-hidden shadow-lg">
        <div class="px-6">
          <div class="font-bold lg:text-xl mg:text-xl m-2 ">{{'teacher.teacher_details.professional_experience' | transloco}}
          </div>
          @if(selectedTeacher.professionalExperience) {
          <p class="text-gray-700 text-base text-dark-green break-words text-justify">
            @if(!isProfExpCollapsed){
            @if(selectedTeacher.professionalExperience!.length > 250){
            {{selectedTeacher.professionalExperience | slice:0:250}}...
            }@else{
            {{selectedTeacher.professionalExperience}}
            }
            }@else{
            {{selectedTeacher.professionalExperience}}
            }
          </p>
          @if(selectedTeacher.professionalExperience!.length > 250){
          <div class="px-6 pt-4 pb-2">
            <button type="button" (click)="toggleProfExpCollapse()">
              @if(!isProfExpCollapsed && selectedTeacher.professionalExperience && selectedTeacher.professionalExperience!.length > 45){
              <p class="font-bold text-light-green">{{'common.show_more' | transloco}}</p>
              }@else{
              <p class="font-bold text-light-green">{{'common.show_less' | transloco}}</p>
              }
            </button>
          </div>
          }
          }
        </div>
    </section>
  </div>
  <div class="row-start-4 w-full justify-self-center rounded-lg">
    <section id="órarend" class="bg-white rounded overflow-auto shadow-lg">
        <timetable></timetable>
    </section>
  </div>
  <div class="row-start-5 lg:w-2/3 md:w-2/3 w-full justify-self-center rounded-lg">
    <section id="értékelések" class="bg-white rounded overflow-auto shadow-lg">
        <app-reviews-summary></app-reviews-summary>
    </section>
  </div>
</div>