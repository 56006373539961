<div class="bg-white">
  <div>
    <!--
      Mobile filter dialog

      Off-canvas filters for mobile, show/hide based on off-canvas filters state.
    -->
    @if(isMobileFiltersOpen){
    <div class="relative z-40 lg:hidden" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-black bg-opacity-25"></div>
      <div class="fixed inset-0 z-40 flex">
        <div
          class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
          <div class="flex items-center justify-between px-4">
            <h2 class="text-lg font-medium text-gray-900">{{'student.filter.filters' | transloco}}</h2>
            <button type="button"
              class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400">
              <span class="sr-only">Close menu</span>
              <img (click)="mobileFiltersClose()" src="../../../../assets/images/x.svg" class="h-6 w-6" loading="lazy"
                alt="xButton">
            </button>
          </div>
          <!-- Filters -->
          <form [formGroup]="form" class="mt-2 border-t border-gray-200">
            <div class="border-t border-gray-200 px-4 py-6 overflow-y-scroll max-h-64">
              <h3 class="-mx-2 -my-3 flow-root" (click)="isLessonTypeDropdownOpen = !isLessonTypeDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.lessonType' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLessonTypeDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        alt="open" loading="lazy">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isLessonTypeDropdownOpen" id="locationTypes">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    @if(allLessonTypes && allLessonTypes.length > 0){
                    @for(lessonType of allLessonTypes; track $index){
                    <li class="flex items-center mt-3">
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input id="lessonType" type="checkbox" value="{{lessonType.lessonTypeId}}"
                          formControlName="lessonType{{lessonType.lessonTypeId}}"
                          id="lessonType{{lessonType.lessonTypeId}}"
                          class="w-6 h-6 me-2 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                        {{lessonType.lessonTypeName}}
                      </label>
                    </li>
                    }
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 overflow-y-scroll max-h-64">
              <h3 class="-mx-2 -my-3 flow-root" (click)="isSortDropdownOpen = !isSortDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.sort' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isSortDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        loading="lazy" alt="open">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isSortDropdownOpen" id="teacherSorting">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    @if(teacherSorting.length > 0) {
                    @for(sort of teacherSorting; track $index){
                    <li class="flex items-center mt-3" (click)="selectSort(sort)">
                      <input id="default-radio-1" type="radio" value="{{sort}}" formControlName="sort"
                        class="w-4 h-4 text-dark-green bg-gray-100 border-gray-300 focus:ring-dark-green dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="default-radio-1"
                        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{sort}}</label>
                    </li>
                    }
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div class="border-t border-gray-200 px-4 py-6 overflow-y-scroll max-h-64">
              <h3 class="-mx-2 -my-3 flow-root" (click)="isLessonDropdownOpen = !isLessonDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{'student.filter.lesson_type' | transloco}}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLessonDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        alt="open" loading="lazy">
                    </button>
                  </span>
                </button>
              </h3>
              @if(isLessonDropdownOpen){
              <div class="pt-6" id="mobile-all-lessons">
                <div class="space-y-6">
                  <ul class="mt-3 rounded">
                    <input type="text" id="lesson_filter" formControlName="lesson_filter"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dark-green focus:border-dark-green w-full"
                      placeholder="Keress órát...">
                    @if(filteredOptions) {
                    @for(lesson of filteredOptions | async; track $index){
                    <li class="flex items-center mt-3">
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input type="checkbox" formControlName="lesson{{lesson.lessonId}}"
                          id="lesson{{lesson.lessonId}}"
                          class="w-6 h-6 me-2 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green" />
                        {{lesson.lessonName}}
                      </label>
                    </li>
                    }@empty{
                    <div class="space-y-6 mt-2">
                      {{ 'filter.empty' | transloco }}
                    </div>
                    }
                    }
                  </ul>
                </div>
              </div>
              }
            </div>

            <div class="border-t border-gray-200 px-4 py-6">
              <h3 class="-mx-2 -my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <div class="flex items-center">
                    <div class="relative p-1 w-5/12">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <img src="../../../../assets/images/teacher-card/money.svg" class="w-6 h-6" loading="lazy"
                          alt="money">
                      </div>
                      <input type="text" id="min_price" formControlName="min_price"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dark-green focus:border-dark-green block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-dark-green dark:focus:border-dark-green"
                        placeholder="Min">
                    </div>
                    <p class="w-1/12 text-center">-</p>
                    <div class="relative p-1  w-5/12">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <img src="../../../../assets/images/teacher-card/money.svg" class="w-6 h-6" loading="lazy"
                          alt="money">
                      </div>
                      <input type="text" id="max_price" formControlName="max_price"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dark-green focus:border-dark-green block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-dark-green dark:focus:border-dark-green"
                        placeholder="Max">
                    </div>
                    <button type="button" class="x w-1/12 flex" (click)="removePrices()">
                      <img src="../../../../assets/images/common/circeled_x.svg" class="w-6 h-6" loading="lazy"
                        alt="xButton">
                    </button>
                  </div>
                </button>
              </h3>
            </div>
            <div class="border-t border-gray-200 px-4 py-6">
              <h3 class="-mx-2 -my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" (click)="isLocationTypeDropdownOpen = !isLocationTypeDropdownOpen"
                  class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{'student.filter.location_type' | transloco}}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLocationTypeDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        loading="lazy" alt="open">
                    </button>
                  </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              @if(isLocationTypeDropdownOpen){
              <div class="pt-6" id="mobile-location-types">
                <div class="space-y-6">
                  <ul class="mt-3 rounded">
                    @if(locationTypes && locationTypes.length > 0){
                    @for(locationType of locationTypes; track $index){
                    <li class="flex items-center mt-3">
                      <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input type="checkbox" value="{{locationType.locationTypeId}}"
                          formControlName="locationType{{locationType.locationTypeId}}"
                          id="mlocationType{{locationType.locationTypeId}}"
                          class="w-6 h-6 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green focus:ring-2" />
                        {{locationType.locationTypeName}}
                      </label>
                    </li>
                    }
                    }

                  </ul>
                </div>
              </div>
              }
            </div>

            <div class="border-t border-gray-200 px-4 py-6">
              <h3 class="-mx-2 -my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" (click)="isLocationDropdownOpen = !isLocationDropdownOpen"
                  class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{'student.filter.city' | transloco}}</span>
                  <span class="ml-6 flex items-center">
                    <span class="ml-6 flex items-center">
                      <button type="button">
                        <img src="./assets/images/arrow-{{isLocationDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                          loading="lazy" alt="open">
                      </button>
                    </span>
                  </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              @if(isLocationDropdownOpen){
              <div class="pt-6" id="mobile-locations">
                <div class="space-y-6">
                  <ul class="mt-3 rounded">
                    @if(offlineLocationCityNames && offlineLocationCityNames.length > 0){
                    @for(offlineLocation of offlineLocationCityNames; track $index){
                    <li class="flex items-center mt-3">
                      <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input type="checkbox" value="offlineLocation.name" id="mlocation{{offlineLocation.locationId}}"
                          formControlName="location{{offlineLocation.locationId}}"
                          class="w-6 h-6 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green" />
                        {{offlineLocation.name}}
                      </label>
                    </li>
                    }
                    }
                  </ul>
                </div>
              </div>
              }
            </div>

            <div class="border-t border-gray-200 px-4 py-6">
              <h3 class="-mx-2 -my-3 flow-root">
                <button type="button" (click)="isTimesDropdownOpen = !isTimesDropdownOpen"
                  class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{'student.filter.times' | transloco}}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isTimesDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        loading="lazy" alt="open">
                    </button>
                  </span>
                </button>
              </h3>
              @if(isTimesDropdownOpen){
              <div class="pt-6" id="mobile-times">
                <div class="space-y-6">
                  <app-times (newItemClicked)="getTimesData($event)"></app-times>
                </div>
              </div>
              }
            </div>
            <div class="p-6" id="mobile-search">
              <button type="button" (click)="filterSearchClick()" class="flex justify-center w-full btn-primary"
                aria-controls="filter-section-0" aria-expanded="false">
                <span class="font-medium text-white">{{'common.search' | transloco}}</span>
                <span class="ml-6 flex items-center">
                  <button type="button">
                    <img src="../../../../assets/images/search.svg" class="w-6 h-6" loading="lazy" alt="search">
                  </button>
                </span>
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
    }

    <main class="px-4 sm:px-6 lg:px-8">
      <div class="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
        <h1 class="text-4xl font-bold tracking-tight text-gray-900">{{ 'filter.title' | transloco }}</h1>
        <button type="button" (click)="mobileFiltersOpen()"
          class="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden">
          <span class="sr-only">Filters</span>
          <img src="./assets/images/filter.svg" class="h-5 w-5" alt="filter" loading="lazy">
        </button>
      </div>

      <section aria-labelledby="teacher-heading" class="pb-24 pt-6">
        <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">

          <!-- Filters -->
          <form [formGroup]="form" class="hidden lg:block" (window:resize)="onResize($event)">
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root" (click)="isLessonTypeDropdownOpen = !isLessonTypeDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.lessonType' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLessonTypeDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        alt="open" loading="lazy">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isLessonTypeDropdownOpen" id="locationTypes">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    @if(allLessonTypes && allLessonTypes.length > 0){
                    @for(lessonType of allLessonTypes; track $index){
                    <li class="flex items-center mt-3" (change)="lessonTypeChange()">
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input id="lessonType" type="checkbox" value="{{lessonType.lessonTypeId}}"
                          formControlName="lessonType{{lessonType.lessonTypeId}}"
                          id="lessonType{{lessonType.lessonTypeId}}"
                          class="w-6 h-6 me-2 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                        {{lessonType.lessonTypeName}}
                      </label>
                    </li>
                    }
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root" (click)="isSortDropdownOpen = !isSortDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.sort' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isSortDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        loading="lazy" alt="open">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isSortDropdownOpen" id="teacherSorting">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    @if(teacherSorting.length > 0) {
                    @for(sort of teacherSorting; track $index){
                    <li class="flex items-center mt-3" (click)="selectSort(sort)">
                      <input id="default-radio-1" type="radio" value="{{sort}}" formControlName="sort"
                        class="w-4 h-4 text-dark-green bg-gray-100 border-gray-300 focus:ring-dark-green dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="default-radio-1"
                        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{sort}}</label>
                    </li>
                    }
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root" (click)="isLessonDropdownOpen = !isLessonDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.lesson' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLessonDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        loading="lazy" alt="open">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6 overflow-y-scroll max-h-64" [class.hidden]="!isLessonDropdownOpen" id="allLessons">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    <input type="text" id="lesson_filter" formControlName="lesson_filter"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dark-green focus:border-dark-green w-full"
                      placeholder="Keress órát...">
                    @if(filteredOptions) {
                    @for(lesson of filteredOptions | async; track $index){
                    <li class="flex items-center mt-3">
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input type="checkbox" formControlName="lesson{{lesson.lessonId}}"
                          id="lesson{{lesson.lessonId}}"
                          class="w-6 h-6 me-2 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green" />
                        {{lesson.lessonName}}
                      </label>
                    </li>
                    }@empty{
                    <div class="space-y-6 mt-2">
                      {{ 'filter.empty' | transloco }}
                    </div>
                    }
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <div class="flex items-center">
                    <div class="relative p-1 w-5/12">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <img src="../../../../assets/images/teacher-card/money.svg" class="w-6 h-6" loading="lazy"
                          alt="money">
                      </div>
                      <input type="text" id="min_price" formControlName="min_price"
                        class="bg-gray-50 border border-gray-300 text-dark-green text-sm rounded-lg focus:ring-dark-green focus:border-dark-green block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-dark-green dark:focus:border-dark-green"
                        placeholder="Min">
                    </div>
                    <p class="w-1/12 text-center">-</p>
                    <div class="relative p-1  w-5/12">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <img src="../../../../assets/images/teacher-card/money.svg" class="w-6 h-6" loading="lazy"
                          alt="money">
                      </div>
                      <input type="text" id="max_price" formControlName="max_price"
                        class="bg-gray-50 border border-gray-300 text-dark-green text-sm rounded-lg focus:ring-dark-green focus:border-dark-green block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-dark-green dark:focus:border-dark-green"
                        placeholder="Max">
                    </div>
                    <button type="button" class="x w-1/12 flex" (click)="removePrices()">
                      <img src="../../../../assets/images/common/circeled_x.svg" class="h-6 w-6" loading="lazy"
                        alt="money">
                    </button>
                  </div>
                </button>
              </h3>
            </div>

            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root" (click)="isLocationTypeDropdownOpen = !isLocationTypeDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.locationType' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLocationTypeDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        alt="open" loading="lazy">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isLocationTypeDropdownOpen" id="locationTypes">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    @if(locationTypes && locationTypes.length > 0){
                    @for(locationType of locationTypes; track $index){
                    <li class="flex items-center mt-3">
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input id="locationType" type="checkbox" value="{{locationType.locationTypeId}}"
                          formControlName="locationType{{locationType.locationTypeId}}"
                          id="locationType{{locationType.locationTypeId}}"
                          class="w-6 h-6 me-2 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                        {{locationType.locationTypeName}}
                      </label>
                    </li>
                    }
                    }
                  </ul>
                </div>
              </div>
            </div>

            @if (offlineLocationCityNames.length) {
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root" (click)="isLocationDropdownOpen = !isLocationDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.location' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isLocationDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        alt="open" loading="lazy">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isLocationDropdownOpen" id="locations">
                <div class="space-y-4">
                  <ul class="mt-3 rounded">
                    @for (offlineLocation of offlineLocationCityNames; track $index) {
                    <li class="flex items-center mt-3">
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                        <input type="checkbox" value="offlineLocation.name"
                          formControlName="location{{offlineLocation.locationId}}"
                          id="location{{offlineLocation.locationId}}"
                          class="w-6 h-6 me-2 bg-gray-100 border-gray-300 rounded text-dark-green focus:ring-dark-green dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                        {{offlineLocation.name}}
                      </label>
                    </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
            }

            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root" (click)="isTimesDropdownOpen = !isTimesDropdownOpen">
                <button type="button"
                  class="flex w-full items-center justify-between bg-white py-3 px-1 text-sm text-gray-400 hover:bg-gray-200"
                  aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">{{ 'filter.times' | transloco }}</span>
                  <span class="ml-6 flex items-center">
                    <button type="button">
                      <img src="./assets/images/arrow-{{isTimesDropdownOpen ? 'up': 'down'}}.svg" class="h-5 w-5"
                        alt="open" loading="lazy">
                    </button>
                  </span>
                </button>
              </h3>
              <div class="pt-6" [class.hidden]="!isTimesDropdownOpen" id="times">
                <div class="space-y-4">
                  <app-times (newItemClicked)="getTimesData($event)"></app-times>
                </div>
              </div>
            </div>

            <div class="border-gray-200 py-6 text-center">
              <button type="submit" class="btn-primary" (click)="filterSearchClick()">
                <img src="../../../../assets/images/search.svg" class="w-6 h-6" loading="lazy" alt="search">
                <span>{{ 'filter.search' | transloco }}</span>
              </button>
            </div>
          </form>
          <div class="lg:col-span-3">
            <teacher-cards-by-filter [teachers]="teachers"></teacher-cards-by-filter>
          </div>
        </div>
      </section>
    </main>
  </div>
</div>