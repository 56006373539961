import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base-component/base-component.component';

@Component({
  selector: 'app-base-form-component',
  template: ''
})
export class BaseFormComponent extends BaseComponent implements OnDestroy {

  onCancel(): void {
    window.history.back();
  }
}
