import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as AdminActions from './admin.action';
import { catchError, concatMap, map, of } from "rxjs";
import { AdminService } from "../admin.service";


@Injectable()
export class AdminEffects {

    getAllTeachersDocuments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(AdminActions.getAllTeachersDocuments),
            concatMap(() => this.adminService.getAllTeachersDocuments().pipe(
                map((data) => {
                    return AdminActions.getAllTeachersDocumentsSuccess({ data });
                }),
                catchError(() => of(AdminActions.failure({ error: "Load get All Teachers Documents failure" })))
            )
            )
        );
    });
    
    updateTeacherVerify$ = createEffect(() => {
        return this.action$.pipe(
            ofType(AdminActions.updateTeacherVerify),
            concatMap((action) => this.adminService.updateTeacherVerify(action.data).pipe(
                map((data) => {
                    return AdminActions.updateTeacherVerifySuccess({ data });
                }),
                catchError(() => of(AdminActions.failure({ error: "Update Teacher Verify failure" })))
            )
            )
        );
    });

    getRequestedLessons$ = createEffect(() => {
        return this.action$.pipe(
            ofType(AdminActions.getRequestedLessons),
            concatMap(() => this.adminService.getRequestedLessons().pipe(
                map((data) => {
                    return AdminActions.getRequestedLessonsSuccess({ data });
                }),
                catchError(() => of(AdminActions.failure({ error: "Get Requested Lessons failure" })))
            )
            )
        );
    });

    
    insertLessonFromTemp$ = createEffect(() => {
        return this.action$.pipe(
            ofType(AdminActions.insertLessonFromTemp),
            concatMap((action) => this.adminService.insertLessonFromTemp(action.data).pipe(
                map((data) => {
                    return AdminActions.insertLessonFromTempSuccess({ data });
                }),
                catchError(() => of(AdminActions.failure({ error: "Get Insert Lesson From Temp failure" })))
            )
            )
        );
    });


    constructor(
        private action$: Actions,
        private adminService: AdminService
    ) { }
}