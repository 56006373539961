<div class="grid grid-rows-6 grid-cols-3 gap-2">
    <label class="col-span-3">{{'student.times.during_day' | transloco}}</label>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" type="button" class=" text-center w-full border-solid border-2 rounded border-light-green"
            [ngStyle]="{'background-color': firstSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="FirstClicked('9-12')">
            <div class=" svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">9-12</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': secondSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="SecondClicked('12-15')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">12-15</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': thirdSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="ThirdClicked('15-18')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">15-18</span>
        </button>
    </div>
    <label class="col-span-3">{{'student.times.afternoon' | transloco}} / {{'student.times.evening' | transloco}}
    </label>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': fourthSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="FourthClicked('18-21')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">18-21</span>
        </button>
    </div>

    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': fifthSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="FifthClicked('21-24')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">21-24</span>
        </button>
    </div>

    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': sixthSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="SixthClicked('0-3')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">0-3</span>
        </button>
    </div>
    <label class="col-span-3">{{'student.times.morning' | transloco}} </label>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': seventhSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="SeventhClicked('3-6')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">3-6</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': eighthSelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="EighthClicked('6-9')">
            <div class="w-full svg">
                <img src="../../../../assets/images/times/sun.svg" alt="sun" loading="lazy" class="w-6 h-6">
            </div>
            <span class="text-dark-green">6-9</span>
        </button>
    </div>
</div>

<div class="grid lg:grid-rows-3 lg:grid-cols-4 md:grid-rows-5 md:grid-cols-2 grid-rows-8 grid-cols-1 gap-2 mt-10">
    <label class="lg:col-span-4 md:col-span-2 col-span-1">{{'student.times.days' | transloco}} </label>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': mondaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="mondayClicked(1)">
            <span class="text-dark-green break-words">{{'student.times.monday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': tuesdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="tuesdayClicked(2)">
            <span class="text-dark-green break-words">{{'student.times.tuesday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': wednesdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="wednesdayClicked(3)">
            <span class="text-dark-green break-words">{{'student.times.wednesday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': thursdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="thursdayClicked(4)">
            <span class="text-dark-green break-words">{{'student.times.thursday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': fridaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="fridayClicked(5)">
            <span class="text-dark-green break-words">{{'student.times.friday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': saturdaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="saturdayClicked(6)">
            <span class="text-dark-green break-words">{{'student.times.saturday' | transloco}}</span>
        </button>
    </div>
    <div class="hover:bg-light-green w-full text-center rounded">
        <button type="button" class="w-full text-center border-solid border-2 rounded border-light-green hover:bg-light-green"
            [ngStyle]="{'background-color': sundaySelected? 'rgb(42, 157, 143)' : 'transparent'}"
            (click)="sundayClicked(7)">
            <span class="text-dark-green break-words">{{'student.times.sunday' | transloco}}</span>
        </button>
    </div>
</div>