<section id="cookieSeciton"
    class="fixed z-50 max-w-md p-4 mx-auto bg-white border border-gray-200 dark:bg-gray-800 left-12 bottom-16 dark:border-gray-700 rounded-2xl">
    <h2 class="font-semibold text-dark-green dark:text-white">🍪{{'shared.accept_accokies.title' | transloco}} </h2>
    <p class="mt-4 text-sm text-gray-600 dark:text-gray-300"> {{'shared.accept_accokies.title_alt' | transloco}}
        <a href="#" class="text-light-green hover:underline">{{'shared.accept_accokies.read_privacy' | transloco}}</a>.
    </p>

    <div class="flex items-center justify-between mt-4 gap-x-4 shrink-0">
        <button type="button"
            class="text-xs text-light-green underline transition-colors duration-300 dark:text-white dark:hover:text-gray-400 hover:text-dark-green focus:outline-none">
            {{'shared.accept_accokies.cookie_settings' | transloco}}
        </button>
        <button (click)="cookiesDenied()" type="button"
        class="text-xs bg-dark-green font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
        {{'shared.accept_accokies.reject' | transloco}}
    </button>
        <button (click)="cookiesAccepted()" type="button"
            class="text-xs bg-dark-green font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
            {{'shared.accept_accokies.accept' | transloco}}
        </button>
    </div>
</section>