import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { ToastService } from '../../../shared/services/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { TeacherSocialR } from '../../models/teacher/teacher-social.model';

@Component({
  selector: 'social-media-list-component',
  templateUrl: './social-media-list-component.component.html'
})
export class SocialMediaListComponentComponent extends BaseComponent implements OnInit, OnDestroy {
  teacherSocialR: TeacherSocialR = {};

  constructor(
    private route: ActivatedRoute,
    override store: Store,
    override toastService: ToastService,
    override action$?: Actions
  ) {
    super(store, toastService, action$);
    action$?.pipe(ofType(TeacherActions.getTeacherSocialsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if(response && response.data){
        this.teacherSocialR = response.data;
      }
    })
  }

  ngOnInit(): void {
    let teacherId = 0;
    this.route.queryParams
    .subscribe(params => {
      teacherId = params['teacherId'] as number;
    })
    if(teacherId >0){
      this.store.dispatch(TeacherActions.getTeacherSocials({teacherId:teacherId}));
    }
  }

}
