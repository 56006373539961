import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { CreateEventQ, CreateEventR, DeleteConnectedEventsDTO, DeletedEventDetailsModel, EventDetailsToJoinEventDTO, EventR, EventScheduleR, EventsToCalendarDTO, EventsToTimeTableDTO, GetConnectedEventsQ, GetConnectedEventsToUpdateQ, GetConnectedEventsToUpdateR, JoinEventDTO, StudentEventListDTO, StudentEventListQ, TeacherEventListDTO, TeacherEventListModel, TeacherEventListQ, UpdateEventIsAcceptedQ, UpdateEventIsArchiveQ, UpdateEventIsDoneQ } from "../../models/event/event.model";
import { GetUsersDataByEventIdR } from "../user/user.model";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    http = inject(HttpClient);

    getEventsByTeacherId(dto: TeacherEventListQ) {
        return this.http.post<TeacherEventListDTO>(Config.baseUrl + 'event/getbyteacherid', dto);
    }

    getArchiveEventsByTeacherId(dto: TeacherEventListQ) {
        return this.http.post<TeacherEventListDTO>(Config.baseUrl + 'event/teacher-event-list-archive', dto);
    }

    getUnAcceptedEventsByTeacherId(dto: TeacherEventListQ) {
        return this.http.post<TeacherEventListDTO>(Config.baseUrl + 'event/teacher-event-list-unaccepted', dto);
    }

    getEventsBySchedule(eventScheduleR: EventScheduleR) {
        let body = {
            weekStartDate: eventScheduleR.weekStartDate,
            weekendDate: eventScheduleR.weekendDate,
            lessonId: eventScheduleR.lessonId
        }

        return this.http.post<EventsToTimeTableDTO[]>(Config.baseUrl + 'event/getbyschedule?teacherId=' + eventScheduleR.teacherId, body);
    }

    getEventsToCalendar(eventScheduleR: EventScheduleR) {
        let body = {
            weekStartDate: eventScheduleR.weekStartDate,
            weekendDate: eventScheduleR.weekendDate,
            lessonId: eventScheduleR.lessonId
        }

        return this.http.post<EventsToCalendarDTO[]>(Config.baseUrl + 'event/getbycalendar?teacherId=' + eventScheduleR.teacherId, body);
    }

    createEvent(eventQ: CreateEventQ) {
        return this.http.post<CreateEventR>(Config.baseUrl + 'event/create', eventQ);
    }

    joinEvent(dto: JoinEventDTO) {
        return this.http.post<number>(Config.baseUrl + 'event/joinevent', dto);
    }

    createEvents(eventQ: CreateEventQ[]) {
        return this.http.post<number>(Config.baseUrl + 'event/createmore', eventQ);
    }

    createAvailableEvents(eventQ: CreateEventQ[]) {
        return this.http.post<number>(Config.baseUrl + 'event/create-more-available', eventQ);
    }

    getEvent(eventId: string) {
        return this.http.post<EventR>(Config.baseUrl + 'event/getbyid', eventId);
    }

    getUsersDataByEventId(eventId: number) {
        return this.http.post<GetUsersDataByEventIdR[]>(Config.baseUrl + 'event/getuserdatabyid', eventId);
    }

    deleteEvent(eventId: number) {
        return this.http.post<number>(Config.baseUrl + 'event/delete', eventId);
    }

    deleteEvents(eventIds: string) {
        const body = JSON.stringify(eventIds);
        return this.http.post<number>(Config.baseUrl + 'event/delete-more', body);
    }

    UpdateEventIsAccepted(dto: UpdateEventIsAcceptedQ) {
        return this.http.post<number>(Config.baseUrl + 'event/updateisaccepted', dto);
    }

    UpdateEventIsDone(dto: UpdateEventIsDoneQ) {
        return this.http.post<number>(Config.baseUrl + 'event/updateisdone', dto);
    }

    UpdateEventIsArchive(dto: UpdateEventIsArchiveQ) {
        return this.http.post<number>(Config.baseUrl + 'event/updateisarchive', dto);
    }

    getEventsByStudentId(dto: StudentEventListQ) {
        return this.http.post<StudentEventListDTO>(Config.baseUrl + 'event/student-event-list', dto);
    }

    getArchiveEventsByStudentId(dto: StudentEventListQ) {
        return this.http.post<StudentEventListDTO>(Config.baseUrl + 'event/student-event-list-archive', dto);
    }

    getUnAcceptedEventsByStudentId(dto: StudentEventListQ) {
        return this.http.post<StudentEventListDTO>(Config.baseUrl + 'event/student-event-list-unaccepted', dto);
    }

    DeleteStudentFromEvent(eventId: number) {
        return this.http.post<number>(Config.baseUrl + 'event/deletestudentfromevent', eventId);
    }

    getEventDetailsToJoinEvent(eventId: number) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("eventId", eventId);

        return this.http.get<EventDetailsToJoinEventDTO>(Config.baseUrl + 'event/geteventdetailstojoinevent', { params: queryParams });
    }

    checkUserExistOnEvent(eventId: number) {
        return this.http.post<number>(Config.baseUrl + 'event/checkuseronevent', eventId);
    }

    getConnectedEvents(dto: GetConnectedEventsQ) {
        return this.http.post<DeleteConnectedEventsDTO[]>(Config.baseUrl + 'event/get-connected-events', dto);
    }

    getConnectedEventsToUpdate(dto: GetConnectedEventsToUpdateQ) {
        return this.http.post<GetConnectedEventsToUpdateR>(Config.baseUrl + 'event/get-connected-events-to-update', dto);
    }

    GetStudentsEmailsByEventIds(eventIds: string) {
        const body = JSON.stringify(eventIds);
        return this.http.post<string[]>(Config.baseUrl + 'event/students-emails-by-event-ids', body);
    }

    GetDeletedEventsDetails(eventIds: string) {
        const body = JSON.stringify(eventIds);
        return this.http.post<DeletedEventDetailsModel[]>(Config.baseUrl + 'event/get-deleted-events-details', body);
    }
}