import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scrollspy',
  templateUrl: './scrollspy.component.html',
  styleUrls: ['./scrollspy.component.css']
})
export class ScrollspyComponent implements OnInit {
  @Input()
  menuItems: string[] = [];
  activeMenuItem: string = this.menuItems[0];

  constructor() { }

  ngOnInit(): void {
    this.activeMenuItem = this.menuItems[0];
  }

  scrollTo(section: string): void {
    const sectionElement = document.getElementById(section.toLowerCase().replace(/ /g, '-',));
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      this.activeMenuItem = section;
    }
  }

}
