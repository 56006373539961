import { Injectable } from '@angular/core';
import { MenuItem } from '../../models/menu/menu.model';
import { RoleEnum } from '../../enums/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsService {

  allMenuItems: MenuItem[]=[
    {
      roleId: +RoleEnum.ANONYMUS,
      menuItemTypeId: 1,
      menuItemLink: '/',
      menuItemName: 'home',
      ordinalNumber: 1
    },
    {
      roleId: +RoleEnum.ANONYMUS,
      menuItemTypeId: 1,
      menuItemLink: '/teacher-filter',
      menuItemName: 'teacher_filter',
      ordinalNumber: 1
    },
    // {
    //   roleId: +RoleEnum.ANONYMUS,
    //   menuItemTypeId: 1,
    //   menuItemLink: '',
    //   menuItemName: 'blog',
    //   ordinalNumber: 3
    // },
    // {
    //   roleId: +RoleEnum.ANONYMUS,
    //   menuItemTypeId: 1,
    //   menuItemLink: '/about',
    //   menuItemName: 'aboutus',
    //   ordinalNumber: 4
    // },
    // {
    //   roleId: +RoleEnum.ANONYMUS,
    //   menuItemTypeId: 1,
    //   menuItemLink: '/contacts',
    //   menuItemName: 'contact',
    //   ordinalNumber: 5
    // },
    {
      roleId: +RoleEnum.ANONYMUS,
      menuItemTypeId: 1,
      menuItemLink: '/aszf',
      menuItemName: 'aszf',
      ordinalNumber: 6
    },
    {
      roleId: +RoleEnum.ANONYMUS,
      menuItemTypeId: 2,
      menuItemLink: '/sign-in',
      menuItemName: 'Belépés',
      ordinalNumber: 1
    },
    {
      roleId: +RoleEnum.ANONYMUS,
      menuItemTypeId: 2,
      menuItemLink: '/sign-up',
      menuItemName: 'Regisztráció',
      ordinalNumber: 2
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/sign-out',
      menuItemName: 'Kijelentkezés',
      ordinalNumber: 100
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/social',
      menuItemName: 'Social Media adatok',
      ordinalNumber: 2
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/subjects',
      menuItemName: 'Tantárgy feltöltése',
      ordinalNumber: 3
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/profile',
      menuItemName: 'Profil',
      ordinalNumber: 1
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/locations',
      menuItemName: 'Helyszínek feltöltése',
      ordinalNumber: 4
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/schedule',
      menuItemName: 'Órarend módosítása',
      ordinalNumber: 5
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/teacher-event-list',
      menuItemName: 'Saját óráim',
      ordinalNumber: 6
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/teacher-documents',
      menuItemName: 'Dokumentum feltöltés',
      ordinalNumber: 7
    },
    {
      roleId: +RoleEnum.TEACHER,
      menuItemTypeId: 2,
      menuItemLink: '/teacher/teacher-locations-list',
      menuItemName: 'Helyszínek módosítása',
      ordinalNumber: 9
    },
    {
      roleId: +RoleEnum.STUDENT,
      menuItemTypeId: 2,
      menuItemLink: '/sign-out',
      menuItemName: 'Kijelentkezés',
      ordinalNumber: 100
    },
    {
      roleId: +RoleEnum.STUDENT,
      menuItemTypeId: 2,
      menuItemLink: '/student/profile',
      menuItemName: 'Profil',
      ordinalNumber: 1
    },
    {
      roleId: +RoleEnum.STUDENT,
      menuItemTypeId: 2,
      menuItemLink: '/student/event-list',
      menuItemName: 'Saját jelentkezéseim',
      ordinalNumber: 2
    },
    {
      roleId: +RoleEnum.ADMIN,
      menuItemTypeId: 2,
      menuItemLink: '/sign-out',
      menuItemName: 'Kijelentkezés',
      ordinalNumber: 100
    },
    {
      roleId: +RoleEnum.ADMIN,
      menuItemTypeId: 2,
      menuItemLink: '/admin/profile',
      menuItemName: 'Profil',
      ordinalNumber: 1
    },{
      roleId: +RoleEnum.ADMIN,
      menuItemTypeId: 2,
      menuItemLink: '/admin/teacher-verify',
      menuItemName: 'Tanárok megerősítése',
      ordinalNumber: 2
    }
  ]

  getMenuItems(roleId: string, menuItemTypeId: number){
    return this.allMenuItems.filter(x=>x.roleId?.toString() === roleId && x.menuItemTypeId === menuItemTypeId).sort((a,b)=>a.ordinalNumber - b.ordinalNumber);
  }

}
