import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./location.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as LocaitonActions from '../location-store/location.action';
import { OnlineLocation } from '../../../../teacher/models/locations/online-location.model';
import { OfflineLocation } from '../../../../teacher/models/locations/offline-location.model';

export const LocaitonAdapter: EntityAdapter<OnlineLocation[] | OfflineLocation[] > =
    createEntityAdapter<OnlineLocation[] | OfflineLocation[] >();

export const initialState: State = ({
    loaded: false,
    data: null
});

const LocaitonReducer = createReducer(
    initialState,
    on(LocaitonActions.init, () =>
        ({ ...initialState })
    ),
    on(
        LocaitonActions.getAllLocationTypesSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.getAllLocationByTypeIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.getAllOfflineLocationsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.getAllPlatformsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.insertLocationsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.updateOfflineLocationSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.updateOnlineLocationSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.deleteOfflineLocationSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.deleteOnlineLocationSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.getOnlineLocationByIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.getOfflineLocationByIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LocaitonActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return LocaitonReducer(state,aciton);
}