import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./teacher-document.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as TeacherDocumentActions from './teacher-document.action';
import { UserDocumentR } from '../../../../shared/services/user/user.model';

export const TeacherDocumentAdapter: EntityAdapter<UserDocumentR> =
    createEntityAdapter<UserDocumentR>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const TeacherDocumentReducer = createReducer(
    initialState,
    on(TeacherDocumentActions.init, () =>
        ({ ...initialState })
    ),
    on(
        TeacherDocumentActions.getTeacherDocumentsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TeacherDocumentActions.updateTeacherDocumentSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        TeacherDocumentActions.deleteTeacherDocumentSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return TeacherDocumentReducer(state,aciton);
}