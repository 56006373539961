import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TimesModel } from '../../../shared/models/filter/filter.model';

@Component({
  selector: 'app-times',
  templateUrl: './times.component.html',
  styleUrls: ['./times.component.css']
})
export class TimesComponent implements OnInit {
  firstSelected = false;
  secondSelected = false;
  thirdSelected = false;
  fourthSelected = false;
  fifthSelected = false;
  sixthSelected = false;
  seventhSelected = false;
  eighthSelected = false;
  ninethSelected = false;

  mondaySelected = false;
  tuesdaySelected = false;
  wednesdaySelected = false;
  thursdaySelected = false;
  fridaySelected = false;
  saturdaySelected = false;
  sundaySelected = false;

  dayTimes: string[] = [];
  days: number[] = [];
  emitterResult = new timesEventEmitterModel();

  @Output() newItemClicked = new EventEmitter<timesEventEmitterModel>();

  ngOnInit(): void {
    const times = JSON.parse(localStorage.getItem('times') as string) as TimesModel[];
    const dayNumber = JSON.parse(localStorage.getItem('dayNumbers') as string) as number[];
    if (times && times.length > 0) {
      this.checkTimes(times);
    }
    if (dayNumber && dayNumber.length > 0) {
      this.checkDayNumbers(dayNumber);
    }
  }

  checkTimes(times: TimesModel[]) {
    times.forEach(x => {
      if (x.startHour === 9 && x.endHour === 12) {
        this.firstSelected = true;
      } else if (x.startHour === 12 && x.endHour === 15) {
        this.secondSelected = true;
      } else if (x.startHour === 15 && x.endHour === 18) {
        this.thirdSelected = true;
      } else if (x.startHour === 18 && x.endHour === 21) {
        this.fourthSelected = true;
      } else if (x.startHour === 21 && x.endHour === 24) {
        this.fifthSelected = true;
      } else if (x.startHour === 0 && x.endHour === 3) {
        this.sixthSelected = true;
      } else if (x.startHour === 3 && x.endHour === 6) {
        this.seventhSelected = true;
      } else if (x.startHour === 6 && x.endHour === 9) {
        this.eighthSelected = true;
      }
    });
  }

  checkDayNumbers(dayNumbers: number[]) {
    dayNumbers.forEach(x => {
      switch (x) {
        case 1:
          this.mondaySelected = true;
          break;
        case 2:
          this.tuesdaySelected = true;
          break;
        case 3:
          this.wednesdaySelected = true;
          break;
        case 4:
          this.thursdaySelected = true;
          break;
        case 5:
          this.fridaySelected = true;
          break;
        case 6:
          this.saturdaySelected = true;
          break;
        case 7:
          this.sundaySelected = true;
          break;
      }
    });
  }

  FirstClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.firstSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.firstSelected = !this.firstSelected;
    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  SecondClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.secondSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.secondSelected = !this.secondSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  ThirdClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.thirdSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.thirdSelected = !this.thirdSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  FourthClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.fourthSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.fourthSelected = !this.fourthSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  FifthClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.fifthSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.fifthSelected = !this.fifthSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  SixthClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.sixthSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.sixthSelected = !this.sixthSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  SeventhClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.seventhSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.seventhSelected = !this.seventhSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  EighthClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.eighthSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.eighthSelected = !this.eighthSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  NinethClicked(value: string) {
    if (!this.dayTimes.find(x => x === value)) {
      this.dayTimes.push(value);
    }
    if (this.ninethSelected === true) {
      let idx = this.dayTimes.findIndex(x => x === value);
      if (idx > -1) {
        this.dayTimes.splice(idx, 1);
      }
    }
    this.ninethSelected = !this.ninethSelected;

    this.emitterResult.dayTimes = this.dayTimes;
    this.newItemClicked.emit(this.emitterResult);
  }

  mondayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.mondaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.mondaySelected = !this.mondaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  tuesdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.tuesdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.tuesdaySelected = !this.tuesdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  wednesdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.wednesdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.wednesdaySelected = !this.wednesdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  thursdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.thursdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.thursdaySelected = !this.thursdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  fridayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.fridaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.fridaySelected = !this.fridaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  saturdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.saturdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.saturdaySelected = !this.saturdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  sundayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.sundaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.sundaySelected = !this.sundaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }
}

export class timesEventEmitterModel {
  dayTimes: string[] = [];
  days: number[] = [];
}
