import { createAction, props } from "@ngrx/store";
import { Step, TeacherStep, TeacherStepR, UpdateTeacherStepQ } from "../../../../shared/models/step/step.model";

export const init = createAction(
    '[Step/API] Load Step',
    props<{ id: number }>()
);

export const loadGetStepsByRole = createAction(
    '[Step/API] Load Get Steps By Role',
    props<{ data: number }>()
);

export const loadGetStepsByRoleSuccess = createAction(
    '[Step/API] Load Get Steps By Role Success',
    props<{ data: Step[] }>()
);

export const loadInsertTeacherSteps = createAction(
    '[Step/API] Load Insert Teacher Steps',
    props<{ data: TeacherStep[]}>()
);

export const loadInsertTeacherStepsSuccess = createAction(
    '[Step/API] Load Insert Teacher Steps Success',
    props<{ data: number }>()
);

export const loadUpdateTeacherStep = createAction(
    '[Step/API] Load Update Teacher Step',
    props<{ data: UpdateTeacherStepQ}>()
);

export const loadUpdateTeacherStepSuccess = createAction(
    '[Step/API] Load Update Teacher Step Success',
    props<{ data: number }>()
);

export const loadGetTeacherSteps = createAction(
    '[Step/API] Load Get Teacher Steps'
);

export const loadGetTeacherStepsSuccess = createAction(
    '[Step/API] Load Get Teacher Steps Success',
    props<{ data: TeacherStepR[] }>()
);

export const failure = createAction(
    '[Step/API] Load Steps Failure',
    props<{ error: any }>()
);