import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { BaseComponent } from '../../components/base-component/base-component.component';
import { SendEmailModel } from '../../models/email/send-email-to-students.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';

export interface SendEmailToAddressModalData {
  title: string;
  toAddress: string;
}

@Component({
  selector: 'app-send-email-to-address',
  standalone: true,
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, DragDropModule],
  templateUrl: './send-email-to-address.component.html',
  styleUrl: './send-email-to-address.component.css'
})

export class SendEmailToAddressModalComponent extends BaseComponent implements OnInit, OnDestroy {
  override form!: FormGroup;
  email: SendEmailModel = {};

  constructor(
    public dialogRef: MatDialogRef<SendEmailToAddressModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SendEmailToAddressModalData,
    private fb: FormBuilder,
    override translocoService: TranslocoService,
    override store: Store
  ) {
    super(store, undefined, undefined, translocoService)
  }

  ngOnInit(): void {
    this.formBuilder();
  }

  formBuilder() {
    this.form = this.fb.group({
      subject: new FormControl('', [Validators.required, Validators.minLength(1)]),
      body: new FormControl('', [Validators.required, Validators.minLength(5)]),
      reply_address: new FormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  createDataToEmail() {
    this.email = {};
    this.email.toAddresses = [];
    const subject = this.form.controls['subject'].value;
    const reply_address = this.form.controls['reply_address'].value;
    let body = this.form.controls['body'].value;
    body =
      '<p>' + body + '</p>' + '<br>' +
      '<b><u> Kérlek ide válaszolj nekem: </u></b> <br>' + reply_address;

    if (body && body !== '') {
      this.email.body = body;
    }
    if (subject && subject !== '') {
      this.email.subject = subject;
    }
    if (this.data.toAddress && this.data.toAddress !== '') {
      this.email.toAddresses?.push(this.data.toAddress);
    }
  }

  onSend(): void {
    this.createDataToEmail();
    if (this.email && this.email.toAddresses?.length! > 0) {
      this.store.dispatch(TeacherActions.sendEmailToAddresses({ data: this.email }));
      this.dialogRef.close(true);
    }else{
      this.dialogRef.close(false);
    }
  }

}
