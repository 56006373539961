import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as UserActions from './user.action';
import { catchError, concatMap, map, of } from "rxjs";
import { UserService } from "../user.service";
import { Store } from "@ngrx/store";
import { BaseComponent } from "../../../../shared/components/base-component/base-component.component";

@Injectable()
export class UserEffects extends BaseComponent {
    loadUsers$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.loadUserData),
            concatMap(() => this.userService.GetUserData().pipe(
                map((data) => {
                    return UserActions.loadUserDataSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    updateUsers$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.UpdateUserData),
            concatMap((action) => this.userService.UpdateUserData(action.data).pipe(
                map((data) => {
                    return UserActions.UpdateUserDataSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    getUsersProfilePicture$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.GetProfilePicture),
            concatMap(() => this.userService.GetUserProfilePicture().pipe(
                map((data) => {
                    return UserActions.GetProfilePictureSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    getUsersProfilePictureToDetails$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.GetProfilePictureToDetails),
            concatMap((action) => this.userService.GetUserProfilePictureToDetails(action.data).pipe(
                map((data) => {
                    return UserActions.GetProfilePictureToDetailsSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    DeleteUser$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.DeleteUserData),
            concatMap(() => this.userService.DeleteUser().pipe(
                map((data) => {
                    return UserActions.DeleteUserDataSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    UpdateUserFirstStepsDone$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.UpdateUserFirstStepsDone),
            concatMap((action) => this.userService.UpdateUserFirstStepsDone(action.data).pipe(
                map((data) => {
                    return UserActions.UpdateUserFirstStepsDoneSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    UpdateUserSubscription$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.UpdateUserSubscription),
            concatMap((action) => this.userService.UpdateUserSubscription(action.data).pipe(
                map((data) => {
                    return UserActions.UpdateUserSubscriptionSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    UpdateUserSeeded$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.UpdateUserSeeded),
            concatMap((action) => this.userService.UpdateUserSeeded(action.data).pipe(
                map((data) => {
                    return UserActions.UpdateUserSeededSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    CheckUserFirstLesson$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.CheckUserFirstLesson),
            concatMap((action) => this.userService.CheckUserFirstLesson(action.data).pipe(
                map((data) => {
                    return UserActions.CheckUserFirstLessonSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    UpdateUserIsFirstLogin$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.UpdateUserIsFirstLogin),
            concatMap((action) => this.userService.UpdateUserIsFirstLogin(action.data).pipe(
                map((data) => {
                    return UserActions.UpdateUserIsFirstLoginSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });

    GetLandingCounts$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UserActions.GetLandingCounts),
            concatMap(() => this.userService.GetLandingCounts().pipe(
                map((data) => {
                    return UserActions.GetLandingCountsSuccess({ data });
                }),
                catchError(() => of(UserActions.failure({ error: "Load users failure" })))
            )
            )
        );
    });


    constructor(
        override action$: Actions,
        private userService: UserService,
        override store: Store
    ) {
        super(store, undefined, action$)
    }
}