<form [formGroup]="form">
    <div class='items-center p-2 justify-center'>
        <div class="grid lg:grid-rows-1 lg:grid-cols-3 md:grid-rows-3 md:grid-cols-1">
            <div class="flex justify-self-center">
                <button class="button  py-2 px-4 rounded inline-flex items-center" (click)="previousWeek()">
                    <img src="../../../../assets/images/common/arrow-left-dark-green.svg" class="btn-icon"
                        alt="arrow-left" loading="lazy">
                </button>
                <span
                    class="uppercase lg:text-base md:text-base text-lg font-semibold text-light-green self-center">{{startDate
                    |
                    date:'YYY.MM.dd'}}
                    - {{endDate | date:'YYY.MM.dd'}}</span>
                <button class="button  py-2 px-4 rounded inline-flex items-center" (click)="nextWeek()">
                    <img src="../../../../assets/images/common/arrow-right-dark-green.svg" class="btn-icon"
                        alt="arrow-left" loading="lazy">
                </button>
            </div>
            <div class="mt-1 mb-1">
                <div class="justify-self-center ">
                    <div class="flex flex-wrap items-center justify-center">
                        <div class="bg-light-green rounded-full w-4 h-4">
                        </div>
                        <p class="justify-self-center ml-2"> {{'teacher.timetable.available' | transloco}} </p>
                    </div>
                    <div class="flex flex-wrap items-center justify-center">
                        <div class="bg-dark-green rounded-full w-4 h-4">
                        </div>
                        <p class="justify-self-center ml-2"> {{'teacher.timetable.busy' | transloco}} </p>
                    </div>
                </div>
            </div>
            <div>
                <select id="roleType" #lesson (change)="lessonChange(lesson.value)"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    @if(lessons && lessons.length > 0){
                    <option [value]="allLessonsId">
                        {{'schedule.all_subjects' | transloco}}
                    </option>
                    @for(lesson of lessons; track $index){
                    <option value="{{lesson.lessonId}}">
                        {{lesson.lessonName}}
                    </option>
                    } @empty {
                    <option value="">{{ 'teacher.calendar.event.noLesson' | transloco }}</option>
                    }
                    <option [value]="availableLessonId">
                        {{ 'teacher.calendar.event.available' | transloco }}
                    </option>
                    }
                </select>
            </div>
        </div>
        <div class="grid lg:grid-rows-1 lg:grid-cols-3 md:grid-rows-3 md:grid-cols-1 pb-10 pt-5">
            <div class="pb-2 justify-self-center" (click)="askSchedule()">
                <button class="text-white bg-light-green  hover:bg-dark-green rounded-xl py-1 px-2" type="button">
                    <p class="justify-self-center"> {{'teacher.timetable.ask_lesson' | transloco}} </p>
                </button>
            </div>
            <div>

            </div>
            <div class="">
                <select id="roleType" #timeZones formControlName="timeZone"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    @if(allTimeZones && allTimeZones.length > 0){
                    @for(timeZone of allTimeZones; track $index){
                    <option value="{{timeZone}}">
                        {{timeZone}}
                    </option>
                    }
                    }
                </select>
            </div>
        </div>

        <div
            class="grid lg:grid-rows-1 lg:grid-cols-7 md:grid-rows-3 md:grid-cols-3 grid-cols-1 pb-10 pt-5">
            <div [class.active]="weekDays[0] >= actualDate" [class.inactive]="weekDays[0] < actualDate"
                class="dayBorder mr-10 text-center rounded-sm items-center justify-center pt-6 pb-2 border-t">
                <div class="grid grid-rows-2 grid-cols-1 mb-4">
                    @if(checkToday(weekDays[0])){
                    <span class="lg:text-base md:text-base text-lg">
                        {{'common.today' | transloco}}
                    </span>
                    <span class="lg:text-base md:text-base text-lg">
                        {{weekDays[0] | date:'MM.dd' }}
                    </span>
                    }@else{
                    <span class="lg:text-base md:text-base text-lg">
                        {{'Monday' | transloco}}
                    </span>
                    <span class="lg:text-base md:text-base text-lg">
                        {{weekDays[0] | date:'MM.dd' }}
                    </span>
                    }
                </div>
                @if(events && events.length>0){
                <div class="grid grid-flow-row grid-cols-1">
                    @if(mondayEvents.length>0){
                    @for(event of mondayEvents; track $index){
                    <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[0] < actualDate"
                        [event]="event"></timetable-event-button>
                    }
                    }
                </div>
                }
            </div>
            <div>
                <div [class.active]="weekDays[1] >= actualDate" [class.inactive]="weekDays[1] < actualDate"
                    class="dayBorder mr-10 text-center px-3 rounded-sm items-center justify-center pb-2 pt-6 border-t">
                    <div class="grid grid-rows-2 grid-cols-1 mb-4">
                        @if(checkToday(weekDays[1])){
                        <span class="lg:text-base md:text-base text-lg">
                            {{'common.today' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[1] | date:'MM.dd' }}
                        </span>
                        }@else{
                        <span class="lg:text-base md:text-base text-lg">
                            {{'Tuesday' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[1] | date:'MM.dd' }}
                        </span>
                        }
                    </div>
                    @if(events && events.length>0){
                    <div class="grid grid-flow-row grid-cols-1">
                        @if(tuesdayEvents.length>0){
                        @for(event of tuesdayEvents; track $index){
                        <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[1] < actualDate"
                            [event]="event"></timetable-event-button>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
            <div>
                <div [class.active]="weekDays[2] >= actualDate" [class.inactive]="weekDays[2] < actualDate"
                    class="dayBorder mr-10 text-center px-3 rounded-sm items-center justify-center pb-2 pt-6 border-t">
                    <div class="grid grid-rows-2 grid-cols-1 mb-4">
                        @if(checkToday(weekDays[2])){
                        <span class="lg:text-base md:text-base text-lg">
                            {{'common.today' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[2] | date:'MM.dd' }}
                        </span>
                        }@else{
                        <span class="lg:text-base md:text-base text-lg">
                            {{'Wednesday' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[2] | date:'MM.dd' }}
                        </span>
                        }
                    </div>
                    @if(events && events.length>0){
                    <div class="grid grid-flow-row grid-cols-1">
                        @if(wednesdayEvents.length>0){
                        @for(event of wednesdayEvents; track $index){
                        <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[2] < actualDate"
                            [event]="event"></timetable-event-button>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
            <div>
                <div [class.active]="weekDays[3] >= actualDate" [class.inactive]="weekDays[3] < actualDate"
                    class="dayBorder mr-10 text-center px-3 rounded-sm items-center justify-center pb-2 pt-6 border-t">
                    <div class="grid grid-rows-2 grid-cols-1 mb-4">
                        @if(checkToday(weekDays[3])){
                        <span class="lg:text-base md:text-base text-lg">
                            {{'common.today' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[3] | date:'MM.dd' }}
                        </span>
                        }@else{
                        <span class="lg:text-base md:text-base text-lg">
                            {{'Thursday_short' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[3] | date:'MM.dd' }}
                        </span>
                        }
                    </div>
                    @if(events && events.length>0){
                    <div class="grid grid-flow-row grid-cols-1">
                        @if(thursdayEvents.length>0){
                        @for(event of thursdayEvents; track $index){
                        <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[3] < actualDate"
                            [event]="event"></timetable-event-button>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
            <div>
                <div [class.active]="weekDays[4] >= actualDate" [class.inactive]="weekDays[4] < actualDate"
                    class="dayBorder mr-10 text-center px-3 rounded-sm items-center justify-center pb-2 pt-6 border-t">
                    <div class="grid grid-rows-2 grid-cols-1 mb-4">
                        @if(checkToday(weekDays[4])){
                        <span class="lg:text-base md:text-base text-lg">
                            {{'common.today' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[4] | date:'MM.dd' }}
                        </span>
                        }@else{
                        <span class="lg:text-base md:text-base text-lg">
                            {{'Friday' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[4] | date:'MM.dd' }}
                        </span>
                        }
                    </div>
                    @if(events && events.length>0){
                    <div class="grid grid-flow-row grid-cols-1">
                        @if(fridayEvents.length>0){
                        @for(event of fridayEvents; track $index){
                        <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[4] < actualDate"
                            [event]="event"></timetable-event-button>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
            <div>
                <div [class.active]="weekDays[5] >= actualDate" [class.inactive]="weekDays[5] < actualDate"
                    class="dayBorder mr-10 text-center px-3 rounded-sm items-center justify-center pb-2 pt-6 border-t">
                    <div class="grid grid-rows-2 grid-cols-1 mb-4">
                        @if(checkToday(weekDays[5])){
                        <span class="lg:text-base md:text-base text-lg">
                            {{'common.today' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[5] | date:'MM.dd' }}
                        </span>
                        }@else{
                        <span class="lg:text-base md:text-base text-lg">
                            {{'Saturday_short' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[5] | date:'MM.dd' }}
                        </span>
                        }
                    </div>
                    @if(events && events.length>0){
                    <div class="grid grid-flow-row grid-cols-1">
                        @if(saturdayEvents.length>0){
                        @for(event of saturdayEvents; track $index){
                        <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[5] < actualDate"
                            [event]="event"></timetable-event-button>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
            <div>
                <div [class.active]="weekDays[6] >= actualDate" [class.inactive]="weekDays[6] < actualDate"
                    class="dayBorder mr-10 text-center px-3 rounded-sm items-center justify-center pb-2 pt-6 border-t">
                    <div class="grid grid-rows-2 grid-cols-1 mb-4">
                        @if(checkToday(weekDays[6])){
                        <span class="lg:text-base md:text-base text-lg">
                            {{'common.today' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[6] | date:'MM.dd' }}
                        </span>
                        }@else{
                        <span class="lg:text-base md:text-base text-lg">
                            {{'Sunday_short' | transloco}}
                        </span>
                        <span class="lg:text-base md:text-base text-lg">
                            {{weekDays[6] | date:'MM.dd' }}
                        </span>
                        }
                    </div>
                    @if(events && events.length>0){
                    <div class="grid grid-flow-row grid-cols-1">
                        @if(sundayEvents.length>0){
                        @for(event of sundayEvents; track $index){
                        <timetable-event-button class="mb-2" (hit)="eventDateClicked($event)" [isDisabled]= "weekDays[6] < actualDate"
                            [event]="event"></timetable-event-button>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</form>