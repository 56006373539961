<div class="grid grid-rows-3 gap-4 m-5">
    <div class="row-start-1 lg:w-2/3 md:w-2/3 w-full justify-self-center">
        <div
            class="max-w justify-center p-6 m-6 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 sign-up-background">
            <div>
                <h3 class="text-white text-center mb-6 text-2xl font-bold">{{'forgot_password.title' | transloco}}</h3>
            </div>
            @if(!setPasswordConfirmed){
            <form [formGroup]="form" class="group">
                <div class="justify-items-center">
                    <div class="w-full mb-6">
                        <label for="email" class="mb-2 text-sm font-medium text-white dark:text-white">{{'common.email'
                            | transloco}}: </label>
                        <input type="email" id="email" class="
                                bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-light-green
                                focus:border-light-green w-full p-2.5
                                invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 ring-red-500"
                            placeholder="Kérlek írd be az e-mail címed" formControlName="email" #email required>
                    </div>
                    <div class="mb-6">
                        <button class="btn-primary justify-center hover:bg-light-orange w-full" [disabled]="!form.valid"
                            (click)="sendClick()">
                            <p>{{'forgot_password.send' | transloco}}</p>
                        </button>
                    </div>
                </div>
            </form>
            }
            @if(setPasswordConfirmed){
            <form [formGroup]="setPasswordForm" class="group">
                <div class="w-full mb-6" [class.error]="setPasswordForm.controls['password'].invalid && setPasswordForm.controls['password'].touched">
                    <label for="password" class="block text-sm font-medium text-white leading-6">
                        {{'common.password'
                        | transloco}}</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                        <input [type]="type" formControlName="password" name="password" id="password" class="input-text"
                            placeholder="*****">
                        <button type="button" class="absolute inset-y-0 right-3 flex items-center" (click)="eyeClicked()">
                            @if(type === 'password'){
                            <img src="../../../../assets/images/eye.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                alt="eye">
                            }@else if(type === 'text'){
                            <img src="../../../../assets/images/eye-slash.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                alt="eye">
                            }
                        </button>
                        @if(setPasswordForm.controls['password'].touched){
                        <div class="absolute end-0 bottom-[-15px] text-red-600 text-xs">
                            @if(setPasswordForm.errors?.['noUpperCase']){
                            <span>
                                {{'validation.error.uppercase' | transloco}},
                            </span>
                            }
                            @if(setPasswordForm.errors?.['noLowerCase']){
                            <span>
                                {{'validation.error.lowercase' | transloco}},
                            </span>
                            }
                            @if(setPasswordForm.errors?.['noNumber']){
                            <span>
                                {{'validation.error.number' | transloco}},
                            </span>
                            }
                            @if(setPasswordForm.errors?.['noSpecialChar']){
                            <span>
                                {{'validation.error.special' | transloco}},
                            </span>
                            }
                            <span>{{
                                getError(setPasswordForm.controls['password'])
                                }}</span>
                        </div>
                        }
                    </div>
                </div>
                <div class="w-full mb-6" [class.error]="setPasswordForm.controls['confirm_password'].invalid && setPasswordForm.controls['confirm_password'].touched">
                    <label for="confirm_password" class="block text-sm font-medium text-white leading-6">
                        {{'common.password_confirm'
                        | transloco}}</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                        <input [type]="confirmType" formControlName="confirm_password" name="confirm_password"
                            id="confirm_password" class="input-text" placeholder="*****">
                        <button type="button" class="absolute inset-y-0 right-3 flex items-center" (click)="eyeConfirmClicked()">
                            @if(confirmType === 'password'){
                            <img src="../../../../assets/images/eye.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                alt="eye">
                            }@else if(confirmType === 'text'){
                            <img src="../../../../assets/images/eye-slash.svg" class="h-7 w-7 btn-icon" loading="lazy"
                                alt="eye">
                            }
                        </button>
                        @if(setPasswordForm.controls['password'].touched){
                            <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                                getError(setPasswordForm.controls['password'])
                                }}</span>
                          }
                    </div>
                    @if(setPasswordForm.errors?.['PasswordNoMatch']){
                    <div class="abottom-[-15px] text-red-600 text-xs">
                        {{'validation.error.doesnt_match' | transloco}}
                    </div>
                    }
                </div>
                <div class="mb-6">
                    <button type="submit" class="btn-primary justify-center hover:bg-light-orange w-full"
                        [disabled]="!setPasswordForm.valid" (click)="sendPasswordClick()">
                        <p>{{'forgot_password.send' | transloco}}</p>
                    </button>
                </div>
            </form>
            }
        </div>
    </div>
</div>