<div class="grid grid-rows-3 gap-4 m-5">
    <div class="row-start-1 lg:w-2/3 md:w-2/3 w-full justify-self-center">
        <div
            class="max-w justify-center p-6 m-6 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 sign-up-background">
            <div>
                <h3 class="text-white text-center mb-6 text-2xl font-bold">{{'confirm_email.title' | transloco}}</h3>
            </div>
            @if(!isEmailConfirmed){
                <div class="flex items-center bg-light-orange text-white text-sm font-bold mt-2 rounded-xl px-4 py-3"
                role="alert">
                <img src="./assets/images/information.svg" loading="lazy" alt="information" class="w-5 h-5 mr-2">
                <p>{{'confirm_email.information_text' | transloco}}</p>
              </div>
            }
        </div>
    </div>
</div>