import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TOAST_STATE, ToastService } from '../../services/toast/toast.service';
import { BaseComponent } from '../base-component/base-component.component';
import * as AuthActions from '../../services/auth/authentication-store/authentication.action'
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent extends BaseComponent implements OnInit {
  isEmailConfirmed = false;
  token = '';
  email = '';
  constructor(
    override translocoService: TranslocoService,
    override store: Store,
    public route: ActivatedRoute,
    private router: Router,
    override toastService: ToastService,
    override action$?: Actions
  ) {
    super(store, toastService, action$, translocoService);

    action$?.pipe(ofType(AuthActions.ConfirmEmailSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeres fiók aktiválás!");
        this.isEmailConfirmed = response.data;
        this.router.navigateByUrl('/sign-in')
      } else {
        this.toastService.showToast(TOAST_STATE.danger, "Hiba az aktiválás során!");
        this.router.navigateByUrl('/sign-up')
      }
    })
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.token = params['token'];
        this.email = params['email'];
      });

    if (this.token !== '' && this.token && this.email !== '' && this.email) {
      this.store.dispatch(AuthActions.ConfirmEmail({
        data: {
          token: this.token,
          email: this.email
        }
      }))
    }
  }
}