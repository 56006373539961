import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { filterQ } from '../../../shared/models/filter/filter.model';
import { ToastService } from '../../../shared/services/toast/toast.service';
import { TeacherByFilterDTO, TeacherR } from '../../../teacher/models/teacher/teacher.model';
import { LessonR } from '../../../teacher/models/lesson/lesson.model';
import { OfflineLocationCityR } from '../../../teacher/models/locations/offline-location.model';
import { LocationTypeR } from '../../../teacher/models/locations/location-type.model';
import { timesEventEmitterModel } from '../times/times.component';
import * as LessonActions from '../../../teacher/services/lesson/lesson-store/lesson.action';
import * as LocationActions from '../../../teacher/services/location/location-store/location.action';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import * as LessonTypeActions from '../../../teacher/services/lesson-type/lesson-type-store/lesson-type.action';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, map, startWith, takeUntil } from 'rxjs';
import { TeacherSortEnum } from '../../../shared/enums/teacher-sort-enum';
import { UtilService } from '../../../shared/services/utils/utils.serivce';
import { HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { LessonTypeR } from 'src/app/teacher/models/lesson-type/lesson-type.model';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() teacherListVisible = new EventEmitter<TeacherR[]>();
  override form!: FormGroup;
  isLessonDropdownOpen = false;
  isLessonTypeDropdownOpen = false;
  isSortDropdownOpen = false;
  isLocationTypeDropdownOpen = false;
  isLocationDropdownOpen = false;
  isTimesDropdownOpen = false;
  isMobileFiltersOpen = false;
  allLessons: LessonR[] = [];
  offlineLocationCityNames: OfflineLocationCityR[] = [];
  allLessonTypes: LessonTypeR[] = [];
  locationTypes: LocationTypeR[] = [];
  checkedLessonids: number[] = [];
  checkedLessonTypeids: number[] = [];
  checkedLocationTypeids: number[] = [];
  checkedLocationCities: string[] = [];
  timesData = new timesEventEmitterModel();
  selectedSort = -1;
  filterQ = new filterQ();
  teachers: TeacherByFilterDTO[] = [];
  dtoCreated = false;
  filteredOptions!: Observable<LessonR[]>;
  teacherSorting: TeacherSortEnum[] = [
    TeacherSortEnum.OUR_TOP_OFFERS,
    TeacherSortEnum.POPULARITY,
    TeacherSortEnum.HIGHEST_PRICE,
    TeacherSortEnum.LOWEST_PRICE,
    TeacherSortEnum.BY_REVIEWS,
    TeacherSortEnum.BEST_RATING
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    override titleService: Title,
    override store: Store,
    override toastService: ToastService,
    private utilService: UtilService,
    override action$?: Actions,
    override translocoService?: TranslocoService
  ) {
    super(store, toastService, action$, translocoService, titleService);

    action$?.pipe(ofType(LessonActions.getAllLessonByLessonTypeIdsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      this.allLessons = [];
      this.filteredOptions = this.form.controls['lesson_filter'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
      if (response && response.data) {
        if (response && response.data.length > 0) {
          response.data.forEach(x => this.allLessons.push(x))
          this.createLessonFormControls();
          this.filteredOptions = this.form.controls['lesson_filter'].valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
        }

        if (this.filterQ.lessonIds && this.filterQ.lessonIds.length > 0) {
          this.filterQ.lessonIds?.forEach(x => {
            this.form.controls['lesson' + x].patchValue(true);
          })
        }

      }
    })


    action$?.pipe(ofType(LocationActions.getAllOfflineLocationsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        if (response && response.data.length > 0) {
          this.offlineLocationCityNames = response.data;
          this.createCitiesFormControls();

          if (this.filterQ.locationNames && this.filterQ.locationNames.length > 0) {
            this.filterQ.locationNames?.forEach(x => {
              const id = this.offlineLocationCityNames.find(y => y.name.toLocaleLowerCase() === x.toLocaleLowerCase())?.locationId;
              if (id) {
                this.form.controls['location' + id].patchValue(true);
              }
            })
          }

        }
      }
    })

    action$?.pipe(ofType(LocationActions.getAllLocationTypesSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        if (response && response.data.length > 0) {
          this.locationTypes = response.data;
          this.createLocationTypesFormControls();

          if (this.filterQ.locationTypeIds && this.filterQ.locationTypeIds.length > 0) {
            this.filterQ.locationTypeIds?.forEach(x => {
              this.form.controls['locationType' + x].patchValue(true);
            })
          }
        }
      }
    })


    action$?.pipe(ofType(TeacherActions.loadGetTeacherByFiltersSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      this.teachers = [];
      if (response && response.data && response.data.length > 0) {
        this.teachers = response.data;
      }
    })

    action$?.pipe(ofType(LessonTypeActions.loadLessonTypesSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        response.data.forEach(x => this.allLessonTypes.push(x));
        this.createLessonTypeFormControls();

        if (this.filterQ.lessonTypeIds && this.filterQ.lessonTypeIds.length > 0) {
          this.filterQ.lessonTypeIds?.forEach(x => {
            this.form.controls['lessonType' + x].patchValue(true);
          })
        }
      }
    })
  }

  ngOnInit(): void {
    this.setTitle('filter.title');
    this.removeLocalStorage();
    this.subscriptionsInit();
    this.formBuilder();
  }

  patchFormValuesFromQuery() {
    if (this.filterQ.minPrice) {
      this.form.controls['min_price'].patchValue(this.filterQ.minPrice);
    }
    if (this.filterQ.maxPrice) {
      this.form.controls['max_price'].patchValue(this.filterQ.maxPrice);
    }
  }

  removeLocalStorage() {
    localStorage.removeItem('dayNumbers');
    localStorage.removeItem('times');
  }

  createFilterDTOFromQuery(params: Params): boolean {
    let dtoCreated = false;
    this.filterQ = new filterQ();
    let lessonIds = params['lessonIds'] as string;
    if (lessonIds && lessonIds.length > 0) {
      dtoCreated = true;
      if (lessonIds.includes(',')) {
        let splitedLessonIds = lessonIds.split(',');
        splitedLessonIds.forEach(lessonId => {
          this.filterQ.lessonIds?.push(Number(lessonId));
        });
      } else {
        this.filterQ.lessonIds?.push(Number(lessonIds));
      }
    }

    let locationNames = params['locationNames'] as string;
    if (locationNames && locationNames.length > 0) {
      dtoCreated = true;
      if (locationNames.includes(',')) {
        let splitedlocationNames = locationNames.split(',');
        splitedlocationNames.forEach(locationName => {
          this.filterQ.locationNames?.push(locationName);
        });
      } else {
        this.filterQ.locationNames?.push(locationNames);
      }
    }

    let dayNumbers = params['dayNumbers'] as string;
    if (dayNumbers && dayNumbers.length > 0) {
      dtoCreated = true;
      if (dayNumbers.includes(',')) {
        let spliteddayNumbers = dayNumbers.split(',');
        spliteddayNumbers.forEach(dayNumber => {
          this.filterQ.daysNumbers?.push(Number(dayNumber));
        });
      } else {
        this.filterQ.daysNumbers?.push(Number(dayNumbers));
      }
      localStorage.setItem('dayNumbers', JSON.stringify(this.filterQ.daysNumbers));
    }

    let locationTypeIds = params['locationTypeIds'] as string;
    if (locationTypeIds && locationTypeIds.length > 0) {
      dtoCreated = true;
      if (locationTypeIds.includes(',')) {
        let splitedlocationTypeIds = locationTypeIds.split(',');
        splitedlocationTypeIds.forEach(locationTypeId => {
          this.filterQ.locationTypeIds?.push(Number(locationTypeId));
        });
      } else {
        this.filterQ.locationTypeIds?.push(Number(locationTypeIds));
      }
    }

    let maxPrice = params['maxPrice'] as string;
    if (maxPrice) {
      dtoCreated = true;
      this.filterQ.maxPrice = maxPrice;
    }

    let minPrice = params['minPrice'] as string;
    if (minPrice) {
      dtoCreated = true;
      this.filterQ.minPrice = minPrice;
    }

    let sort = params['sort'] as number;
    if (sort) {
      dtoCreated = true;
      this.filterQ.sort = sort;
    }

    let availableHours = params['availableHours'] as string;
    if (availableHours && availableHours.length > 0) {
      dtoCreated = true;
      if (availableHours.includes(',')) {
        let splitedavailableHours = availableHours.split(',');
        splitedavailableHours.forEach(availableHour => {
          let splittedAvailableHour = availableHour.split('-');
          this.filterQ.availableHours?.push({
            startHour: Number(splittedAvailableHour[0]),
            endHour: Number(splittedAvailableHour[1])
          })
        });
      } else {
        let splittedAvailableHour = availableHours.split('-');
        this.filterQ.availableHours?.push({
          startHour: Number(splittedAvailableHour[0]),
          endHour: Number(splittedAvailableHour[1])
        })
      }
      localStorage.setItem('times', JSON.stringify(this.filterQ.availableHours));
    }

    let lessonTypeIds = params['lessonTypeIds'] as string;
    if (lessonTypeIds && lessonTypeIds.length > 0) {
      dtoCreated = true;
      if (lessonTypeIds.includes(',')) {
        let splitedLessonTypeIds = lessonTypeIds.split(',');
        splitedLessonTypeIds.forEach(lessonTypeId => {
          this.filterQ.lessonTypeIds?.push(Number(lessonTypeId));
        });
      } else {
        this.filterQ.lessonTypeIds?.push(Number(lessonTypeIds));
      }
    }

    return dtoCreated;
  }

  subscriptionsInit() {
    this.route.queryParams
      .subscribe(params => {
        this.dtoCreated = this.createFilterDTOFromQuery(params);
      })

    let filterSection = document.getElementById('filter-section-0');
    if (filterSection) {
      filterSection.style.display = "none";
    }

    this.patchFormValuesFromQuery();
    this.store.dispatch(TeacherActions.loadGetTeacherByFilters({ filter: this.filterQ }));
    if (this.filterQ.lessonTypeIds) {
      this.store.dispatch(LessonActions.getAllLessonByLessonTypeIds({ lessonTypeIds: this.filterQ.lessonTypeIds }));
    }
    this.store.dispatch(LocationActions.getAllOfflineLocations());
    this.store.dispatch(LocationActions.getAllLocationTypes());
    this.store.dispatch(LessonTypeActions.loadLessonTypes());
  }

  private formBuilder() {
    this.form = new FormBuilder().group({
      min_price: new FormControl(''),
      max_price: new FormControl(''),
      lesson_filter: new FormControl(''),
      sort: new FormControl('')
    });
  }

  createLessonFormControls() {
    this.allLessons.forEach(x => {
      this.form.addControl('lesson' + x.lessonId, new FormControl(''));
    })
  }

  createLessonTypeFormControls() {
    this.allLessonTypes.forEach(x => {
      this.form.addControl('lessonType' + x.lessonTypeId, new FormControl(''));
    })
  }

  createLocationTypesFormControls() {
    this.locationTypes.forEach(x => {
      this.form.addControl('locationType' + x.locationTypeId, new FormControl(''));
    })
  }

  createCitiesFormControls() {
    this.offlineLocationCityNames.forEach(x => {
      this.form.addControl('location' + x.locationId, new FormControl(''));
    })
  }

  getTimesData(data: timesEventEmitterModel) {
    this.timesData = data;
  }

  createFilterQueryParams(filter: filterQ): HttpParams {
    let queryParams = new HttpParams();
    if (filter.locationNames?.length! > 0) {
      queryParams = queryParams.append("locationNames", filter.locationNames?.toString()!);
    }
    if (filter.lessonIds?.length! > 0) {
      queryParams = queryParams.append("lessonIds", filter.lessonIds?.toString()!);
    }
    if (filter.lessonTypeIds?.length! > 0) {
      queryParams = queryParams.append("lessonTypeIds", filter.lessonTypeIds?.toString()!);
    }
    if (filter.availableHours && filter.availableHours?.length! > 0) {
      let availableHoursQuery = '';
      for (let index = 0; index < filter.availableHours?.length; index++) {
        if (index == filter.availableHours?.length - 1) {
          availableHoursQuery = availableHoursQuery + filter.availableHours[index].startHour + '-' + filter.availableHours[index].endHour
        } else {
          availableHoursQuery = availableHoursQuery + filter.availableHours[index].startHour + '-' + filter.availableHours[index].endHour + ','
        }
      }
      queryParams = queryParams.append("availableHours", availableHoursQuery);
    }
    if (filter.daysNumbers?.length! > 0) {
      queryParams = queryParams.append("daysNumbers", filter.daysNumbers?.toString()!);
    }
    if (filter.locationTypeIds?.length! > 0) {
      queryParams = queryParams.append("locationTypeIds", filter.locationTypeIds?.toString()!);
    }
    if (Number(filter.maxPrice) > 0) {
      queryParams = queryParams.append("maxPrice", filter.maxPrice?.toString()!);
    }
    if (Number(filter.minPrice) > 0) {
      queryParams = queryParams.append("minPrice", filter.minPrice?.toString()!);
    }
    if (Number(filter.sort) > 0) {
      queryParams = queryParams.append("sort", filter.sort?.toString()!);
    }

    return queryParams;
  }

  removePrices() {
    this.form.controls['min_price'].patchValue('');
    this.form.controls['max_price'].patchValue('');
  }


  filterSearchClick() {
    this.teachers = [];
    this.createFilterQuery();
    this.filterQ = JSON.parse(JSON.stringify(this.filterQ));

    const params = this.createFilterQueryParams(this.filterQ).toString();
    const newUrl = '/teacher-filter?' + params;
    this.router.navigateByUrl(newUrl);
    this.store.dispatch(TeacherActions.loadGetTeacherByFilters({ filter: this.filterQ }));
  }

  createFilterQuery() {
    this.fillCheckedLessons();
    this.fillCheckedLocationTypes();
    this.fillCheckedLocations();
    this.fillCheckedLessonTypes();

    this.filterQ = JSON.parse(JSON.stringify(this.filterQ));
    this.filterQ.lessonIds = this.checkedLessonids;
    this.filterQ.locationTypeIds = this.checkedLocationTypeids;
    this.filterQ.locationNames = this.checkedLocationCities;
    this.filterQ.lessonTypeIds = this.checkedLessonTypeids;
    this.filterQ.minPrice = this.form.controls['min_price'].value;
    this.filterQ.maxPrice = this.form.controls['max_price'].value;
    this.filterQ.sort = this.selectedSort;

    this.createHourTimesModel();
    this.createDaysTimesModel();
  }

  fillCheckedLessons() {
    this.checkedLessonids = [];
    this.allLessons.forEach(x => {
      const value = this.form.controls['lesson' + x.lessonId].value;
      if (value === true) {
        this.checkedLessonids.push(x.lessonId)
      }
    });
  }

  fillCheckedLessonTypes() {
    this.checkedLessonTypeids = [];
    this.allLessonTypes.forEach(x => {
      const value = this.form.controls['lessonType' + x.lessonTypeId].value;
      if (value === true) {
        this.checkedLessonTypeids.push(x.lessonTypeId)
      }
    });
  }

  fillCheckedLocationTypes() {
    this.checkedLocationTypeids = [];
    this.locationTypes.forEach(x => {
      const value = this.form.controls['locationType' + x.locationTypeId].value;
      if (value === true) {
        this.checkedLocationTypeids.push(x.locationTypeId)
      }
    });
  }

  fillCheckedLocations() {
    this.checkedLocationCities = [];
    this.offlineLocationCityNames.forEach(x => {
      const value = this.form.controls['location' + x.locationId].value;
      if (value === true) {
        this.checkedLocationCities.push(x.name)
      }
    });
  }

  createHourTimesModel() {
    this.filterQ.availableHours = [];
    this.timesData.dayTimes.forEach(x => {
      const hoursSplit = x.split('-');
      this.filterQ.availableHours?.push({
        startHour: hoursSplit[0] as unknown as number,
        endHour: hoursSplit[1] as unknown as number
      })
    });
  }

  createDaysTimesModel() {
    this.filterQ.daysNumbers = [];
    this.timesData.days.forEach(x => {
      this.filterQ.daysNumbers?.push(x);
    });
  }

  toggleDropdownMobile(display: string) {
    let allElements = document.getElementById('mobile-all-lessons');
    if (allElements) {
      allElements.style.display = display;
    }
  }

  toggleDropdownMobile1(display: string) {
    let allElements = document.getElementById('mobile-location-types');
    if (allElements) {
      allElements.style.display = display;
    }
  }

  toggleDropdownMobile2(display: string) {
    let allElements = document.getElementById('mobile-locations');
    if (allElements) {
      allElements.style.display = display;
    }
  }

  toggleDropdownMobile3(display: string) {
    let allElements = document.getElementById('mobile-times');
    if (allElements) {
      allElements.style.display = display;
    }
  }

  selectSort(sort: string) {
    const idx = this.utilService.getKeyIndexByValue(sort, TeacherSortEnum);
    if (idx > -1) {
      this.selectedSort = idx;
    }
  }

  mobileFiltersClose() {
    this.isMobileFiltersOpen = false;
  }

  mobileFiltersOpen() {
    this.isMobileFiltersOpen = true;
  }

  onResize(event: any) {
    const screenWidth = event.target.innerWidth;
    if (screenWidth < 1024) {
      this.form.patchValue(this.form.value)
    }
  }

  lessonTypeChange() {
    this.fillCheckedLessonTypes();
    this.store.dispatch(LessonActions.getAllLessonByLessonTypeIds({ lessonTypeIds: this.checkedLessonTypeids }));
  }

  private _filter(value: string): LessonR[] {
    const filterValue = value.toLowerCase();

    return this.allLessons.filter(option => option.lessonName.toLowerCase().includes(filterValue));
  }
}
