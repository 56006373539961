import { createAction, props } from "@ngrx/store";

export const init = createAction(
    '[News Letter/API] Load News Letter',
    props<{ id: number }>()
);

export const insertNewsLetterSubscriber = createAction(
    '[News Letter/API] Insert Newsletter',
    props<{ data: string }>()
);

export const insertNewsLetterSubscriberSuccess = createAction(
    '[News Letter/API] Insert Newsletter Success',
    props<{ data: number }>()
);


export const deleteNewsLetterSubscriber = createAction(
    '[News Letter/API] Delete Newsletter',
    props<{ data: string }>()
);

export const deleteNewsLetterSubscriberSuccess = createAction(
    '[News Letter/API] Delete Newsletter Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[News Letter/API] Load Newsletter Failure',
    props<{ error: any }>()
);