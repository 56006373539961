import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./user.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as UserActions from './user.action';
import { UserDataR } from '../user.model';

export const UserAdapter: EntityAdapter<UserDataR> =
    createEntityAdapter<UserDataR>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const UserReducer = createReducer(
    initialState,
    on(UserActions.init, () =>
        ({ ...initialState })
    ),
    on(
        UserActions.loadUserDataSuccess, (state, { data }) => ({
            ...state,
            userData: data,
            loaded: true
        })
    ),
    on(
        UserActions.UpdateUserDataSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.GetLandingCountsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.GetProfilePictureSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.GetProfilePictureToDetailsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.CheckUserFirstLessonSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    ),
    on(
        UserActions.DeleteUserDataSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.UpdateUserFirstStepsDoneSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.UpdateUserSeededSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        UserActions.UpdateUserIsFirstLoginSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return UserReducer(state,aciton);
}