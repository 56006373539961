import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TeacherAssessmentR } from '../../../teacher/models/teacher/teacher-assessment.model';
import { BaseComponent } from '../base-component/base-component.component';
import { ToastService } from '../../services/toast/toast.service';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-reviews-summary',
  templateUrl: './reviews-summary.component.html',
  styleUrls: ['./reviews-summary.component.css']
})
export class ReviewsSummaryComponent extends BaseComponent implements OnInit, OnDestroy {
  numberAssessments: number[] = [];
  barStylings: string[] = [];
  allCount: number = 0;
  summaryRating: number = 0;
  teacherId = 0;
  textAssessments: TeacherAssessmentR[] = [];

  constructor(
    private route: ActivatedRoute,
    override store: Store,
    override toastService: ToastService,
    override action$?: Actions
  ) {
    super(store, toastService, action$);

    action$?.pipe(ofType(TeacherActions.loadGetAllTeacherNumberAssessmentsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        this.numberAssessments = response.data;
        if (response.data.length > 4) {
          this.allCount = response.data[5];
        }
        this.generatePercentageAndSetBarValues();
      }
    })

    action$?.pipe(ofType(TeacherActions.updateTeacherTotalRatingsSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.store.dispatch(TeacherActions.getTeacherTotalRatings({ teacherId: this.teacherId }));
    })

    action$?.pipe(ofType(TeacherActions.loadGetTeacherTextAssessmentsByIdSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.length > 0) {
        this.textAssessments = response.data;
      }
    })

    action$?.pipe(ofType(TeacherActions.getTeacherTotalRatingsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        if (response.data.totalReviewRating) {
          this.summaryRating = response.data.totalReviewRating;
        } else {
          this.summaryRating = 0;
        }
      }
    })
  }

  ngOnInit(): void {
    this.getTeacherIdFromQuery();
    this.subscriptionsInit();
  }

  getTeacherIdFromQuery() {
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      });
  }

  subscriptionsInit() {
    this.store.dispatch(TeacherActions.loadGetTeacherTextAssessmentsById({ teacherId: this.teacherId }));
    this.store.dispatch(TeacherActions.loadGetAllTeacherNumberAssessments({ teacherId: this.teacherId }));
    this.store.dispatch(TeacherActions.getTeacherTotalRatings({ teacherId: this.teacherId }));
  }

  generatePercentageAndSetBarValues() {
    for (let index = 0; index < 5; index++) {
      let percentageValue = ((this.numberAssessments[index] / this.allCount) * 100).toFixed(2);
      this.barStylings[index] = "width: " + percentageValue + '%' + "; height: 15px; background-color: rgb(42, 157, 143)"
    }
  }
}
