import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../base-component/base-component.component';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as EventActions from '../../../shared/services/event/event-store/event.action';

@Component({
  selector: 'days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.css']
})
export class DaysComponent extends BaseComponent implements OnInit, OnDestroy {
  mondaySelected = false;
  tuesdaySelected = false;
  wednesdaySelected = false;
  thursdaySelected = false;
  fridaySelected = false;
  saturdaySelected = false;
  sundaySelected = false;
  days: number[] = [];
  emitterResult = new daysEventEmitterModel();
  @Output() newItemClicked = new EventEmitter<daysEventEmitterModel>();

  constructor(
    override store: Store,
    override action$?: Actions
  ) {
    super(store, undefined, action$)
    action$?.pipe(ofType(EventActions.getConnectedEventsToUpdateSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.initDaysFromStorage();
    })
  }

  ngOnInit(): void {
    this.initDaysFromStorage();
  }

  initDaysFromStorage() {
    const dayNumber = JSON.parse(localStorage.getItem('dayNumbersFromCreateEvent') as string) as number[];

    if (dayNumber && dayNumber.length > 0) {
      this.checkDayNumbers(dayNumber);
      this.fillDaysArray(dayNumber);
    }
  }

  fillDaysArray(dayNumbers: number[]) {
    dayNumbers.forEach(x => {
      if (!this.days.find(y => x === y)) {
        this.days.push(x);
      }
    })
    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  checkDayNumbers(dayNumbers: number[]) {
    dayNumbers.forEach(x => {
      switch (x) {
        case 1:
          this.mondaySelected = true;
          break;
        case 2:
          this.tuesdaySelected = true;
          break;
        case 3:
          this.wednesdaySelected = true;
          break;
        case 4:
          this.thursdaySelected = true;
          break;
        case 5:
          this.fridaySelected = true;
          break;
        case 6:
          this.saturdaySelected = true;
          break;
        case 7:
          this.sundaySelected = true;
          break;
      }
    });
  }

  mondayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.mondaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.mondaySelected = !this.mondaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  tuesdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.tuesdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.tuesdaySelected = !this.tuesdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  wednesdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.wednesdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.wednesdaySelected = !this.wednesdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  thursdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.thursdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.thursdaySelected = !this.thursdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  fridayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.fridaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.fridaySelected = !this.fridaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  saturdayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.saturdaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.saturdaySelected = !this.saturdaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }

  sundayClicked(value: number) {
    if (!this.days.find(x => x === value)) {
      this.days.push(value);
    }
    if (this.sundaySelected === true) {
      let idx = this.days.findIndex(x => x === value);
      if (idx > -1) {
        this.days.splice(idx, 1);
      }
    }
    this.sundaySelected = !this.sundaySelected;

    this.emitterResult.days = this.days;
    this.newItemClicked.emit(this.emitterResult);
  }
}

export class daysEventEmitterModel {
  days: number[] = [];
}
