import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base-component/base-component.component';
import { Store } from '@ngrx/store';
import { TOAST_STATE, ToastService } from '../../../../shared/services/toast/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import * as NewsletterActions from '../../../../shared/services/news-letter/news-letter-store/news-letter.action';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { emailValidator } from '../../../../shared/validators/email-validator';

@Component({
  selector: 'newsletter-subscription',
  templateUrl: './newsletter-subscription.component.html',
  styleUrl: './newsletter-subscription.component.css'
})
export class NewsletterSubscriptionComponent extends BaseComponent implements OnInit {
  override form!: FormGroup;

  constructor(
    override toastService: ToastService,
    override store: Store,
    override action$?: Actions,
  ) {
    super(store, toastService, action$)
    action$?.pipe(ofType(NewsletterActions.insertNewsLetterSubscriberSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data > 0) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeres feliratkozás!")
      } else {
        this.toastService.showToast(TOAST_STATE.warning, "Hiba a feliratkozás során!")
      }
    })
  }

  ngOnInit(): void {
    this.formBuilder();
  }

  subscribe() {
    const email = this.form.controls['email'].value;

    if (this.form.valid && email && email !== '') {
      this.store?.dispatch(NewsletterActions.insertNewsLetterSubscriber({ data: email }))
    } else {
      this.toastService.showToast(TOAST_STATE.warning, "Hiba a feliratkozás során!")
    }
  }

  formBuilder() {
    this.form = new FormBuilder().group({
      email: new FormControl(undefined, [Validators.required])
    });
    this.form.addValidators(emailValidator(this.form.controls['email']));
  }
}
