import { EventR } from "../../../../shared/models/event/event.model";
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./event.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as EventActions from '../event-store/event.action';

export const EventAdapter: EntityAdapter<EventR> =
    createEntityAdapter<EventR>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const EventReducer = createReducer(
    initialState,
    on(EventActions.init, () =>
        ({ ...initialState })
    ),
    on(
        EventActions.getEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.CreateEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.CreateEventsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetEventByTeacherSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.DeleteEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.DeleteEventsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetUsersDataByEventIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.UpdateEventIsAcceptedSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.UpdateEventIsDoneSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.UpdateEventIsArchiveSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.DeleteStudentFromEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetEventsByStudentIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.getEventDetailsToJoinEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.checkUserExistOnEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.createAvailableEventsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.getConnectedEventsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.getConnectedEventsToUpdateSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetArchiveEventsTeacherEventsListSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetUnAcceptedEventsTeacherEventsListSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetArchiveEventsByStudentIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetUnAcceptedEventsByStudentIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.JoinEventSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.getEventsToCalendarSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetStudentsEmailsByEventIdsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.GetDeletedEventsDetailsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        EventActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action) {
    return EventReducer(state, aciton);
}