import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, of, Subject, Subscription, takeUntil } from 'rxjs';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { ToastService } from '../../services/toast/toast.service';
import { UserDataR } from '../../services/user/user.model';
import * as fromUser from '../../../shared/services/user/user-store/user.selector';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';
import * as UserActions from '../../../shared/services/user/user-store/user.action';
import { SignalRService } from '../../services/signal-r/signal-r.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { LoaderService } from '../../services/loader/loader.service';
import * as moment from 'moment';

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html'
})
export class BaseComponent implements OnDestroy {
  form!: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  subscription: Subscription = new Subscription();

  constructor(
    protected store?: Store,
    protected toastService?: ToastService,
    protected action$?: Actions,
    protected translocoService?: TranslocoService,
    protected titleService?: Title,
    protected signalService?: SignalRService,
    protected clipBoard?: Clipboard,
    protected loaderService?: LoaderService
  ) {

  }

  protected setTitle(translation: string) {
    this.subscription.add(
      this.translocoService!.selectTranslate(translation).subscribe((value) => {
        this.titleService!.setTitle('Órafoglaló - ' + value)
      })
    )
  }

  protected getTimezone(): string {
    let timezone = moment.tz.guess();

    if (!timezone || !moment.tz.zone(timezone)) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (!timezone || !moment.tz.zone(timezone)) {
      timezone = 'Europe/Budapest';
    }

    return timezone;
  }


  protected getUserDataFromStore(store: Store): UserDataR {
    const userDataObsv = store.select(fromUser.getUserData);
    let userData: UserDataR = {};
    this.subscription.add(
      userDataObsv
        .pipe(takeUntil(this.destroy$), catchError((error) => {
          console.error('Error getting user data from store:', error);
          return of({});
        }))
        .subscribe((response) => {
          if (response !== undefined && response !== null) {
            userData = response;
          } else {
            this.store!.dispatch(UserActions.loadUserData());
          }
        })
    );
    return userData;
  }

  protected getError(control: AbstractControl): string {
    if (!control || !control.errors) { return ''; }
    return Object.keys(control.errors).map((v) => this.translocoService!.translate('validation.error.' + v)).join(', ');
  }

  protected async checkSignalRConnection() {
    if (this.signalService) {
      const state = await this.signalService.checkConnectedState();
      if (state !== true) {
        await this.signalService.startConnection();
        await this.signalService.addJoinEventNotificationListener();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
}
