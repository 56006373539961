import { Component, OnInit } from '@angular/core';
import * as UserActions from '../../../shared/services/user/user-store/user.action';
import { BaseComponent } from '../base-component/base-component.component';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'landing-counts',
  templateUrl: './landing-counts.component.html',
  styleUrl: './landing-counts.component.css'
})

export class LandingCountsComponent extends BaseComponent implements OnInit {
  teacherCount = 0;
  doneLessonsCount = 0;
  lessonsCount = 0;

  constructor(
    override store: Store,
    override action$?: Actions
  ) {
    super(store, undefined, action$);

    action$?.pipe(ofType(UserActions.GetLandingCountsSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        this.teacherCount = Number(response.data.teachersCount);
        this.doneLessonsCount = Number(response.data.doneLessonsCount);
        this.lessonsCount = Number(response.data.lessonsCount);
      }
    })
  }

  ngOnInit(): void {
    this.store.dispatch(UserActions.GetLandingCounts());
  }

}
