import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { GetAllTeacherDocumentsDTO, UpdateTeacherVerifiedDto } from "../../../shared/services/user/user.model";
import { InsertLessonFromTempQ, RequestedLessonListDTO } from "../../models/requested-lesson-list.model";

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    http = inject(HttpClient);

    getAllTeachersDocuments() {
        return this.http.get<GetAllTeacherDocumentsDTO[]>(Config.baseUrl + 'admin/getalldocuments');
    }

    updateTeacherVerify(dto: UpdateTeacherVerifiedDto) {
        return this.http.post<number>(Config.baseUrl + 'admin/updateverified', dto);
    }

    getRequestedLessons(){
        return this.http.get<RequestedLessonListDTO[]>(Config.baseUrl + 'admin/getrequestedlessons');
    }

    insertLessonFromTemp(dto: InsertLessonFromTempQ){
        return this.http.post<number>(Config.baseUrl + 'admin/insertlessonfromtemp', dto);
    }
}