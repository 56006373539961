import { createAction, props } from "@ngrx/store";
import { SendEmailModel } from "../../../../shared/models/email/send-email-to-students.model";
import { filterQ } from "../../../../shared/models/filter/filter.model";
import { GetTeacherByIdDTO, UserDocumentQ } from "../../../../shared/services/user/user.model";
import { LessonInsertQ, RequestedLessonInsertQ } from "../../../../teacher/models/lesson/lesson.model";
import { TeacherAssessmentQ, TeacherAssessmentR } from "../../../../teacher/models/teacher/teacher-assessment.model";
import { TeacherSocialQ, TeacherSocialR } from "../../../../teacher/models/teacher/teacher-social.model";
import { InsertTeacherCallBackDTO, TeacherByFilterDTO, TeacherR, TeacherTotalRatingR } from "../../../../teacher/models/teacher/teacher.model";
import { SeededTeachersCardsModel } from "src/app/shared/models/seeded-teachers-cards/seeded-teacher-cards.model";
import { CreateCalendarEventsDTO, EventR } from "src/app/shared/models/event/event.model";

export const init = createAction(
    '[Teacher/API] Load Teacher',
    props<{ id: number }>()
);

export const loadTeacherAssessments = createAction(
    '[Teacher/API] Load Teacher Assessments',
    props<{id: number}>()
);

export const loadTeacherAssessmentsSuccess = createAction(
    '[Teacher/API] Load Teacher Assessments Success',
    props<{ data: TeacherAssessmentR[] }>()
);

export const loadGetAllTeachersCount = createAction(
    '[Teacher/API] Load Get All Teachers Count'
);

export const loadGetAllTeachersCountSuccess = createAction(
    '[Teacher/API] Load Get All Teachers Count Success',
    props<{ data: number }>()
);

export const loadGetTeachersSuccessfulLessonsNumber = createAction(
    '[Teacher/API] Load Get Teachers SuccessfulLessonsNumber'
);

export const loadGetTeachersSuccessfulLessonsNumberSuccess = createAction(
    '[Teacher/API] Load Get Teachers SuccessfulLessonsNumber Success',
    props<{ data: number }>()
);

export const loadGetTeacherTextAssessmentsById = createAction(
    '[Teacher/API] Load Get Teacher Text Assessments By Id',
    props<{ teacherId: number }>()
);

export const loadGetTeacherTextAssessmentsByIdSuccess = createAction(
    '[Teacher/API] Load Get Teacher Text Assessments By Id Success',
    props<{ data: TeacherAssessmentR[] }>()
);

export const loadGetTeacherById = createAction(
    '[Teacher/API] Load Get Teacher By Id',
    props<{ teacherId: number }>()
);

export const loadGetTeacherByIdSuccess = createAction(
    '[Teacher/API] Load Get Teacher By Id Success',
    props<{ data: GetTeacherByIdDTO }>()
);

export const loadGetTeacherByFilters = createAction(
    '[Teacher/API] Load Get Teacher By Filters',
    props<{ filter: filterQ }>()
);

export const loadGetTeacherByFiltersSuccess = createAction(
    '[Teacher/API] Load Get Teacher By Filters Success',
    props<{ data: TeacherByFilterDTO[] }>()
);

export const loadGetAllTeacherNumberAssessments = createAction(
    '[Teacher/API] Load Get All Teacher Number Assessments',
    props<{ teacherId: number }>()
);

export const loadGetAllTeacherNumberAssessmentsSuccess = createAction(
    '[Teacher/API] Load Get All Teacher Number Assessments Success',
    props<{ data: number[] }>()
);

export const insertTeacherAssessment = createAction(
    '[Teacher/API] Load Insert Teacher Assessment',
    props<{ teacherAssessment: TeacherAssessmentQ }>()
);

export const insertTeacherAssessmentSuccess = createAction(
    '[Teacher/API] Load Insert Teacher Assessment Success',
    props<{ data: TeacherAssessmentR }>()
);

export const insertTeacherSocials = createAction(
    '[Teacher/API] Load Insert Teacher Socials',
    props<{ teacherSocials: TeacherSocialQ }>()
);

export const insertTeacherSocialsSuccess = createAction(
    '[Teacher/API] Load Insert Teacher Socials Success',
    props<{ data: TeacherSocialR }>()
);

export const insertTeacherLessons = createAction(
    '[Teacher/API] Load Insert Teacher Lessons',
    props<{ data: LessonInsertQ[] }>()
);

export const insertTeacherLessonsSuccess = createAction(
    '[Teacher/API] Load Insert Teacher Lessons Success',
    props<{ data: number }>()
);

export const insertTeacherDocuments = createAction(
    '[Teacher/API] Load Insert Teacher Document',
    props<{ documents: UserDocumentQ }>()
);

export const insertTeacherDocumentsSuccess = createAction(
    '[Teacher/API] Load Insert Teacher Document Success',
    props<{ data: number }>()
);

export const getTeacherSocials = createAction(
    '[Teacher/API] Load Get Teacher Socials',
    props<{ teacherId?: number }>()
);

export const getTeacherSocialsSuccess = createAction(
    '[Teacher/API] Load Get Teacher Socials Success',
    props<{ data: TeacherSocialR }>()
);

export const updateTeacherTotalRatings = createAction(
    '[Teacher/API] Update Teacher Total Ratings',
    props<{ teacherId: number }>()
);

export const updateTeacherTotalRatingsSuccess = createAction(
    '[Teacher/API] Update Teacher Total Ratings Success',
    props<{ data: number }>()
);

export const getTeacherTotalRatings = createAction(
    '[Teacher/API] Get Teacher Total Ratings',
    props<{ teacherId: number }>()
);

export const getTeacherTotalRatingsSuccess = createAction(
    '[Teacher/API] Get Teacher Total Ratings Success',
    props<{ data: TeacherTotalRatingR }>()
);

export const sendEmailToAddresses = createAction(
    '[Teacher/API] Send Email To Students',
    props<{ data: SendEmailModel }>()
);

export const sendEmailToAddressesSuccess = createAction(
    '[Teacher/API] Send Email To Students Success',
    props<{ data: number }>()
);

export const sendEmailById = createAction(
    '[Teacher/API] Send Email By Id',
    props<{ data: SendEmailModel }>()
);

export const sendEmailByIdSuccess = createAction(
    '[Teacher/API] Send Email By Id Success',
    props<{ data: number }>()
);

export const insertTeacherLessonRequests = createAction(
    '[Teacher/API] Insert Teacher Lesson Requests',
    props<{ data: RequestedLessonInsertQ }>()
);

export const insertTeacherLessonRequestsSuccess = createAction(
    '[Teacher/API] Insert Teacher Lesson Requests Success',
    props<{ data: number }>()
);

export const getSeededTeachers = createAction(
    '[Teacher/API] Get Seeded Teachers'
);

export const getSeededTeachersSuccess = createAction(
    '[Teacher/API] Get Seeded Teachers Success',
    props<{ data: SeededTeachersCardsModel[] }>()
);

export const createCalendarEventAsync = createAction(
    '[Teacher/API] Create Calendar Event',
    props<{ data: CreateCalendarEventsDTO }>()
);

export const createCalendarEventAsyncSuccess = createAction(
    '[Teacher/API] Create Calendar Event Success',
    props<{ data: number }>()
);

export const getTeacherCallBackCount = createAction(
    '[Teacher/API] Get Teacher Callback Count',
    props<{ data: InsertTeacherCallBackDTO }>()
);

export const getTeacherCallBackCountSuccess = createAction(
    '[Teacher/API] Get Teacher Callback Count Success',
    props<{ data: number }>()
);

export const insertTeacherCallBack = createAction(
    '[Teacher/API] Insert Teacher Callback',
    props<{ data: InsertTeacherCallBackDTO }>()
);

export const insertTeacherCallBackSuccess = createAction(
    '[Teacher/API] Insert Teacher Callback Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Teacher/API] Load Teachers Failure',
    props<{ error: any }>()
);