<footer class="bg-dark-green border-t border-white text-center text-white lg:text-left">
  <div
    class="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-neutral-500 lg:justify-between">
    <div class="mr-12 hidden lg:block">
      <span>{{'shared.footer.search_us' | transloco}}</span>
    </div>
    <!-- Social network icons container -->
    <div class="flex justify-center">
      <a href="{{facebookLink}}" target="_blank" role="button">
        <img src="../../../../assets/images/social-form/facebook.svg" class="btn-icon w-8 h-8" loading="lazy" alt="facebook">
      </a>
      <a href="{{instagramLink}}" target="_blank" role="button">
        <img src="../../../../assets/images/social-form/insta.svg" class="btn-icon w-8 h-8" loading="lazy" alt="insta">
      </a>
      <a href="{{tiktokLink}}" target="_blank" role="button">
        <img src="../../../../assets/images/social-form/tiktok.svg" class="btn-icon w-8 h-8" loading="lazy" alt="tiktok">
      </a>
      <a href="{{youtubeLink}}" target="_blank" role="button">
        <img src="../../../../assets/images/social-form/youtube.svg" class="btn-icon w-8 h-8" loading="lazy" alt="youtube">
      </a>
    </div>
  </div>

  <!-- Main container div: holds the entire content of the footer, including four sections (TW elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. -->
  <div class="mx-6 py-10 text-center md:text-left">
    <div class="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
      <!-- TW elements section -->
      <div class="">
        <h6 class="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
          <img class="h-16 w-auto" src="./assets/images/orafoglalo_logo.png" alt="Órafoglaló" loading="lazy">
        </h6>
      </div>
      <!-- Products section  (bal oldali középső oszlop)-->
      <div class="">

      </div>
      <!-- Useful links section (jobb oldali középső oszlop)-->
      <div class="">

      </div>
      <!-- Contact section -->
      <div>
        <h6 class="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          {{'shared.footer.contacts' | transloco}}
        </h6>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <img src="../../../../assets/images/common/house.svg" class="mr-3 h-5 w-5" loading="lazy" alt="house">

          {{'shared.footer.hungary' | transloco}}
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <img src="../../../../assets/images/common/email.svg" class="mr-3 h-5 w-5" loading="lazy" alt="email">
          info&#64;orafoglalo.hu
        </p>
      </div>
    </div>
  </div>
  <div class="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
    <span>© 2024 Copyright: Órafoglaló</span>
    <a class="font-semibold text-neutral-600 dark:text-neutral-400" href=""></a>
  </div>
</footer>