<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="star-rating inline-block">
  @if(fullStarsArray){
    @for(_ of fullStarsArray; track $index){
    <span class="star fa fa-star"></span>
    }
  }
  @if(hasHalfStar){
    <span class="star fa fa-star-half"></span>
  }
  @if(emptyStarsArray){
    @for(_ of emptyStarsArray; track $index){
    <span class="star fa fa-star-o"></span>
    }
  }
</div>