import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailValidator(control: AbstractControl): ValidatorFn {
  return (): ValidationErrors | null => {
    const email = control.value;
    if(email && email.length>0){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const valid = emailRegex.test(email);
      return valid ? null : { invalidEmail: true };
    }else{
      return  { invalidEmail: false };
    }
  };
}