import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, of, take, takeUntil } from 'rxjs';
import { EventR, EventScheduleR, EventsToTimeTableDTO } from '../../../shared/models/event/event.model';
import * as fromLesson from '../../../teacher/services/lesson/lesson-store/lesson.selector';
import * as EventActions from '../../../shared/services/event/event-store/event.action';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { FormControl, FormGroup } from '@angular/forms';
import { TOAST_STATE, ToastService } from '../../../shared/services/toast/toast.service';
import { LessonR } from '../../models/lesson/lesson.model';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { LessonIdEnum } from '../../../shared/enums/lessond-ids.enum';
import { UserDataR } from '../../../shared/services/user/user.model';
import { LocationTypeEnum } from 'src/app/shared/enums/location-types.enum';
import { RoleEnum } from 'src/app/shared/enums/roles.enum';
import { CreateEventByStudentModal } from 'src/app/student/modals/create-event-by-student/create-event-by-student-modal.component';
import * as UserActions from '../../../shared/services/user/user-store/user.action';
import * as fromUser from '../../../shared/services/user/user-store/user.selector';

@Component({
  selector: 'timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.css']
})
export class TimetableComponent extends BaseComponent implements OnInit, OnDestroy {
  override form: FormGroup = new FormGroup({});
  createEventModalRef: MatDialogRef<CreateEventByStudentModal, boolean> | undefined = undefined;
  startDate!: Date;
  endDate!: Date;
  weekDays: Date[] = [];
  lessons: LessonR[] = [];
  events: EventsToTimeTableDTO[] = [];
  mondayEvents: EventsToTimeTableDTO[] = [];
  tuesdayEvents: EventsToTimeTableDTO[] = [];
  wednesdayEvents: EventsToTimeTableDTO[] = [];
  thursdayEvents: EventsToTimeTableDTO[] = [];
  fridayEvents: EventsToTimeTableDTO[] = [];
  saturdayEvents: EventsToTimeTableDTO[] = [];
  sundayEvents: EventsToTimeTableDTO[] = [];
  eventScheduleR: EventScheduleR = {};
  selectedLessonId!: number;
  teacherId = 0;
  allTimeZones = moment.tz.names();
  actualTimeZone = 'Europe/Budapest';
  actualDate!: Date;
  availableLessonId = LessonIdEnum.AVAILABLE;
  phoneLocationType = LocationTypeEnum.PHONE;
  allLessonsId = LessonIdEnum.ALL_LESSONS;
  userData!: UserDataR;

  constructor(
    override store: Store,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    override toastService: ToastService,
    override action$?: Actions
  ) {

    super(store, toastService, action$);
    action$?.pipe(ofType(EventActions.CreateEventSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeresen mentettük az adataid.");

      }
    });

    action$?.pipe(ofType(EventActions.getEventsByScheduleSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      this.clearEvents();
      if (response.data && response.data.length > 0) {
        this.events = response.data;
        this.events.forEach(x =>
          this.fillEventsMap(x.dayOfWeek, x, this.actualTimeZone)
        )
      }

    });

    action$?.pipe(ofType(UserActions.loadUserDataSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data) {
        this.userData = response.data;
      }
    })
  }

  ngOnInit(): void {
    this.actualTimeZone = this.getTimezone();
    this.actualDate = new Date(moment.tz(this.actualTimeZone).format('YYYY-MM-DD'));
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      })

    this.form.addControl('timeZone', new FormControl<string | null>(this.actualTimeZone));

    this.form.controls['timeZone'].valueChanges.subscribe(x => {
      this.actualTimeZone = x;
      this.changeTimeZone(x)
    }
    );
    this.store.dispatch(UserActions.loadUserData());
    this.getStartAndEndDaysOfWeek();
    this.generateDaysOfActualWeek();
    this.checkLessonData();
    this.checkUserData();
  }

  checkLessonData(): void {
    const lessonData = this.store.select(fromLesson.getLessonsByTeacherId);

    lessonData
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response != null && response !== undefined) {
          this.lessons = response as unknown as LessonR[];
          if (this.lessons && this.lessons.length > 0) {
            this.selectedLessonId = -2;
          }
          this.eventScheduleR = JSON.parse(JSON.stringify(this.eventScheduleR));
          this.eventScheduleR.weekStartDate = this.startDate;
          this.eventScheduleR.weekendDate = this.endDate;
          this.eventScheduleR.lessonId = this.selectedLessonId;
          this.eventScheduleR.teacherId = this.teacherId;

          this.store.dispatch(EventActions.getEventsBySchedule({ data: this.eventScheduleR }));
        }
      })
  }

  checkUserData(): void {
    if (!this.userData) {
      const userDataObsv = this.store.select(fromUser.getUserData);
      this.subscription.add(
        userDataObsv
          .pipe(takeUntil(this.destroy$), catchError((error) => {
            console.error('Error getting user data from store:', error);
            return of({});
          }))
          .subscribe((response) => {
            if (response !== undefined && response !== null) {
              this.userData = response;
            }
          })
      );
    }
  }

  getStartAndEndDaysOfWeek() {
    var now = moment();
    this.startDate = new Date(now.clone().weekday(1).format('YYYY-MM-DD'));
    this.endDate = new Date(now.clone().weekday(7).format('YYYY-MM-DD'));
  }

  lessonChange(lessonId: string) {
    this.selectedLessonId = Number(lessonId);

    if ((this.selectedLessonId !== undefined && this.selectedLessonId > 0 && this.selectedLessonId !== null) || this.selectedLessonId === -2) {
      this.eventScheduleR = JSON.parse(JSON.stringify(this.eventScheduleR));

      this.eventScheduleR.weekStartDate = this.startDate;
      this.eventScheduleR.weekendDate = this.endDate;
      this.eventScheduleR.lessonId = this.selectedLessonId;
      this.eventScheduleR.teacherId = this.teacherId;

      this.store.dispatch(EventActions.getEventsBySchedule({ data: this.eventScheduleR }));
    }
  }

  previousWeek() {
    this.startDate = new Date(this.startDate);
    this.endDate = new Date(this.endDate);

    this.startDate.setDate(this.startDate.getDate() - 7);
    this.endDate.setDate(this.endDate.getDate() - 7);

    this.getEventsToAcutalDate();
    this.changeTimeZone(this.actualTimeZone);
  }

  nextWeek() {
    this.startDate = new Date(this.startDate);
    this.endDate = new Date(this.endDate);

    this.startDate.setDate(this.startDate.getDate() + 7);
    this.endDate.setDate(this.endDate.getDate() + 7);

    this.getEventsToAcutalDate();
    this.changeTimeZone(this.actualTimeZone);
  }

  getEventsToAcutalDate() {
    this.generateDaysOfActualWeek();
    this.eventScheduleR = JSON.parse(JSON.stringify(this.eventScheduleR));
    this.eventScheduleR.weekStartDate = this.startDate;
    this.eventScheduleR.weekendDate = this.endDate;
    this.eventScheduleR.lessonId = this.selectedLessonId;
    this.eventScheduleR.teacherId = this.teacherId;

    this.store.dispatch(EventActions.getEventsBySchedule({ data: this.eventScheduleR }));
  }

  fillEventsMap(dayOfWeek: number, event: EventR, timeZone: string) {
    let tempEventR = JSON.parse(JSON.stringify(event));
    let actualDate = moment.tz(event.eventStartDate, event.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
    if (actualDate) {
      tempEventR.eventStartDate = actualDate;
    }
    switch (dayOfWeek) {
      case 1:
        this.sundayEvents.push(tempEventR);
        break;
      case 2:
        this.mondayEvents.push(tempEventR);
        break;
      case 3:
        this.tuesdayEvents.push(tempEventR);
        break;
      case 4:
        this.wednesdayEvents.push(tempEventR);
        break;
      case 5:
        this.thursdayEvents.push(tempEventR);
        break;
      case 6:
        this.fridayEvents.push(tempEventR);
        break;
      case 7:
        this.saturdayEvents.push(tempEventR);
        break;
      default:
        break;
    }
  }

  changeTimeZone(timeZone: string) {
    let eventsTemp: EventR[] = [];
    let eventRTemp!: EventR;
    if (this.mondayEvents && this.mondayEvents.length > 0) {
      eventsTemp = [];
      this.mondayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.mondayEvents = eventsTemp;
    }

    if (this.tuesdayEvents && this.tuesdayEvents.length > 0) {
      eventsTemp = [];
      this.tuesdayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.tuesdayEvents = eventsTemp;
    }

    if (this.wednesdayEvents && this.wednesdayEvents.length > 0) {
      eventsTemp = [];
      this.wednesdayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.wednesdayEvents = eventsTemp;
    }

    if (this.thursdayEvents && this.thursdayEvents.length > 0) {
      eventsTemp = [];
      let eventRTemp!: EventR;
      this.thursdayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.thursdayEvents = eventsTemp;
    }

    if (this.fridayEvents && this.fridayEvents.length > 0) {
      eventsTemp = [];
      this.fridayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.fridayEvents = eventsTemp;
    }

    if (this.saturdayEvents && this.saturdayEvents.length > 0) {
      eventsTemp = [];
      this.saturdayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.saturdayEvents = eventsTemp;
    }

    if (this.sundayEvents && this.sundayEvents.length > 0) {
      eventsTemp = [];
      this.sundayEvents.forEach(x => {
        eventRTemp = JSON.parse(JSON.stringify(x));
        eventRTemp.eventStartDate = moment.tz(x.eventStartDate, x.timeZone).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') as unknown as Date;
        eventRTemp.timeZone = timeZone;
        eventsTemp.push(eventRTemp);
      })
      this.sundayEvents = eventsTemp;
    }
  }

  generateDaysOfActualWeek() {
    let startDate = new Date(this.startDate);
    this.weekDays = [];

    for (let index = 0; index < 7; index++) {
      this.weekDays.push(new Date(moment(startDate).add(index, 'days').format('YYYY-MM-DD HH:mm:ss')))
    }
  }

  clearEvents() {
    this.mondayEvents = [];
    this.tuesdayEvents = [];
    this.wednesdayEvents = [];
    this.thursdayEvents = [];
    this.fridayEvents = [];
    this.saturdayEvents = [];
    this.sundayEvents = [];
  }

  askSchedule(event?: EventR) {
    if (this.userData && this.userData.roleId) {
      this.openCreateEventModal(event);
    } else {
      this.toastService.showToast(TOAST_STATE.warning, "Kérjük jelentkezz be a funkció használatához!");
    }
  }

  openCreateEventModal(event?: EventR) {
    this.createEventModalRef = this.dialog
      .open(CreateEventByStudentModal, {
        panelClass: 'resizable-mat-dialog-panel',
        data: {
          event: event
        }
      });

    this.createEventModalRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirmed) => {
        if (confirmed && confirmed === true) {
          this.getEventsToAcutalDate();
          this.toastService.showToast(TOAST_STATE.warning, "Órakérésed sikeresen elküldtük. Email küldés folyamatban...");
        }
        this.createEventModalRef = undefined;
      });
  }

  eventDateClicked(event: EventsToTimeTableDTO) {
    if (event.lessonId !== LessonIdEnum.AVAILABLE) {
      if (this.userData && this.userData.roleId?.toString() === RoleEnum.STUDENT) {
        this.router.navigate(['/student/join-event'], { queryParams: { eventId: event.eventId } });
      } else {
        this.toastService.showToast(TOAST_STATE.warning, "Kérjük jelentkezz be a funkció használatához!");
      }
    } else {
      this.askSchedule(event);
    }
  }

  checkToday(date: Date) {
    return moment(date).format('YYYY-MM-DD') === moment(this.actualDate).format('YYYY-MM-DD');
  }
}
