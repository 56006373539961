<app-spinner></app-spinner>
<app-accept-cookies></app-accept-cookies>
<toast></toast>
<div id="page-container">
  <div class="header sticky top-0 z-10">
    <header></header>
  </div>
  <div class="items-center justify-center max-w-[95%]">
    <app-stepper></app-stepper>
  </div>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
  <div id="footer">
    <app-footer></app-footer>
  </div>
</div>