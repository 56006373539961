import { Component } from '@angular/core';
import { BaseComponent } from '../base-component/base-component.component';
import { Step, TeacherStep, TeacherStepR } from '../../models/step/step.model';
import * as StepActions from '../../services/step/step-store/step.action';
import * as UserActions from '../../../shared/services/user/user-store/user.action';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserDataR } from '../../services/user/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.css'
})
export class StepperComponent extends BaseComponent {
  currentStep = 1;
  allSteps: Step[] = [];
  teahcerSteps: TeacherStep[] = [];
  ownTeacherSteps: TeacherStepR[] = [];
  stepperDisplay = 'hidden';
  userData: UserDataR = {
    profilePicture: '',
    about: '',
    professionalExperience: ''
  };

  constructor(
    public route: Router,
    override store: Store,
    override action$?: Actions
  ) {
    super(store, undefined, action$)

    action$?.pipe(ofType(StepActions.loadGetStepsByRoleSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined && response.data.length > 0) {
        if (this.userData.isFirstLogin === true) {
          this.allSteps = response.data;
          this.fillTeacherSteps();
          this.store.dispatch(StepActions.loadInsertTeacherSteps({ data: this.teahcerSteps }));
        }
        else {
          this.store.dispatch(StepActions.loadGetTeacherSteps());
        }
      }
    })

    action$?.pipe(ofType(StepActions.loadInsertTeacherStepsSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.store.dispatch(UserActions.UpdateUserIsFirstLogin({ data: false }));
    })

    action$?.pipe(ofType(UserActions.UpdateUserIsFirstLoginSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.store.dispatch(StepActions.loadGetTeacherSteps());
    })

    action$?.pipe(ofType(UserActions.UpdateUserFirstStepsDoneSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.store.dispatch(UserActions.loadUserData());
    })

    action$?.pipe(ofType(StepActions.loadGetTeacherStepsSuccess), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.data && res.data.length > 0) {
        this.ownTeacherSteps = res.data;
        const isFirstStepsDone = this.checkAllStepsDone();
        if (isFirstStepsDone === true) {
          this.store.dispatch(UserActions.UpdateUserFirstStepsDone({ data: true }));
        }
      }
    })

    action$?.pipe(ofType(UserActions.loadUserDataSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.userData = response.data;
        if (this.userData.isFirstStepsDone === true) {
          this.stepperDisplay = 'hidden';
        } else if (this.userData.isFirstStepsDone === false) {
          this.stepperDisplay = 'flex';
          this.store.dispatch(StepActions.loadGetStepsByRole({ data: this.userData.roleId as unknown as number }));
        }
      }
    })

    action$?.pipe(ofType(StepActions.loadUpdateTeacherStepSuccess), takeUntil(this.destroy$)).subscribe(() => {
      this.store.dispatch(StepActions.loadGetTeacherSteps());
    })

  }

  fillTeacherSteps() {
    this.allSteps.forEach(x => {
      this.teahcerSteps.push({
        stepId: x.stepId,
        stepIsDone: false,
      })
    })
  }

  getAllStepsCount(): number {
    if (this.ownTeacherSteps && this.ownTeacherSteps.length > 0) {
      return this.ownTeacherSteps.length;
    }
    else {
      return 0;
    }
  }

  getDoneStepsCount(): number {
    let doneSteps = 0;
    if (this.ownTeacherSteps && this.ownTeacherSteps.length > 0) {
      this.ownTeacherSteps.forEach(x => {
        if (x.stepIsDone === true) {
          doneSteps++;
        }
      })
    }
    return doneSteps;
  }

  checkAllStepsDone(): boolean {
    if (this.ownTeacherSteps.find(x => x.stepIsDone === false)) {
      return false;
    }
    return true;
  }

  stepClicked(stepUrl?: string, currentStep?: number) {
    this.currentStep = currentStep as number;
    this.route.navigateByUrl(stepUrl as string);
  }
}
