<dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
    <div class="mx-auto flex max-w-xs flex-col gap-y-4">
      <dt class="text-base leading-7 text-dark-green">      {{'landing.counts.registered_teacher' | transloco}}    </dt>
      <dd class="order-first text-3xl font-semibold tracking-tight text-dark-green sm:text-5xl">{{teacherCount}}
      </dd>
    </div>
    <div class="mx-auto flex max-w-xs flex-col gap-y-4">
      <dt class="text-base leading-7 text-dark-green"> {{'landing.counts.done_lesson' | transloco}} </dt>
      <dd class="order-first text-3xl font-semibold tracking-tight text-dark-green sm:text-5xl">
        73</dd>
    </div>
    <div class="mx-auto flex max-w-xs flex-col gap-y-4">
      <dt class="text-base leading-7 text-dark-green">{{'landing.counts.category_search' | transloco}} </dt>
      <dd class="order-first text-3xl font-semibold tracking-tight text-dark-green sm:text-5xl">több mint
        {{lessonsCount}}</dd>
    </div>
  </dl>