@if(!isDeleted){
<div class="border flex w-full border-dark-green border-1">
  <div class="flex break-all w-8/12">
    <div class="text-dark-green justify-self-start self-center">
      <img src="../../../../assets/images/attachment.svg" class="w-6 h-6" loading="lazy" alt="attachment">
    </div>
    @if(file){
    <div class="text-dark-green ml-2 font-semibold justify-self-start self-center">
      @if(file.name.length < 15){ 
        <label>{{file.name}}</label>
        }
        @if(file.name.length > 15){
        <label>{{file.name | slice:0:15}}...</label>
        }
    </div>
    }
  </div>
  <div class="justify-self-center text-center w-3/12 self-center break-all">
    @if(file && file.size>0){
    <label> {{file.size}} KB</label>
    }
  </div>
  <div class="text-dark-green w-1/12  justify-self-center self-center text-center">
    <button (click)="xButtonClick()" type="button">
      <img src="../../../../assets/images/x.svg" class="w-4 h-4" loading="lazy" alt="x">
    </button>
  </div>
</div>
}