import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numberValidator(control: AbstractControl): ValidatorFn {
  return (): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasNumber = /\d/.test(value);

    return hasNumber ? null : { noNumber: true };
  };
}