import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { Step, TeacherStep, TeacherStepR, UpdateTeacherStepQ } from "../../models/step/step.model";

@Injectable({
    providedIn: 'root'
})
export class StepService {

    constructor(private http: HttpClient) {

    }

    getStepsByRole(roleId:number) {
        return this.http.post<Step[]>(Config.baseUrl + 'step/getbyroleid',roleId);
    }

    getTeacherStepsByUser() {
        return this.http.get<TeacherStepR[]>(Config.baseUrl + 'step/getbyuser');
    }

    insertTeacherSteps(teacherSteps: TeacherStep[]) {
        return this.http.post<number>(Config.baseUrl + 'step/insert',teacherSteps);
    }

    updateTeacherStep(teacherStep: UpdateTeacherStepQ) {
        return this.http.post<number>(Config.baseUrl + 'step/updateteacherstep',teacherStep);
    }
}