
export class filterQ {
   lessonIds?: number[];
   minPrice?: string;
   maxPrice?: string;
   locationTypeIds?: number[];
   locationNames?: string[];
   daysNumbers?: number[];
   availableHours?: TimesModel[];
   sort?: number;
   lessonTypeIds?: number[];

   constructor() {
      this.lessonIds = [];
      this.lessonTypeIds = [];
      this.minPrice = '';
      this.maxPrice = '';
      this.locationTypeIds = [];
      this.locationNames = [];
      this.daysNumbers = [];
      this.availableHours = [];
   }
}

export interface TimesModel {
   startHour?: number;
   endHour?: number;
}