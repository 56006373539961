import {
  provideTransloco,
  TRANSLOCO_LOADER,
  TranslocoModule
} from '@ngneat/transloco';
import { isDevMode, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';


@NgModule({
  exports: [ TranslocoModule ],
  providers: [
      provideTransloco({
        config: {
          availableLangs: ['en','hu','de'],
          defaultLang: 'hu',
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
        },
        loader: TranslocoHttpLoader
      }),
      { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ],
})
export class TranslocoRootModule {}
