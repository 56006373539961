import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as NotificationActions from './notification.action';
import { catchError, concatMap, map, of } from "rxjs";
import { NotificationService } from "../notification-service";


@Injectable()
export class NotificationEffects {

    loadNotificationsByUserId$ = createEffect(() => {
        return this.action$.pipe(
            ofType(NotificationActions.loadNotificationsByUserId),
            concatMap((action) => this.notificationService.getNotificationByUserId(action.data).pipe(
                map((data) => {
                    return NotificationActions.loadNotificationsByUserIdSuccess({ data });
                }),
                catchError(() => of(NotificationActions.failure({ error: "Load notifications failure" })))
            )
            )
        );
    });

    insertNotification$ = createEffect(() => {
        return this.action$.pipe(
            ofType(NotificationActions.InsertNotification),
            concatMap((action) => this.notificationService.insertNotification(action.notification).pipe(
                map((data) => {
                    return NotificationActions.InsertNotificationSuccess({ data });
                }),
                catchError(() => of(NotificationActions.failure({ error: "Load notifications failure" })))
            )
            )
        );
    });

    updateNotification$ = createEffect(() => {
        return this.action$.pipe(
            ofType(NotificationActions.UpdateNotification),
            concatMap((action) => this.notificationService.updateNotificationIsNew(action.data).pipe(
                map((data) => {
                    return NotificationActions.UpdateNotificationSuccess({ data });
                }),
                catchError(() => of(NotificationActions.failure({ error: "Load notifications failure" })))
            )
            )
        );
    });

    getNotificationsCount$ = createEffect(() => {
        return this.action$.pipe(
            ofType(NotificationActions.loadNotificationsCountByUserId),
            concatMap((action) => this.notificationService.getNewNotificationsCountByUserId().pipe(
                map((data) => {
                    return NotificationActions.loadNotificationsCountByUserIdSuccess({ data });
                }),
                catchError(() => of(NotificationActions.failure({ error: "Load notifications failure" })))
            )
            )
        );
    });


    constructor(
        private action$: Actions,
        private notificationService: NotificationService
    ) { }
}