<div class="bg-white p-5 sm-modal cursor-move" cdkDragRootElement=".cdk-overlay-pane"
  cdkDrag cdkDragHandle>
  <div>
    <h4>
      {{ data.title | transloco }}
    </h4>
  </div>

  <div class="modal-content">
    <form [formGroup]="form" (ngSubmit)="bookingClicked()">
      <form-title [title]="'teacher.calendar.event.title' | transloco"></form-title>
      <div class="flex flex-wrap lg:justify-around">
        <div class="w-full">
          <div class="mt-2" [class.error]="form.controls['eventName'].invalid && form.controls['eventName'].touched"
            [title]="form.controls['eventName'] | formError">
            <label for="eventName" class="label">{{ 'teacher.calendar.event.eventName' | transloco }}</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <input type="text" name="eventName" id="eventName" formControlName="eventName" class="input-text"
                placeholder="{{ 'teacher.calendar.event.eventNameNote' | transloco }}">
                @if(form.controls['eventName'].touched){
                  <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                    getError(form.controls['eventName'])
                    }}</span>
                }
            </div>
          </div>

          <div class="mt-2" [class.error]="form.controls['lessonId'].invalid && form.controls['lessonId'].touched">
            <label for="lessonId" class="label">{{ 'teacher.calendar.event.lesson' | transloco }}</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <select formControlName="lessonId" id="lessonId" class="select">
                @for (lesson of lessons; track $index) {
                <option value="{{lesson.lessonId}}">
                  {{lesson.lessonName}}
                </option>
                }
                @empty {
                <option value="-1">{{ 'teacher.calendar.event.noLesson' | transloco }}</option>
                }
                @if(data.event && data.event.lessonId !== availableLessonId){
                  <option [value]="availableLessonId">
                    {{ 'teacher.calendar.event.available' | transloco }}
                  </option>
                }
              </select>
              @if(form.controls['lessonId'].touched){
                <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                  getError(form.controls['lessonId'])
                  }}</span>
              }
            </div>
          </div>

          <div class="mt-2">
            <div class="me-2" [class.error]="form.controls['clickedDate'].invalid && form.controls['clickedDate'].touched">
              <label for="clickedDate" class="label">{{ 'teacher.calendar.event.clickedDate' | transloco
                }}</label>
              <div class="relative mt-2 rounded-md shadow-sm">
                <input type="date" name="clickedDate" id="clickedDate" formControlName="clickedDate"
                 class="input-text disabled:bg-gray-50 disabled:text-gray-400 disabled:border-gray-200 disabled:shadow-none"
                  placeholder="{{ 'teacher.calendar.event.clickedDate' | transloco }}">
                  @if(form.controls['clickedDate'].touched){
                    <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                      getError(form.controls['clickedDate'])
                      }}</span>
                  }
              </div>
            </div>
            <div [class.error]="form.controls['timeZone'].invalid && form.controls['timeZone'].touched">
              <label for="timeZone" class="label">{{ 'teacher.calendar.event.timeZone' | transloco }}</label>
              <div class="relative mt-2 rounded-md shadow-sm">
                <select w-full formControlName="timeZone" id="timeZone" class="select">
                  @for(option of timeZones; track $index){
                  <option value="{{option}}">
                    {{option}}
                  </option>
                  }
                </select>
                @if(form.controls['timeZone'].touched){
                  <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                    getError(form.controls['timeZone'])
                    }}</span>
                }
              </div>
            </div>
          </div>

          <div class="mt-2" [class.error]="form.controls['startTime'].invalid && form.controls['startTime'].touched">
            <label for="startTime" class="label">{{ 'teacher.calendar.event.startTime' | transloco }}</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="flex justify-center space-x-2">
                <input matInput name="startTime" [format]="24" formControlName="startTime" [ngxMatTimepicker]="pickerA"
                  placeholder="{{ 'teacher.calendar.event.startTimeNote' | transloco }}" readonly class="input-text" />
                <img src="../../../../../assets/images/clock.svg" class="w-8 h-8 cursor-pointer"
                  (click)="pickerA.open()" alt="clock" loading="lazy">
                <ngx-mat-timepicker color="accent" #pickerA></ngx-mat-timepicker>
              </div>
              <div class="absolute end-0 bottom-[-15px] text-red-600 text-xs">
                @if(form.errors?.['StartDateHigher']){
                  <span>
                    {{'validation.error.higher_start_date' | transloco}},
                </span>
                }
                @if(form.controls['startTime'].touched){
                  <span>{{
                    getError(form.controls['startTime'])
                    }}</span>
                }
              </div>
            </div>
          </div>

          <div class="mt-2" [class.error]="form.controls['endTime'].invalid && form.controls['endTime'].touched">
            <label for="endTime" class="label">{{ 'teacher.calendar.event.endTime' | transloco }}</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="flex justify-center space-x-2">
                <input matInput name="endTime" [format]="24" formControlName="endTime" [ngxMatTimepicker]="pickerB"
                  placeholder="{{ 'teacher.calendar.event.endTimeNote' | transloco }}" readonly class="input-text" />
                <img src="../../../../../assets/images/clock.svg" class="w-8 h-8 cursor-pointer"
                  (click)="pickerB.open()" alt="clock" loading="lazy">
                <ngx-mat-timepicker color="accent" #pickerB></ngx-mat-timepicker>
              </div>
              @if(form.controls['endTime'].touched){
                <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                  getError(form.controls['endTime'])
                  }}</span>
              }
            </div>
          </div>

          <div class="mt-2" [class.error]="form.controls['locationTypeId'].invalid && form.controls['locationTypeId'].touched">
            <label for="locationTypeId" class="label">{{ 'teacher.calendar.event.locationType' | transloco
              }}</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <select formControlName="locationTypeId" id="locationTypeId" class="select">
                @if(lessons){
                @for(locationType of locationTypes; track $index){
                <option value="{{locationType.locationTypeId}}">
                  {{locationType.locationTypeName}}
                </option>
                }
                }
              </select>
              @if(form.controls['locationTypeId'].touched){
                <span class="absolute end-0 bottom-[-15px] text-red-600 text-xs">{{
                  getError(form.controls['locationTypeId'])
                  }}</span>
              }
            </div>
          </div>
        </div> <!-- end of column 1 -->
      </div>
    </form>
  </div>

  <div class="mt-6 flex items-center justify-between">
    <button type="submit" [disabled]="!form.valid"  class="btn-primary" (click)="onOk()">{{ 'common.ok' | transloco
      }}</button>
    <button type="button" class="btn-cancel" (click)="onCancel()">{{ 'common.close' | transloco }}</button>
  </div>
</div>

<!-- [disabled]="!form.valid" -->