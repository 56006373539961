import { createAction, props } from "@ngrx/store";
import { LocationTypeR } from "../../../../teacher/models/locations/location-type.model";
import { InsertLocationModelQ } from "../../../../teacher/models/locations/location.model";
import { OfflineLocation, OfflineLocationCityR } from "../../../../teacher/models/locations/offline-location.model";
import { OnlineLocation } from "../../../../teacher/models/locations/online-location.model";
import { OnlinePlatform } from "../../../../teacher/models/locations/platform-model";


export const init = createAction(
    '[Locaiton/API] Load Locaiton',
    props<{ id: number }>()
);

export const getAllLocationTypes = createAction(
    '[Locaiton/API] Load Get All Locations'
);

export const getAllLocationTypesSuccess = createAction(
    '[Locaiton/API] Load Get All Locations Success',
    props<{ data: LocationTypeR[] }>()
);

export const getAllLocationByTypeId = createAction(
    '[Locaiton/API] Load Get All Location By TypeId',
    props<{ locationTypeId: number }>()
);

export const getAllLocationByTypeIdSuccess = createAction(
    '[Locaiton/API] Load Get All Location By TypeId Success',
    props<{ data: OnlineLocation[] | OfflineLocation[] }>()
);

export const getAllOfflineLocations = createAction(
    '[Locaiton/API] Load Get All Offline Locations'
);

export const getAllOfflineLocationsSuccess = createAction(
    '[Locaiton/API] Load Get All Offline Locations Success',
    props<{ data: OfflineLocationCityR[] }>()
);

export const getAllPlatforms = createAction(
    '[Locaiton/API] Load Get All Platforms'
);

export const getAllPlatformsSuccess = createAction(
    '[Locaiton/API] Load Get All Platforms Success',
    props<{ data: OnlinePlatform[] }>()
);

export const insertLocations = createAction(
    '[Locaiton/API] Load Insert Location',
    props<{ data: InsertLocationModelQ }>()
);

export const insertLocationsSuccess = createAction(
    '[Locaiton/API] Load Insert Location Success',
    props<{ data: number }>()
);

export const updateOnlineLocation = createAction(
    '[Locaiton/API] Update Online Location',
    props<{ data: OnlineLocation }>()
);

export const updateOnlineLocationSuccess = createAction(
    '[Locaiton/API] Update Online Location Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Locaiton/API] Load Locaitons Failure',
    props<{ error: any }>()
);

export const updateOfflineLocation = createAction(
    '[Locaiton/API] Update Offline',
    props<{ data: OfflineLocation}>()
);

export const updateOfflineLocationSuccess = createAction(
    '[Locaiton/API] Update Offline Success',
    props<{ data: number }>()
);

export const deleteOnlineLocation = createAction(
    '[Locaiton/API] Delete Online',
    props<{ data: number}>()
);

export const deleteOnlineLocationSuccess = createAction(
    '[Locaiton/API] Delete Online Success',
    props<{ data: number }>()
);

export const deleteOfflineLocation = createAction(
    '[Locaiton/API] Delete Offline',
    props<{ data: number}>()
);

export const deleteOfflineLocationSuccess = createAction(
    '[Locaiton/API] Delete Offline Success',
    props<{ data: number }>()
);

export const getOnlineLocationById = createAction(
    '[Locaiton/API] Get Online Location By Id',
    props<{ data: number}>()
);

export const getOnlineLocationByIdSuccess = createAction(
    '[Locaiton/API] Get Online Location By Id Success',
    props<{ data: OnlineLocation }>()
);

export const getOfflineLocationById = createAction(
    '[Locaiton/API] Get Offline Location By Id',
    props<{ data: number}>()
);

export const getOfflineLocationByIdSuccess = createAction(
    '[Locaiton/API] Get Offline Location By Id Success',
    props<{ data: OfflineLocation }>()
);

