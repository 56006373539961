@if(loader.isLoading$ | async){
<div class="spinner-overlay">
    <div class="spinner-container flex items-center justify-center">
        <div class="flex flex-wrap font-semibold text-dark-green items-center justify-center">
            <span class="animate-spin">
                <img src="../../../../assets/images/clock.svg" class="w-32 h-32" loading="lazy" alt="clock">
            </span>
            @if(loader.message$ | async){
            <span class="text-6xl text-center animate-bounce pr-5 pl-5 pt-5">
                {{ loader.message$ | async}}
            </span>
            <div class="flex">
                <div class='h-4 w-4 bg-dark-green rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                <div class='h-4 w-4 bg-dark-green rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                <div class='h-4 w-4 bg-dark-green rounded-full animate-bounce'></div>
            </div>
            }
        </div>
    </div>
</div>
}