import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TOAST_STATE, ToastService } from '../../services/toast/toast.service';
import { BaseComponent } from '../base-component/base-component.component';
import { ForgotPasswordDTO } from '../../models/auth/forgot-password.model';
import * as AuthActions from '../../../shared/services/auth/authentication-store/authentication.action'
import { takeUntil } from 'rxjs';
import { confirmPasswordValidator } from '../../validators/confirm-password-validator';
import { TranslocoService } from '@ngneat/transloco';
import { SetPasswordDTO } from '../../services/user/user.model';
import { Config } from 'src/app/config/appconfig';
import { upperCaseValidator } from '../../validators/password-upper-case-validator';
import { lowerCaseValidator } from '../../validators/password-lower-case-validator';
import { specialCharValidator } from '../../validators/password-special-char-validator';
import { numberValidator } from '../../validators/password-number-validator';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  override form: FormGroup = new FormGroup({});
  setPasswordForm: FormGroup = new FormGroup({});
  @Input() error: string | null | undefined;
  forgotPasswordDto: ForgotPasswordDTO = {
    email: '',
    url: ''
  }
  setPasswordDto: SetPasswordDTO = {
    password: '',
    userId: 0
  }
  setPasswordConfirmed = false;
  token = '';
  userId = 0;
  type = 'password';
  confirmType = 'password';

  constructor(
    override translocoService: TranslocoService,
    override store: Store,
    public route: ActivatedRoute,
    private router: Router,
    override toastService: ToastService,
    override action$?: Actions
  ) {
    super(store, toastService, action$, translocoService);
    action$?.pipe(ofType(AuthActions.ForgotPasswordValidateSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data !== null && response.data !== undefined) {
        if(response.data === true){
          this.setPasswordConfirmed = response.data;
        }
        else{
          this.toastService.showToast(TOAST_STATE.danger, "Hiba a hitelesítés során!");
        }
      }
    })

    action$?.pipe(ofType(AuthActions.SetUserPasswordSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeres jelszó frissítés");
        this.router.navigateByUrl('/sign-in')
      }
    })
  }

  ngOnInit() {
    this.initForms();
    this.route.queryParams
      .subscribe(params => {
        this.token = params['token'];
        this.userId = Number(params['userId']);
      });

    if (this.token !== '' && this.token && this.userId > 0 && this.userId) {
      this.store.dispatch(AuthActions.ForgotPasswordValidate({
        data: {
          token: this.token,
          userId: this.userId
        }
      }))
    }
  }

  sendClick() {
    this.forgotPasswordDto = JSON.parse(JSON.stringify(this.forgotPasswordDto));
    this.forgotPasswordDto.email = this.form.controls["email"].value;
    this.forgotPasswordDto.url =  Config.frontendUrl + "forgot-password?";

    this.store.dispatch(AuthActions.ForgotPasswordEmailSend({ data: this.forgotPasswordDto }));
    this.toastService.showToast(TOAST_STATE.success, "Kérjük ellenőrizze postafiókját!");
  }

  sendPasswordClick() {
    const passowrd: string = this.setPasswordForm.controls['password'].value;
    this.setPasswordDto = JSON.parse(JSON.stringify(this.setPasswordDto));
    if (passowrd && passowrd.length > 0 && this.userId > 0) {
      this.setPasswordDto.password = passowrd;
      this.setPasswordDto.userId = this.userId;
      this.store.dispatch(AuthActions.SetUserPassword({ data: this.setPasswordDto }))
    }
  }

  eyeClicked() {
    if (this.type.toLocaleLowerCase() === 'password') {
      this.type = 'text'
    } else if (this.type.toLocaleLowerCase() === 'text') {
      this.type = 'password'
    }
  }

  eyeConfirmClicked() {
    if (this.confirmType.toLocaleLowerCase() === 'password') {
      this.confirmType = 'text'
    } else if (this.confirmType.toLocaleLowerCase() === 'text') {
      this.confirmType = 'password'
    }
  }

  initForms() {
    this.form.addControl('email', new FormControl<string | null>('', Validators.required));
    this.setPasswordForm.addControl('password', new FormControl<string | null>('', [Validators.required,Validators.minLength(8)]));
    this.setPasswordForm.addControl('confirm_password', new FormControl<string | null>('', [Validators.required,Validators.minLength(8)]));
    this.setPasswordForm.addValidators(confirmPasswordValidator);
    this.setPasswordForm.addValidators(upperCaseValidator(this.setPasswordForm.controls['password']));
    this.setPasswordForm.addValidators(lowerCaseValidator(this.setPasswordForm.controls['password']));
    this.setPasswordForm.addValidators(specialCharValidator(this.setPasswordForm.controls['password']));
    this.setPasswordForm.addValidators(numberValidator(this.setPasswordForm.controls['password']));
  }

}