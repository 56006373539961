import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Actions, ofType } from '@ngrx/effects';
import * as TokenActions from '../../services/auth/authentication-store/authentication.action';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TOAST_STATE, ToastService } from '../../services/toast/toast.service';
import { BaseComponent } from '../base-component/base-component.component';
import { SignUpModel } from '../../models/sign-up/sign-up.model';
import { aszfConfirmedValidator } from '../../validators/aszf-confirmed-validator';
import { emailValidator } from '../../validators/email-validator';
import { TranslocoService } from '@ngneat/transloco';
import { confirmPasswordValidator } from '../../validators/confirm-password-validator';
import { upperCaseValidator } from '../../validators/password-upper-case-validator';
import { lowerCaseValidator } from '../../validators/password-lower-case-validator';
import { specialCharValidator } from '../../validators/password-special-char-validator';
import { numberValidator } from '../../validators/password-number-validator';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { SendEmailModel } from '../../models/email/send-email-to-students.model';
import { Config } from '../../../config/appconfig';
import { RoleEnum } from '../../enums/roles.enum';

@Component({
  selector: 'sign-up-of',
  templateUrl: './sign-up-of.component.html',
  styleUrls: ['./sign-up-of.component.css']
})
export class SignUpOfComponent extends BaseComponent implements OnInit, OnDestroy {
  override form: FormGroup = new FormGroup({});
  @Input() error: string | null | undefined;
  emailByIdDTO: SendEmailModel = {};
  signUpModel: SignUpModel = {
    password: '',
    viaProvider: false
  };
  type = 'password';
  confirmType = 'password';
  selectedRole = RoleEnum.TEACHER;

  constructor(
    override store: Store,
    public route: Router,
    public authService: AuthenticationService,
    override toastService: ToastService,
    override translocoService: TranslocoService,
    override action$?: Actions
  ) {
    super(store, toastService, action$, translocoService);
    action$?.pipe(ofType(TokenActions.LoadSignUpSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data && response.data.email) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeres regisztráció");
        if(this.selectedRole === RoleEnum.TEACHER){
          this.sendSuccessfulTeacherRegEmail(response.data.email, response.data.confirmationToken!);
        }
       else if(this.selectedRole === RoleEnum.STUDENT){
          this.sendSuccessfulStudentRegEmail(response.data.email, response.data.confirmationToken!);
        }
        this.route.navigateByUrl('/confirm-email');
      }
    })

    action$?.pipe(ofType(TokenActions.LoadCheckUserInDatabaseSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        if (response.data === true) {
          this.toastService.showToast(TOAST_STATE.warning, "Már szerepel az adatbázisunkban, kérlek jelentkezz be!");
          this.route.navigateByUrl('/sign-in');
        }
        else if (response.data === false) {
          this.store.dispatch(TokenActions.LoadSignUp({ data: this.signUpModel }))
        }
      }
    })

    action$?.pipe(ofType(TeacherActions.failure), takeUntil(this.destroy$)).subscribe(() => {
      this.loaderService?.hideLoader();
      this.toastService.showToast(TOAST_STATE.warning, "Hiba a folyamat során!");
    })
  }

  ngOnInit() {
    this.initForm();

    this.subscription.add(
      this.form.controls['roleId'].valueChanges.subscribe((roleId: number) => {
        const role = Number(roleId);
        if (role === 1) {
          this.selectedRole = RoleEnum.TEACHER
        } else {
          this.selectedRole = RoleEnum.STUDENT
        }
      }));
  }

  sendSuccessfulTeacherRegEmail(email: string, confirmationToken: string) {
    const confirmationLink = Config.frontendUrl + 'confirm-email?token=' + confirmationToken + '&email=' + email;
    this.emailByIdDTO = {};
    this.emailByIdDTO.body = 'Szia ' + this.signUpModel.firstName + '!<br>'
      + 'Köszönjük, hogy csatlakoztál hozzánk!<br>'
      + 'Nagyon örülünk, hogy regisztráltál az oldalunkra! Pár lépésben segítünk, hogy gyorsan láthatóvá váljon a profilod a keresőben.<br>'
      + 'Ahhoz, hogy megjelenj, kérlek, csináld végig az alábbiakat:<br>'
      + '1. Töltsd ki a profilodat. <br>'
      + '2. Töltsd fel a tantárgyakat, amiket tanítanál.<br>'
      + '3. Add meg a helyszínt, ahol az órákat tartod.<br>'
      + '4. Az órarendedbe vegyél fel legalább egy órát.'
      + 'Ha ezek megvannak, máris látható leszel a keresőben! Ha bármiben elakadsz, vagy kérdésed van, nyugodtan írj nekünk az info@orafoglalo.hu email címre, szívesen segítünk!<br>'
      + 'Megerősítő linked: <br>'
      + confirmationLink
      + '<br>' + 'Üdvözlettel,'
      + 'az Órafoglaló.hu csapata';
    this.emailByIdDTO.subject = 'Sikeres regisztráció, erősítsd meg az e-mailed!';
    this.emailByIdDTO.toAddresses = [];
    this.emailByIdDTO.toAddresses.push(email);
    this.store.dispatch(TeacherActions.sendEmailToAddresses({ data: this.emailByIdDTO }));
  }

  sendSuccessfulStudentRegEmail(email: string, confirmationToken: string) {
    const confirmationLink = Config.frontendUrl + 'confirm-email?token=' + confirmationToken + '&email=' + email;
    this.emailByIdDTO = {};
    this.emailByIdDTO.body = 'Szia ' + this.signUpModel.firstName + '!<br>'
      + 'Köszönjük, hogy csatlakoztál hozzánk!<br>'
      + 'Nagyon örülünk, hogy regisztráltál az oldalunkra! Bátron böngéssz és keress tanáraink között!<br>'
      + 'Ha bármiben elakadsz, vagy kérdésed van, nyugodtan írj nekünk az info@orafoglalo.hu email címre, szívesen segítünk!<br>'
      + 'Megerősítő linked: <br>'
      + confirmationLink
      + '<br>' + 'Üdvözlettel,'
      + 'az Órafoglaló.hu csapata';
    this.emailByIdDTO.subject = 'Sikeres regisztráció, erősítsd meg az e-mailed!';
    this.emailByIdDTO.toAddresses = [];
    this.emailByIdDTO.toAddresses.push(email);
    this.store.dispatch(TeacherActions.sendEmailToAddresses({ data: this.emailByIdDTO }));
  }

  submit() {
    if (this.form.valid) {
      this.signUpModel = JSON.parse(JSON.stringify(this.signUpModel));

      this.signUpModel = this.form.value;
      this.signUpModel = JSON.parse(JSON.stringify(this.signUpModel));
      this.signUpModel.viaProvider = false;

      this.store.dispatch(TokenActions.LoadCheckUserInDatabase({ data: JSON.stringify(this.signUpModel.email) }))
    }
  }

  backClicked() {
    window.scrollTo(0, 0);
    this.route.navigateByUrl('/sign-up')
  }

  preventEnter(event: Event) {
    if (event instanceof KeyboardEvent && event.key === 'Enter') {
      event.preventDefault();
    }
  }

  eyeClicked() {
    if (this.type.toLocaleLowerCase() === 'password') {
      this.type = 'text'
    } else if (this.type.toLocaleLowerCase() === 'text') {
      this.type = 'password'
    }
  }

  eyeConfirmClicked() {
    if (this.confirmType.toLocaleLowerCase() === 'password') {
      this.confirmType = 'text'
    } else if (this.confirmType.toLocaleLowerCase() === 'text') {
      this.confirmType = 'password'
    }
  }

  initForm() {
    this.form.addControl('email', new FormControl<string | null>('', Validators.required));
    this.form.addControl('password', new FormControl<string | null>('', [Validators.required, Validators.minLength(8)]));
    this.form.addControl('confirm_password', new FormControl<string | null>('', [Validators.required, Validators.minLength(8)]));
    this.form.addControl('firstName', new FormControl<string | null>('', Validators.required));
    this.form.addControl('lastName', new FormControl<string | null>('', Validators.required));
    this.form.addControl('phoneNumber', new FormControl<string | null>('', Validators.required));
    this.form.addControl('roleId', new FormControl<number | null>(1, Validators.required));
    this.form.addControl('isPhonePublic', new FormControl<boolean | null>(false));
    this.form.addControl('isEmailPublic', new FormControl<boolean | null>(false));
    this.form.addControl('isAszfAccepted', new FormControl<boolean | null>(false));
    this.form.addValidators(aszfConfirmedValidator);
    this.form.addValidators(emailValidator(this.form.controls['email']));
    this.form.addValidators(confirmPasswordValidator);
    this.form.addValidators(upperCaseValidator(this.form.controls['password']));
    this.form.addValidators(lowerCaseValidator(this.form.controls['password']));
    this.form.addValidators(specialCharValidator(this.form.controls['password']));
    this.form.addValidators(numberValidator(this.form.controls['password']));
  }
}
