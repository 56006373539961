<div class="flex flex-col md:flex-row justify-around">
    <div class="md:max-w-[50%] mx-auto content-center">
      <h2 class="text-3xl font-bold tracking-tight text-dark-green sm:text-4xl">Órát tartanál vagy Óraadót keresel?</h2>
      <p class="mt-6 text-lg leading-8 text-gray-500">Csatlakozz közösségünkhöz! Regisztrálj és keress fel minket social media platformokon is!
      </p>
      <div class="grid lg:grid-cols-4 lg:grid-rows-1 md:grid-cols-4 md:grid-rows-1 grid-cols-2 grid-rows-2 gap-4 mt-5 justify-items-center">
          <a href="{{facebookLink}}" target="_blank" role="button">
            <img src="../../../../assets/images/social-form/facebook.svg" class="btn-icon w-16 h-16" loading="lazy" alt="facebook">
          </a>
          <a href="{{instagramLink}}" target="_blank" role="button">
            <img src="../../../../assets/images/social-form/insta.svg" class="btn-icon w-16 h-16" loading="lazy" alt="insta">
          </a>
          <a href="{{tiktokLink}}" target="_blank" role="button">
            <img src="../../../../assets/images/social-form/tiktok.svg" class="btn-icon w-16 h-16" loading="lazy" alt="tiktok">
          </a>
          <a href="{{youtubeLink}}" target="_blank" role="button">
            <img src="../../../../assets/images/social-form/youtube.svg" class="btn-icon w-16 h-16" loading="lazy" alt="youtube">
          </a>
      </div>
    </div>
    <img src="../../../assets/images/landing-home/hold-give.jpg" alt="hold" class="hidden md:block max-h-96"
      loading="lazy">
  </div>