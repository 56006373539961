import { createAction, props } from "@ngrx/store";
import { GetNotificationsDTO, NotificationQ, NotificationR, NotificationToScrollDTO, NotificationU } from "../notification.model";

export const init = createAction(
    '[Notification/API] Load Notification',
    props<{ id: number }>()
);

export const loadNotificationsByUserId = createAction(
    '[Notification/API] Load Notifications By User Id',
    props<{ data: GetNotificationsDTO }>()
);

export const loadNotificationsByUserIdSuccess = createAction(
    '[Notification/API] Load Notifications By User Id Success',
    props<{ data: NotificationToScrollDTO[] }>()
);

export const InsertNotification = createAction(
    '[Notification/API] Insert Notification',
    props<{ notification: NotificationQ }>()
);

export const InsertNotificationSuccess = createAction(
    '[Notification/API] Insert Notification Success',
    props<{ data: NotificationR }>()
);

export const UpdateNotification = createAction(
    '[Notification/API] Update Notification',
    props<{ data: NotificationU }>()
);

export const UpdateNotificationSuccess = createAction(
    '[Notification/API] Update Notification Success',
    props<{ data: NotificationR }>()
);

export const loadNotificationsCountByUserId = createAction(
    '[Notification/API] Load Notifications Count'
);

export const loadNotificationsCountByUserIdSuccess = createAction(
    '[Notification/API] Load Notifications Count Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Notification/API] Load Notifications Failure',
    props<{ error: any }>()
);