import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'star-rating-component',
  templateUrl: './star-rating-component.component.html',
  styleUrls: ['./star-rating-component.component.css'],
  standalone: true
})

export class StarRatingComponent {
  @Output() clickedStarValue = new EventEmitter<number>();
  starRatingValue = 0;
  maxStars: number = 5;
  checkedStars: number = 0;

  get stars(): number[] {
    return Array(this.maxStars).fill(0).map((_, index) => index + 1);
  }

  onStarClick(starIndex: number): void {
    this.checkedStars = starIndex;
    if (this.checkedStars !== undefined && this.checkedStars !== null) {
      this.clickedStarValue.emit(this.checkedStars)
    }
  }
}
