import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { ToastService } from '../../../shared/services/toast/toast.service';
import { LessonTypeR } from '../../../teacher/models/lesson-type/lesson-type.model';
import * as LessonTypeActions from '../../../teacher/services/lesson-type/lesson-type-store/lesson-type.action';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'main-filter-cards',
  templateUrl: './main-filter-cards.component.html',
  styleUrls: ['./main-filter-cards.component.css']
})
export class MainFilterCardsComponent extends BaseComponent implements OnInit, OnDestroy {
  allLessonTypes: LessonTypeR[] = [];

  constructor(
    override store: Store,
    override toastService: ToastService,
    override action$?: Actions
  ) {
    super(store, toastService, action$);

    action$?.pipe(ofType(LessonTypeActions.loadLessonTypesSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data) {
        response.data.forEach(x => this.allLessonTypes.push(x));
      }
    })
  }

  ngOnInit(): void {
    this.store.dispatch(LessonTypeActions.loadLessonTypes());
  }
}
