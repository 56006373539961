import { Component } from '@angular/core';
import { Config } from '../../../config/appconfig';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  facebookLink = Config.facebookLink;
  instagramLink = Config.instagramLink;
  youtubeLink = Config.youtubeLink;
  tiktokLink = Config.tiktokLink;
}
