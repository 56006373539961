import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./notification.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as NotificationActions from './notification.action';
import { NotificationR } from '../notification.model';

export const NotificationAdapter: EntityAdapter<NotificationR> =
    createEntityAdapter<NotificationR>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const NotificationReducer = createReducer(
    initialState,
    on(NotificationActions.init, () =>
        ({ ...initialState })
    ),
    on(
        NotificationActions.loadNotificationsByUserIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        NotificationActions.UpdateNotificationSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        NotificationActions.InsertNotificationSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        NotificationActions.loadNotificationsCountByUserIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        NotificationActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return NotificationReducer(state,aciton);
}