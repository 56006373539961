import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as LessonActions from '../lesson-store/lesson.action';
import { catchError, concatMap, map, of } from "rxjs";
import { LessonService } from "../lesson-service";


@Injectable()
export class LessonEffects {

    getTeacherLessonsByTeacherId$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonActions.loadGetTeacherLessonsByTeacherId),
            concatMap((action) => this.lessonService.getTeacherLessonsByTeacherId(action.teacherId).pipe(
                map((data) => {
                    return LessonActions.loadGetTeacherLessonsByTeacherIdSuccess({ data });
                }),
                catchError(() => of(LessonActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });

    getAllLessonCategoryCount$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonActions.getAllLessonCategoryCount),
            concatMap(() => this.lessonService.getAllLessonCategoryCount().pipe(
                map((data) => {
                    return LessonActions.getAllLessonCategoryCountSuccess({ data });
                }),
                catchError(() => of(LessonActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });

    getAllLessonByLessonTypeId$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonActions.getAllLessonByLessonTypeId),
            concatMap((action) => this.lessonService.getAllLessonByLessonTypeId(action.lessonTypeId).pipe(
                map((data) => {
                    return LessonActions.getAllLessonByLessonTypeIdSuccess({ data });
                }),
                catchError(() => of(LessonActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });

    getAllLessonByLessonTypeIds$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonActions.getAllLessonByLessonTypeIds),
            concatMap((action) => this.lessonService.getAllLessonByLessonTypeIds(action.lessonTypeIds).pipe(
                map((data) => {
                    return LessonActions.getAllLessonByLessonTypeIdsSuccess({ data });
                }),
                catchError(() => of(LessonActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });

    GetTeacherLessonsByTeacher$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonActions.getTeacherLessonsByTeacher),
            concatMap(() => this.lessonService.GetTeacherLessonsByTeacher().pipe(
                map((data) => {
                    return LessonActions.getTeacherLessonsByTeacherSuccess({ data });
                }),
                catchError(() => of(LessonActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });

    deleteTeacherLesson$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LessonActions.deleteTeacherLesson),
            concatMap((action) => this.lessonService.deleteTeacherLesson(action.data).pipe(
                map((data) => {
                    return LessonActions.deleteTeacherLessonSuccess({ data });
                }),
                catchError(() => of(LessonActions.failure({ error: "Load lessons failure" })))
            )
            )
        );
    });
    constructor(
        private action$: Actions,
        private lessonService: LessonService
    ) { }
}