import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as NewsLetterActions from './news-letter.action';
import { catchError, concatMap, map, of } from "rxjs";
import { NewsLetterService } from "../news-letter.service";


@Injectable()
export class NewsLetterEffects {

    insertNewsLetterSubscriber$ = createEffect(() => {
        return this.action$.pipe(
            ofType(NewsLetterActions.insertNewsLetterSubscriber),
            concatMap((action) => this.newsLetterService.insertNewsLetterSubscriber(action.data).pipe(
                map((data) => {
                    return NewsLetterActions.insertNewsLetterSubscriberSuccess({ data });
                }),
                catchError(() => of(NewsLetterActions.failure({ error: "Load Newsletter failure" })))
            )
            )
        );
    });

    deleteNewsLetterSubscriber$ = createEffect(() => {
        return this.action$.pipe(
            ofType(NewsLetterActions.deleteNewsLetterSubscriber),
            concatMap((action) => this.newsLetterService.deleteNewsLetterSubscriber(action.data).pipe(
                map((data) => {
                    return NewsLetterActions.deleteNewsLetterSubscriberSuccess({ data });
                }),
                catchError(() => of(NewsLetterActions.failure({ error: "Load Newsletter failure" })))
            )
            )
        );
    });


    constructor(
        private action$: Actions,
        private newsLetterService: NewsLetterService
    ) { }
}