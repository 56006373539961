import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../../config/appconfig";
import { CheckUserFirstLessonDTO, LandingCountsDTO, UpdateUserSeededDTO, UpdateUserSubscriptionDTO, UserDataQ, UserDataR } from "./user.model";
import { ProfilePictureR } from "../../models/sign-up/sign-up.model";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {

    }

    GetUserData() {
        return this.http.get<UserDataR>(Config.baseUrl + 'users/getdata');
    }

    UpdateUserData(userDataQ: UserDataQ) {
        return this.http.post<UserDataR>(Config.baseUrl + 'users/updatedata', userDataQ);
    }

    GetUserProfilePicture() {
        return this.http.get<ProfilePictureR>(Config.baseUrl + 'users/getprofilepicture');
    }

    GetUserProfilePictureToDetails(teahcerId: number) {
        return this.http.get<ProfilePictureR>(Config.baseUrl + 'users/getprofilepicturetodetails?teacherId=' + teahcerId);
    }

    UpdateUserCookieData(cookiesIsAccepted: boolean) {
        return this.http.post<number>(Config.baseUrl + 'users/updatecookiedata', cookiesIsAccepted);
    }


    GetUserCookieData() {
        return this.http.get<boolean>(Config.baseUrl + 'users/getcookiedata');
    }

    DeleteUser() {
        return this.http.get<number>(Config.baseUrl + 'users/deleteuser');
    }

    UpdateUserFirstStepsDone(isFirstStepsDone: boolean) {
        return this.http.post<number>(Config.baseUrl + 'users/updatesteps', isFirstStepsDone);
    }

    UpdateUserSubscription(dto: UpdateUserSubscriptionDTO) {
        return this.http.post<number>(Config.baseUrl + 'users/updatesubscription', dto);
    }

    UpdateUserSeeded(dto: UpdateUserSeededDTO) {
        return this.http.post<number>(Config.baseUrl + 'users/updateseeded', dto);
    }

    CheckUserFirstLesson(dto:CheckUserFirstLessonDTO) {
        return this.http.post<number>(Config.baseUrl + 'users/checkfirstlesson', dto);
    }

    UpdateUserIsFirstLogin(isFirstLogin: boolean) {
        return this.http.post<number>(Config.baseUrl + 'users/updateisfirstlogin', isFirstLogin);
    }

    GetLandingCounts() {
        return this.http.get<LandingCountsDTO>(Config.baseUrl + 'users/get-landing-counts');
    }
}