import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./news-letter.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as NewsLetterActions from './news-letter.action';

export const NewsLetterAdapter: EntityAdapter<number> =
    createEntityAdapter<number>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const newsLetterReducer = createReducer(
    initialState,
    on(NewsLetterActions.init, () =>
        ({ ...initialState })
    ),
    on(
        NewsLetterActions.insertNewsLetterSubscriberSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        NewsLetterActions.deleteNewsLetterSubscriberSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        NewsLetterActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return newsLetterReducer(state,aciton);
}