import { LessonR } from "../../../../teacher/models/lesson/lesson.model";

export const LESSON_FEATURE_KEY = 'LESSON';

export interface State{
    loaded:boolean;
    error?:string | null;
    data: LessonR[] |null|number;
    allCategoriesCount?: number;
    getByTeacherId?:LessonR[];
}