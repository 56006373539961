import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TeacherAssessmentQ } from '../../../teacher/models/teacher/teacher-assessment.model';
import { BaseComponent } from '../base-component/base-component.component';
import { TOAST_STATE, ToastService } from '../../services/toast/toast.service';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { StarRatingComponent } from '../star-rating-component/star-rating-component.component';

@Component({
  selector: 'send-message-component',
  templateUrl: './send-message-component.component.html',
  styleUrls: ['./send-message-component.component.css'],
  standalone: true,
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, StarRatingComponent]
})
export class SendMessageComponent extends BaseComponent implements OnInit, OnDestroy {
  override form!: FormGroup;
  starRatingValue = 0;
  teacherId = 0;
  constructor(
    private route: ActivatedRoute,
    override store: Store,
    override toastService: ToastService
  ) {
    super(store, toastService);
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.teacherId = params['teacherId'] as number;
      })
    this.formBuilder();
  }

  formBuilder() {
    this.form = new FormBuilder().group({
      messageText: new FormControl('')
    });
  }

  clickedStarValue(starRatingValue: any) {
    this.starRatingValue = starRatingValue;
  }

  sendAssessment() {
    if(this.starRatingValue >0){
      const assessment = new TeacherAssessmentQ(
        this.form.controls["messageText"].value,
        this.starRatingValue,
        this.teacherId
      );
  
      this.store.dispatch(TeacherActions.insertTeacherAssessment({ teacherAssessment: assessment }));
    }else{
      this.toastService.showToast(TOAST_STATE.warning, "Kérlek legalább egy csillaggal értékelj.");
    }

  }
}


