import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UtilService } from '../../../shared/services/utils/utils.serivce';
import { TeacherByFilterDTO } from '../../models/teacher/teacher.model';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'teacher-card',
  templateUrl: './teacher-card.component.html',
  styleUrls: ['./teacher-card.component.css']
})
export class TeacherCardComponent extends BaseComponent implements OnInit {
  @Input()
  teacher!: TeacherByFilterDTO;
  isCollapsed: boolean = false;
  safeImg!: SafeResourceUrl;

  constructor(
    private router: Router,
    private utilService: UtilService,
    override store: Store
      ) {
    super(store, undefined, undefined)
  }
  ngOnInit(): void {
    if (this.teacher && this.teacher.profilePicture) {
      this.safeImg = this.utilService.decodeBase64ImageFileToSecurityTrustResource(this.teacher.profilePicture);
    }
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  teacherDetailsClick() {
    this.router.navigate(['/teacher-details'], { queryParams: { teacherId: this.teacher.userId } })
  }

}
