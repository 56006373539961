import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import * as TokenActions from '../../../shared/services/auth/authentication-store/authentication.action';
import { TOAST_STATE, ToastService } from '../../../shared/services/toast/toast.service';
import { JwtDecode } from '../../../shared/models/jwt-token/jwt-token.model';
import { UtilService } from '../../../shared/services/utils/utils.serivce';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { FacebookSignInR, SignInModel, TokenR } from '../../models/sign-up/sign-up.model';
import { MenuItemsService } from '../../services/menu-items/menu-items.service';
import { TeacherStep } from '../../models/step/step.model';
import { RoleEnum } from '../../enums/roles.enum';
declare var FB: any;
declare var gapi: any;

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent extends BaseComponent implements OnInit {
  override form: FormGroup = new FormGroup({});
  @Input() error: string | null | undefined;
  signInModel: SignInModel = {
    email: '',
    password: ''
  };
  tokenR!: TokenR;
  decodedToken!: JwtDecode;
  facebookSignInR!: FacebookSignInR;
  teacherSteps: TeacherStep[] = [];
  isOfLogin = false;
  type = 'password';

  constructor(
    override store: Store,
    public route: Router,
    private utilService: UtilService,
    override toastService: ToastService,
    override loaderService: LoaderService,
    private menuItemsService: MenuItemsService,
    private ref: ChangeDetectorRef,
    override action$?: Actions
  ) {
    super(store, toastService, action$, undefined, undefined, undefined, undefined, loaderService);

    action$?.pipe(ofType(TokenActions.LoadTokenSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.tokenR = response.data;
        this.decodedToken = this.utilService.decodeToken(this.tokenR.token) as JwtDecode;
        localStorage.setItem('token', this.tokenR.token);
        if (this.decodedToken.RoleId) {
          localStorage.setItem('roleId', this.decodedToken.RoleId)
        }
        this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés");

        this.routingHomeByRoleId(Number(this.decodedToken.RoleId));
      }
    })

    action$?.pipe(ofType(TokenActions.LoadGoogleSignInSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.tokenR = response.data;
        this.decodedToken = this.utilService.decodeToken(this.tokenR.token) as JwtDecode;

        localStorage.setItem('token', this.tokenR.token);
        if (this.decodedToken.RoleId) {
          localStorage.setItem('roleId', this.decodedToken.RoleId)
        }
        if (this.decodedToken.RoleId) {
          this.menuItemsService.getMenuItems(this.decodedToken.RoleId as string, 2);
        }

        this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés");

        this.routingHomeByRoleId(Number(this.decodedToken.RoleId));
      }
    })

    action$?.pipe(ofType(TokenActions.LoadFacebookSignInSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.tokenR = response.data;
        this.decodedToken = this.utilService.decodeToken(this.tokenR.token) as JwtDecode;

        localStorage.setItem('token', this.tokenR.token);
        if (this.decodedToken.RoleId) {
          localStorage.setItem('roleId', this.decodedToken.RoleId)
        }
        if (this.decodedToken.RoleId) {
          this.menuItemsService.getMenuItems(this.decodedToken.RoleId as string, 2);
        }

        this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés");

        this.routingHomeByRoleId(Number(this.decodedToken.RoleId));
      }
    })

    action$?.pipe(ofType(TokenActions.LoadCheckUserInDatabaseSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        if (response.data === false) {
          this.toastService.showToast(TOAST_STATE.warning, "Nem szerepel az adatbázisunkban ilyen felhasználó!");
        }
        else if (response.data === true && this.signInModel.viaProvider === true) {
          this.store.dispatch(TokenActions.LoadToken({ data: this.signInModel }));
        }
        else if (response.data === true && this.form.valid && this.signInModel.viaProvider === false) {
          this.store.dispatch(TokenActions.LoadToken({ data: this.signInModel }));
        }
      }
    })

  }

  ngOnInit() {
    this.initForm();
  }

  routingHomeByRoleId(roleId: number) {
    if (roleId.toString() === RoleEnum.STUDENT) {
      this.route.navigateByUrl('/student/home');
    }
    else if (roleId.toString() === RoleEnum.TEACHER) {
      this.route.navigateByUrl('/teacher/home');
    }
  }

  login() {
    let email = this.form.controls["email"].value;
    let password = this.form.controls["password"].value;

    this.signInModel = JSON.parse(JSON.stringify(this.signInModel));
    this.signInModel.email = email;
    this.signInModel.password = password;
    this.signInModel.viaProvider = false;
    this.signInModel.rememberMe = this.form.controls['remember_me'].value;

    this.store.dispatch(TokenActions.LoadCheckUserInDatabase({ data: JSON.stringify(this.signInModel.email) }))
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.login();
    }
  }

  eyeClicked() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  forgotPasswordClick() {
    this.route.navigateByUrl('/forgot-password');
  }

  preventEnter(event: Event) {
    if (event instanceof KeyboardEvent && event.key === 'Enter') {
      event.preventDefault();
    }
  }

  facebookLogin() {
    this.signInModel = JSON.parse(JSON.stringify(this.signInModel));
    FB.login((response: any) => {
      if (response) {
        localStorage.setItem('facebookAccessToken', JSON.stringify(response));
        this.statusChangeCallback(response);
      }
    })
  }

  statusChangeCallback(response: { status: string; }) {
    this.loaderService.showLoader();
    if (response.status === 'connected') {
      FB.api('/me', { fields: 'email' }, (response: FacebookSignInR) => {
        if (response) {
          this.facebookSignInR = response;
          this.signInModel.email = this.facebookSignInR.email;
          this.loaderService.hideLoader();
          this.ref.detectChanges();
          this.store.dispatch(TokenActions.LoadFacebookSignIn({ data: this.signInModel }));
        }
      })
    }
    else {
      this.loaderService.hideLoader();
      this.ref.detectChanges();
    }
  }

  googleLogin() {
    this.loaderService.showLoader('Google bejelentkezés');
    gapi.load('auth2', () => {
      gapi.auth2.authorize({
        client_id: '1047061689417-35t06epi74785n6k54gnf4kv4nv4v9u2.apps.googleusercontent.com',
        scope: 'email profile openid',
        plugin_name: 'of_login',
        response_type: 'id_token permission'
      }, (response: any) => {
        if (response.error) {
          this.loaderService.hideLoader();
          this.ref.detectChanges();
          return;
        }
        this.signInModel = JSON.parse(JSON.stringify(this.signInModel));

        this.signInModel.viaProvider = true;
        this.signInModel.rememberMe = this.form.controls['remember_me'].value;
        this.signInModel.googleCredentials = response.id_token;

        this.loaderService.hideLoader();
        this.ref.detectChanges();
        this.store.dispatch(TokenActions.LoadGoogleSignIn({ data: this.signInModel }));
      }
      );
    });
  }

  OfLoginClicked() {
    this.isOfLogin = true;
  }

  backClicked() {
    this.isOfLogin = false;
  }


  initForm() {
    this.form.addControl('email', new FormControl<string | null>('', Validators.required));
    this.form.addControl('password', new FormControl<string | null>('', [Validators.required, Validators.minLength(1)]));
    this.form.addControl('remember_me', new FormControl<boolean | null>(false));
  }
}
