<div class="max-w-sm rounded h-full bg-white overflow-hidden shadow-lg card">
  <div class="relative">
    <img class="w-80 h-80 rounded-full m-auto" [src]="safeImg || './assets/images/profile_anonym.svg'"
      alt="teacher-profile-picture" loading="lazy">
    @if (teacher.verified) {
    <img src="./assets/images/verified2.png" class="absolute bottom-4 right-4 w-12" alt="verified" loading="lazy"
      title="Verified">
    }
  </div>
  <div class="px-6 flex flex-wrap align-center">
    <img src="./assets/images/teacher-card/teacher.svg" loading="lazy" class="max-w-6 max-h-6 text-dark-green">
    <div class="font-bold text-xl mb-2 text-dark-green">
      {{teacher.lastName}}
      {{teacher.firstName }}
    </div>
  </div>
  @if(teacher.totalReviewCount>0){
  <div class="px-6 align-center">
    <div>
      <app-star-summary-rating [rating]="teacher.totalReviewRating"></app-star-summary-rating>
    </div>
    <p class="font-bold text-m mb-2 text-dark-green">{{teacher.totalReviewCount}} {{'teacher.teacher_card.reviews_text'
      |
      transloco}}</p>
  </div>
  }@else{
  <div class="px-6 flex flex-wrap align-center">
    <img src="../../../../assets/images/stars/star.svg" class="w-6 h-6" alt="star" loading="lazy" title="star">
    <span class="font-bold text-l mb-2 text-dark-green"> {{'shared.reviews_summary.no_rating' |
      transloco}}
    </span>
  </div>
  }
  <div class="px-6 py-2 flex flex-wrap">
    <img src="./assets/images/teacher-card/money.svg" class="max-w-6 max-h-6" loading="lazy" alt="money">
    <div class="font-bold text-l text-dark-green mb-2"> {{'teacher.teacher_card.best_price' | transloco}}:
      @if(teacher.bestPrice ){
      {{teacher.bestPrice}} {{'teacher.teacher_card.huf' |
      transloco}}
      }
    </div>
  </div>
  <div class="px-6 py-1">
    <span class="flex flex-wrap">
      <img src="./assets/images/teacher-card/document.svg" class="max-w-6 max-h-6">
      <p class="font-bold text-l mb-2 text-dark-green">{{'teacher.teacher_card.lesson_types' |
        transloco}}:</p>
    </span>
    @if(teacher.lessonTypes) {
    <p class="text-gray-700 text-base text-dark-green break-all">
      @if(teacher.lessonTypes!.length > 0){
    <div class="px-1 pt-4 pb-2">
      @for(lesson of teacher.lessonTypes.split(','); track $index){
      <span class="inline-block bg-dark-green rounded-full px-2 py-1 text-xs text-white mr-1 mb-1">#{{lesson}}</span>
      }
    </div>
    }
    </p>
    }
  </div>
</div>
<div class="max-w-sm bg-white rounded overflow-hidden shadow-lg  card">
  <div class="flex justify-center px-6 py-2">
    <button type="button" (click)="teacherDetailsClick()" class="btn-primary">
      <p class="justify-self-center">{{'common.more_information' | transloco}}</p>
    </button>
  </div>
</div>