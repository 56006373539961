import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LESSON_FEATURE_KEY, State } from "./lesson.state";

export const getLessonSate = createFeatureSelector<State>(LESSON_FEATURE_KEY);

export const getLessonLoaded = createSelector(
    getLessonSate,
    (state:State) => state.loaded
)

export const getLesson = createSelector(
    getLessonSate,
    (state:State) => state.data
)

export const getAllLessonCategoryCount = createSelector(
    getLessonSate,
    (state:State) => state.allCategoriesCount
)

export const getLessonsByTeacherId = createSelector(
    getLessonSate,
    (state:State) => state.getByTeacherId
)