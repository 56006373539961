import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';
import { GetNotificationsDTO, NotificationToScrollDTO } from "../notificaiton/notification.model";
import { Subject } from "rxjs";
import { InsertUserNotificationSocketDTO, RemoveFromEventGroupSocketDTO, RemoveFromUserGroupSocketDTO, UserJoinEventSocketDTO } from "../../models/event/event.model";
import { Config } from "src/app/config/appconfig";
import { HubConnectionState } from "@microsoft/signalr";

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    destroy$: Subject<boolean> = new Subject<boolean>();
    public hubConnection!: signalR.HubConnection;
    public availableSeats = 0;
    public userNotificationCount = 0;
    public userNewNotificationsCount = 0;
    public userNotification!: NotificationToScrollDTO;
    public notificationsToScroll: NotificationToScrollDTO[] = [];

    constructor() {
    }

    public startConnection = async (retryCount = 5, retryDelay = 5000) => {
        const attemptConnection = async (retriesLeft: number) => {
            try {
                this.hubConnection = new signalR.HubConnectionBuilder()
                    .withUrl(Config.backendUrl + 'eventhub', {
                        skipNegotiation: true,
                        transport: signalR.HttpTransportType.WebSockets
                    })
                    .withAutomaticReconnect()
                    .withServerTimeout(3600000)
                    .build();

                await this.hubConnection.start().catch(err => {
                    console.error('Error starting SignalR connection:', err);
                });
            } catch (err) {
                console.error('Error establishing SignalR connection:', err);
                if (retriesLeft > 0) {
                    setTimeout(() => attemptConnection(retriesLeft - 1), retryDelay);
                } else {
                    console.error('All retry attempts to establish SignalR connection have failed.');
                }
            }
        };

        await attemptConnection(retryCount);
    }

    async checkConnectedState() {
        if (this.hubConnection) {
            return HubConnectionState.Connected === this.hubConnection.state;
        }
        return false;
    }

    public addGetGroupDataFromClientDataListener = () => {
        this.hubConnection.on('GetGroupDataFromClient', (data) => {
            console.log(data);
        })
    }

    public async invokeAddToGroupListener(eventId: number, userId: number) {
        await this.hubConnection.send("AddToGroup", {
            eventId: eventId,
            userId: userId.toString()
        });
    }

    public addJoinEventListener = async () => {
        this.hubConnection.on('ReceiveEventAvailableSeats', (result: number) => {
            this.availableSeats = result;
        })
    }

    public addJoinEventNotificationListener = async () => {
        this.hubConnection.on('ReceiveUserNotification', (notificationCount: number, notification: NotificationToScrollDTO) => {
            this.userNotificationCount = notificationCount;
            this.userNotification = notification;
        })
    }

    public async invokeRemoveFromEventGroup(dto: RemoveFromEventGroupSocketDTO) {
        await this.hubConnection.send("RemoveFromEventGroup", dto);
    }

    public async invokeRemoveFromUserGroup(dto: RemoveFromUserGroupSocketDTO) {
        await this.hubConnection.send("RemoveFromUserGroup", dto);
    }

    public async invokeJoinEvent(dto: UserJoinEventSocketDTO) {
        await this.hubConnection.send("JoinEvent", dto
        );
    }

    public async invokeUpdateEventAvailableSeats(eventId: number) {
        await this.hubConnection.send("UpdateEventAvailableSeats", eventId
        );
    }

    public async invokeInsertUserNotification(dto: InsertUserNotificationSocketDTO) {
        await this.hubConnection.send("InsertUserNotification", dto
        );
    }

    public async invokeAddUserToOwnGroup(userId: number) {
        await this.hubConnection.send('AddUserToOwnGroup', userId);
    }

    public AddUserToOwnGroupDataListener = async () => {
        this.hubConnection.on('GetUserGroupDataFromClient', (data) => {
            console.log(data)
        })
    }

    public async invokeUpdateUserNotificationCount(notificationId: number, userId: number) {
        await this.hubConnection.send("UpdateUserNotificationCount", {
            notificationId: notificationId,
            userId: userId.toString()
        }
        );
    }

    public async invokeGetNotificationsToScroll(dto: GetNotificationsDTO) {
        await this.hubConnection.send("GetNotificationsToScroll", dto);
    }

    public ReceiveNotificationsToScrollListener = async () => {
        this.hubConnection.on('ReceiveNotificationsToScroll', (data: NotificationToScrollDTO[]) => {
            this.notificationsToScroll = data;
        })
    }

    public addReceiveUserNewNotificationsCountListener = async () => {
        this.hubConnection.on('ReceiveUserNewNotificationsCount', (notificationCount: number) => {
            this.userNewNotificationsCount = notificationCount;
        })
    }

    public disconnectConnection() {
        this.hubConnection.stop();
    }
}