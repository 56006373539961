import { ChangeDetectionStrategy, Component} from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'local-spinner',
  templateUrl: './local-spinner.component.html',
  styleUrls: ['./local-spinner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalSpinnerComponent  {
  constructor(
    public loader: LoaderService
  ) {

  }

}
