<div class="grid grid-flow-row">
  <div class="row-start-1">
    <div class="bg-dark-green mb-5 mt-5 p-4 md:p-8">
      <div class="bg-white py-4 sm:py-8 px-4 md:px-8">
        <landing-social></landing-social>
      </div>
    </div>
  </div>

  <div class="row-start-2">
    <div class="bg-white p-4 md:p-8">
      <div class="bg-dark-green">
        <div class="flex-col lg:flex lg:flex-row md:flex md:flex-row mt-4">
          <div class="flex flex-col items-center justify-center md:w-1/3">
            <p class="text-white font-semibold text-center">Tanáraink biztosítják számotokra a tudást</p>
            <img src="./assets/images/teacher.png" class="mt-2 w-[400px] h-[400px] overflow-hidden" alt="teacher"
              loading="lazy">
          </div>
          <h3 class="text-white text-4xl flex justify-center items-center md:w-1/3">Találjatok Egymásra!</h3>
          <div class="flex flex-col items-center md:w-1/3">
            <p class="text-white font-semibold text-center">Diákjaink élettel töltik meg a tanórákat</p>
            <img src="./assets/images/student.png" class="mt-2 w-[400px] h-[400px] overflow-hidden" alt="student"
              loading="lazy">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row-start-3">
    <div class="bg-dark-green p-4 md:p-8">
      <div class="bg-white py-24 sm:py-32">
        <landing-counts></landing-counts>
      </div>
    </div>
  </div>

  <div class="row-start-4">
    <div class="bg-white p-4 md:p-8">
      <div class="bg-dark-green py-24 sm:py-32">
        <div class="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div class="max-w-2xl">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Kiemelt Tanáraink</h2>
            <p class="mt-6 text-lg leading-8 text-gray-200">A legjobbak nem csak szakmailag teljesítenek a maximumon, de
              a diákjaik szívébe is belopják magukat</p>
          </div>
          <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            @for(teacher of teachers; track $index) {
            <seeded-teachers-cards [seededTeacher]="teacher"></seeded-teachers-cards>
            }
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row-start-5">
    <div class="bg-dark-green p-4 md:p-8">
      <div class="bg-white">
        <step-cards></step-cards>
      </div>
    </div>
  </div>

  <div class="row-start-6">
    <div class="bg-white mb-5 mt-5 p-4 md:p-8">
      <div class="bg-dark-green py-24 sm:py-32 px-4 md:px-8">
        <div class="flex flex-col md:flex-row justify-around">
          <div class="md:max-w-[50%] mx-auto">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Professzionális támogatás a
              maximális
              felhasználói élményért</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">Szakembereink a nap 24 órájában azon dolgoznak, hogy a
              lehető legegyszerübben és legbiztonságosabban találjanak diákjaink rá a legjobb tanárokra Magyarországon.
            </p>
            <ul class="list-disc list-inside m-10 text-gray-300">
              <li>A legújabb technológiákat használjuk</li>
              <li>Felhasználói felületeink a ti igényeitekre vannak szabva</li>
              <li>Teljes mobil támogatás</li>
            </ul>
          </div>
          <img src="./assets/images/support.png" alt="promo_it" class="hidden md:block max-h-96 max-w-[50%]"
            loading="lazy">
        </div>
      </div>
    </div>
  </div>

  <div class="row-start-7">
    <div class="bg-dark-green p-4 md:p-8">
      <div class="bg-white p-4 md:p-8">
        <div class="flex flex-col md:flex-row justify-around">
          <newsletter-subscription></newsletter-subscription>
        </div>
      </div>
    </div>
  </div>

  <div class="row-start-8">
    <div class="bg-dark-green p-4 md:p-8">
      <div class="bg-white p-4 md:p-8">
        <div class="flex flex-col md:flex-row justify-around">
          <div class="md:max-w-[50%]">
            <h2 class="text-3xl font-bold tracking-tight text-dark-green sm:text-4xl">Örülünk, hogy itt vagy velünk</h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">Találd meg számodra a legjobb tanárt, böngéssz, válogass,
              kérdezz tőlük mielőtt elköteleznéd magad</p>
            <div class="flex justify-around md:m-10">
              <button [routerLink]="['/','teacher-filter']" type="button"
                class="block btn-primary w-1/3 me-1 my-2 md:w-40">Keress</button>
              <button class="block btn-primary w-1/3 me-1 my-2 md:w-40" type="button"
                [routerLink]="['/','sign-up']">Regisztrálj</button>
            </div>
          </div>
          <img src="./assets/images/faces/student5.jpg" alt="happy" class="hidden md:block max-h-96 max-w-[50%]"
            loading="lazy">
        </div>
      </div>
    </div>
  </div>