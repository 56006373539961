import { createAction, props } from "@ngrx/store";
import { LessonTypeR } from "../../../../teacher/models/lesson-type/lesson-type.model";

export const init = createAction(
    '[Lesson/API] Load Lesson',
    props<{ id: number }>()
);

export const loadLessonTypes = createAction(
    '[Lesson/API] Load Lesson Types'
);

export const loadLessonTypesSuccess = createAction(
    '[Lesson/API] Load Lesson Types Success',
    props<{ data: LessonTypeR[] }>()
);

export const failure = createAction(
    '[Lesson/API] Load Lesson Types Failure',
    props<{ error: any }>()
);