import { createAction, props } from "@ngrx/store";
import { CheckUserFirstLessonDTO, LandingCountsDTO, UpdateUserSeededDTO, UpdateUserSubscriptionDTO, UserDataQ, UserDataR } from "../user.model";
import { ProfilePictureR } from "../../../../shared/models/sign-up/sign-up.model";

export const init = createAction(
    '[User/API] User Data Init',
    props<{ id: number }>()
);

export const loadUserData = createAction(
    '[User/API] Load User Data'
);

export const loadUserDataSuccess = createAction(
    '[User/API] Load User Data Success',
    props<{ data: UserDataR }>()
);

export const UpdateUserData = createAction(
    '[User/API] Update User Data',
    props<{ data: UserDataQ }>()
);

export const UpdateUserDataSuccess = createAction(
    '[User/API] Update User Data Success',
    props<{ data: UserDataR }>()
);

export const GetProfilePicture = createAction(
    '[User/API] Get Profile Picture'
);

export const GetProfilePictureSuccess = createAction(
    '[User/API] Get Profile Picture Success',
    props<{ data: ProfilePictureR }>()
);

export const GetProfilePictureToDetails = createAction(
    '[User/API] Get Profile Picture To Details',
    props<{ data: number }>()
);

export const GetProfilePictureToDetailsSuccess = createAction(
    '[User/API] Get Profile Picture To Details Success',
    props<{ data: ProfilePictureR }>()
);

export const DeleteUserData = createAction(
    '[User/API] Delete User Data'
);

export const DeleteUserDataSuccess = createAction(
    '[User/API] Delete User Data Success',
    props<{ data: number }>()
);

export const UpdateUserFirstStepsDone = createAction(
    '[User/API] Update User First Steps Done',
    props<{ data: boolean }>()
);

export const UpdateUserFirstStepsDoneSuccess = createAction(
    '[User/API] Update User First Steps Done Success',
    props<{ data: number }>()
);

export const UpdateUserSubscription = createAction(
    '[User/API] Update User Subscribed',
    props<{ data: UpdateUserSubscriptionDTO }>()
);

export const UpdateUserSubscriptionSuccess = createAction(
    '[User/API] Update User Subscribed Success',
    props<{ data: number }>()
);

export const CheckUserFirstLesson = createAction(
    '[User/API] Check User First Lesson',
    props<{ data: CheckUserFirstLessonDTO }>()
);

export const CheckUserFirstLessonSuccess = createAction(
    '[User/API] Check User First Lesson Success',
    props<{ data: number }>()
);

export const UpdateUserSeeded = createAction(
    '[User/API] Update User Subscribed',
    props<{ data: UpdateUserSeededDTO }>()
);

export const UpdateUserSeededSuccess = createAction(
    '[User/API] Update User Subscribed Success',
    props<{ data: number }>()
);

export const UpdateUserIsFirstLogin = createAction(
    '[User/API] Update User Is First Login',
    props<{ data: boolean }>()
);

export const UpdateUserIsFirstLoginSuccess = createAction(
    '[User/API] Update User Is First Login Success',
    props<{ data: number }>()
);

export const GetLandingCounts = createAction(
    '[User/API] Get Landing Counts'
);

export const GetLandingCountsSuccess = createAction(
    '[User/API] Get Landing Counts Success',
    props<{ data: LandingCountsDTO }>()
);

export const failure = createAction(
    '[User/API] Load User Data Failure',
    props<{ error: any }>()
);
