<div class="grid grid-rows-2 gap-4">
    <div class="row-start-1 lg:w-2/3 md:w-2/3 w-full justify-self-center">
        <div
            class="max-w justify-center p-6 m-6 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 sign-up-background">
            <div>
                <h3 class="text-white text-center mb-6 text-2xl font-bold">{{'common.registration' | transloco}}</h3>
            </div>
            <div class="grid grid-cols-1 gap-4 button-container">
                <button type="button" class="btn-primary login_button_firebase flex items-center justify-center w-full"
                    (click)="ofRegistrationClick()">
                    <img class="h-6 w-auto cursor-pointer" src="./assets/images/orafoglalo_logo.png" alt="Órafoglaló"
                        loading="lazy" routerLink="/">
                    <span>Órafoglaló {{'common.registration' | transloco}}</span>
                </button>
                <button type="button" (click)="googleRegistrationClick()"
                    class="login_button_google flex items-center justify-center w-full px-4 py-2 border flex gap-2 border-slate-200 rounded-lg font-bold text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                    <img class="w-6 h-6" src="../../../../assets/images/socials/google-color.svg" loading="lazy"
                        alt="google logo">
                    <span>Google {{'common.registration' | transloco}}</span>
                </button>
                <button type="button" (click)="facebookRegistrationClick()" style="background-color: #2374F2"
                    class="login_button_google flex items-center justify-center w-full px-4 py-2 flex gap-2 rounded-lg font-bold text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                    <img src="../../../../assets/images/socials/facebook.svg" class="w-6 h-6" loading="lazy"
                        alt="facebook logo">
                    <span>Facebook {{'common.registration' | transloco}}</span>
                </button>
                <div class="text-white text-center">
                    <span> {{'common.already_account' | transloco}}<a
                            class="text-light-green underline hover:text-light-orange"
                            href="/sign-in">{{'common.sign_in' | transloco}}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>