import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./lesson.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as LessonActions from '../lesson-store/lesson.action';
import { LessonR } from '../../../../teacher/models/lesson/lesson.model';

export const LessonAdapter: EntityAdapter<LessonR> =
    createEntityAdapter<LessonR>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const LessonReducer = createReducer(
    initialState,
    on(LessonActions.init, () =>
        ({ ...initialState })
    ),
    on(
        LessonActions.loadGetTeacherLessonsByTeacherIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            getByTeacherId: data,
            loaded: true
        })
    ),
    on(
        LessonActions.getAllLessonCategoryCountSuccess, (state, { data }) => ({
            ...state,
            data: data,
            getAllLessonCategoryCountSuccess: data,
            loaded: true
        })
    ),
    on(
        LessonActions.getAllLessonByLessonTypeIdSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LessonActions.getAllLessonByLessonTypeIdsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LessonActions.getTeacherLessonsByTeacherSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LessonActions.deleteTeacherLessonSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        LessonActions.failure, (state, { error }) => ({
            ...state,
            loaded: true,
            error
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return LessonReducer(state,aciton);
}