import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from "./step.state";
import { Action, createReducer, on } from "@ngrx/store";
import * as StepActions from './step.action';
import { Step } from '../../../../shared/models/step/step.model';

export const StepAdapter: EntityAdapter<Step> =
    createEntityAdapter<Step>();

export const initialState: State = ({
    loaded: false,
    data: null
});

const StepReducer = createReducer(
    initialState,
    on(StepActions.init, () =>
        ({ ...initialState })
    ),
    on(
        StepActions.loadGetStepsByRoleSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        StepActions.loadInsertTeacherStepsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        StepActions.loadUpdateTeacherStepSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    ),
    on(
        StepActions.loadGetTeacherStepsSuccess, (state, { data }) => ({
            ...state,
            data: data,
            loaded: true
        })
    )
)

export function reducer(state: State | undefined, aciton: Action){
    return StepReducer(state,aciton);
}