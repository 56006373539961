import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventsToTimeTableDTO } from '../../../../shared/models/event/event.model';
import { TranslocoModule } from '@ngneat/transloco';
import { LessonIdEnum } from '../../../../shared/enums/lessond-ids.enum';
import { LocationTypeEnum } from '../../../../shared/enums/location-types.enum';

@Component({
    selector: 'timetable-event-button',
    templateUrl:'timetable-event-button.component.html',
    styleUrls: ['./timetable-event-button.component.css'],
    standalone: true,
    imports: [
        CommonModule, DatePipe, TranslocoModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeTableEventButtonComponent {
    @Output() hit = new EventEmitter<EventsToTimeTableDTO>();
    @Input() event!: EventsToTimeTableDTO;
    @Input() isDisabled: boolean = false;
    availableLessonId = LessonIdEnum.AVAILABLE;
    phoneLocationType = LocationTypeEnum.PHONE;

    eventClicked(): void {
        this.hit.emit(this.event);
    }
}
