import { createAction, props } from "@ngrx/store";
import { InsertLessonFromTempQ, RequestedLessonListDTO } from "../../../../admin/models/requested-lesson-list.model";
import { GetAllTeacherDocumentsDTO, UpdateTeacherVerifiedDto } from "../../../../shared/services/user/user.model";

export const init = createAction(
    '[Admin/API] Init Admin',
    props<{ id: number }>()
);

export const getAllTeachersDocuments = createAction(
    '[Admin/API] Load All Teacher Documents'
);

export const getAllTeachersDocumentsSuccess = createAction(
    '[Admin/API] Load All Teacher Documents Success',
    props<{ data: GetAllTeacherDocumentsDTO[] }>()
);

export const updateTeacherVerify = createAction(
    '[Admin/API] Load Update Teacher Verify',
    props<{ data: UpdateTeacherVerifiedDto }>()
);

export const updateTeacherVerifySuccess = createAction(
    '[Admin/API] Load Update Teacher Verify Success',
    props<{ data: number }>()
);

export const getRequestedLessons = createAction(
    '[Admin/API] Get Requested Lessons'
);

export const getRequestedLessonsSuccess = createAction(
    '[Admin/API] Get Requested Lessons Success',
    props<{ data: RequestedLessonListDTO[] }>()
);

export const insertLessonFromTemp = createAction(
    '[Admin/API] Insert Lesson From Temp',
    props<{ data: InsertLessonFromTempQ }>()
);

export const insertLessonFromTempSuccess = createAction(
    '[Admin/API] Insert Lesson From Temp Success',
    props<{ data: number }>()
);

export const failure = createAction(
    '[Admin/API] Load Admin Failure',
    props<{ error: any }>()
);
