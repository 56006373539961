import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as EventActions from '../event-store/event.action';
import { catchError, concatMap, map, of } from "rxjs";
import { EventService } from "../event.service";


@Injectable()
export class EventEffects {

    createEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.CreateEvent),
            concatMap((action) => this.eventService.createEvent(action.createEventQ).pipe(
                map((data) => {
                    return EventActions.CreateEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    joinEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.JoinEvent),
            concatMap((action) => this.eventService.joinEvent(action.dto).pipe(
                map((data) => {
                    return EventActions.JoinEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    createEvents$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.CreateEvents),
            concatMap((action) => this.eventService.createEvents(action.createEventQ).pipe(
                map((data) => {
                    return EventActions.CreateEventsSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    createAvailableEvents$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.createAvailableEvents),
            concatMap((action) => this.eventService.createAvailableEvents(action.createEventQ).pipe(
                map((data) => {
                    return EventActions.createAvailableEventsSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.getEvent),
            concatMap((action) => this.eventService.getEvent(action.eventId).pipe(
                map((data) => {
                    return EventActions.getEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getEventsByTeacher$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetEventByTeacher),
            concatMap((action) => this.eventService.getEventsByTeacherId(action.data).pipe(
                map((data) => {
                    return EventActions.GetEventByTeacherSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    GetArchiveEventsTeacherEventsList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetArchiveEventsTeacherEventsList),
            concatMap((action) => this.eventService.getArchiveEventsByTeacherId(action.data).pipe(
                map((data) => {
                    return EventActions.GetArchiveEventsTeacherEventsListSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    GetUnAcceptedEventsTeacherEventsList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetUnAcceptedEventsTeacherEventsList),
            concatMap((action) => this.eventService.getUnAcceptedEventsByTeacherId(action.data).pipe(
                map((data) => {
                    return EventActions.GetUnAcceptedEventsTeacherEventsListSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    GetArchiveEventsStudentEventsList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetArchiveEventsByStudentId),
            concatMap((action) => this.eventService.getArchiveEventsByStudentId(action.data).pipe(
                map((data) => {
                    return EventActions.GetArchiveEventsByStudentIdSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    GetUnAcceptedEventsStudentEventsList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetUnAcceptedEventsByStudentId),
            concatMap((action) => this.eventService.getUnAcceptedEventsByStudentId(action.data).pipe(
                map((data) => {
                    return EventActions.GetUnAcceptedEventsByStudentIdSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getEventsBySchedule$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.getEventsBySchedule),
            concatMap((action) => this.eventService.getEventsBySchedule(action.data).pipe(
                map((data) => {
                    return EventActions.getEventsByScheduleSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load Get Events By Schedule failure" })))
            )
            )
        );
    });

    getEventsToCalendar$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.getEventsToCalendar),
            concatMap((action) => this.eventService.getEventsToCalendar(action.data).pipe(
                map((data) => {
                    return EventActions.getEventsToCalendarSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load Get Events By Schedule failure" })))
            )
            )
        );
    });

    GetUsersDataByEventId$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetUsersDataByEventId),
            concatMap((action) => this.eventService.getUsersDataByEventId(action.data).pipe(
                map((data) => {
                    return EventActions.GetUsersDataByEventIdSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    UpdateEventIsAccepted$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.UpdateEventIsAccepted),
            concatMap((action) => this.eventService.UpdateEventIsAccepted(action.data).pipe(
                map((data) => {
                    return EventActions.UpdateEventIsAcceptedSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    UpdateEventIsDone$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.UpdateEventIsDone),
            concatMap((action) => this.eventService.UpdateEventIsDone(action.data).pipe(
                map((data) => {
                    return EventActions.UpdateEventIsDoneSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    UpdateEventIsArchive$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.UpdateEventIsArchive),
            concatMap((action) => this.eventService.UpdateEventIsArchive(action.data).pipe(
                map((data) => {
                    return EventActions.UpdateEventIsArchiveSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    deleteEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.DeleteEvent),
            concatMap((action) => this.eventService.deleteEvent(action.data).pipe(
                map((data) => {
                    return EventActions.DeleteEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    deleteEvents$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.DeleteEvents),
            concatMap((action) => this.eventService.deleteEvents(action.data).pipe(
                map((data) => {
                    return EventActions.DeleteEventsSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    deleteStudentFromEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.DeleteStudentFromEvent),
            concatMap((action) => this.eventService.DeleteStudentFromEvent(action.data).pipe(
                map((data) => {
                    return EventActions.DeleteStudentFromEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getEventsByStudentId$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetEventsByStudentId),
            concatMap((action) => this.eventService.getEventsByStudentId(action.data).pipe(
                map((data) => {
                    return EventActions.GetEventsByStudentIdSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getEventDetailsToJoinEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.getEventDetailsToJoinEvent),
            concatMap((action) => this.eventService.getEventDetailsToJoinEvent(action.data).pipe(
                map((data) => {
                    return EventActions.getEventDetailsToJoinEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    checkUserExistOnEvent$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.checkUserExistOnEvent),
            concatMap((action) => this.eventService.checkUserExistOnEvent(action.data).pipe(
                map((data) => {
                    return EventActions.checkUserExistOnEventSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getConnectedEvents$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.getConnectedEvents),
            concatMap((action) => this.eventService.getConnectedEvents(action.data).pipe(
                map((data) => {
                    return EventActions.getConnectedEventsSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    getConnectedEventsToUpdate$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.getConnectedEventsToUpdate),
            concatMap((action) => this.eventService.getConnectedEventsToUpdate(action.data).pipe(
                map((data) => {
                    return EventActions.getConnectedEventsToUpdateSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    GetStudentsEmailsByEventIds$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetStudentsEmailsByEventIds),
            concatMap((action) => this.eventService.GetStudentsEmailsByEventIds(action.data).pipe(
                map((data) => {
                    return EventActions.GetStudentsEmailsByEventIdsSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    GetDeletedEventsDetails$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EventActions.GetDeletedEventsDetails),
            concatMap((action) => this.eventService.GetDeletedEventsDetails(action.data).pipe(
                map((data) => {
                    return EventActions.GetDeletedEventsDetailsSuccess({ data });
                }),
                catchError(() => of(EventActions.failure({ error: "Load events failure" })))
            )
            )
        );
    });

    constructor(
        private action$: Actions,
        private eventService: EventService
    ) { }
}