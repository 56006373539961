<form [formGroup]="form">
    <div class="pb-6 items-center">
        <div class="py-4 border-b border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-800 dark:text-gray-300">{{'shared.send_message.rating' | transloco}}: </p>
            <div class="flex items-center justify-center">
                <p class="text-sm leading-none text-gray-600 dark:text-gray-300 mr-3">
                    <star-rating-component (clickedStarValue)="clickedStarValue($event)"></star-rating-component>
                </p>
            </div>
        </div>
    </div>
    <div class="flex mx-2">
        <textarea formControlName="messageText"
            class="textarea"
            id="message" type="text" placeholder="Üzenet...">
        </textarea>
        <button type="submit" class="mx-2 w-48 justify-center" (click)="sendAssessment()">
            <div class="img-wrapper-1">
                <div class="img-wrapper w-6 h-6">
                    <img src="../../../../../assets/images/newsletter/send.svg" loading="lazy" alt="send">
                </div>
            </div>
            <span>{{'common.send' | transloco}}</span>
        </button>
    </div>
</form>