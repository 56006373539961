<div id="toast" [@toastTrigger]="(toast.showsToast$ | async) ? 'open' : 'close'"
    class="flex fixed z-50 top-5 right-5 items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
    role="alert">

    @if(actualToastState === TOAST_STATE.success){
    <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
        <img src="../../../../assets/images/toast/success.svg" class="w-5 h-5" loading="lazy" alt="success">
        <span class="sr-only">Check icon</span>
    </div>
    }

    @if(actualToastState === TOAST_STATE.warning){
    <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-yellow-500 bg-yellow-100 rounded-lg dark:bg-yellow-800 dark:text-yellow-200">
        <img src="../../../../assets/images/toast/warning.svg" class="w-5 h-5" loading="lazy" alt="warning">
        <span class="sr-only">Check icon</span>
    </div>
    }

    @if(actualToastState === TOAST_STATE.danger){
    <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
        <img src="../../../../assets/images/toast/warning.svg" class="w-5 h-5" loading="lazy" alt="danger">
        <span class="sr-only">Check icon</span>
    </div>
    }


    <div class="ml-3 text-sm font-normal"> {{ toast.toastMessage$ | async }} </div>
    <button type="button" (click)="dismiss()"
        class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target="#toast" aria-label="Close">
        <span class="sr-only">Close</span>
        <img src="../../../../assets/images/x.svg" class="w-5 h-5" loading="lazy" alt="xButton">
    </button>
</div>