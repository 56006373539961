import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../shared/components/base-component/base-component.component';
import { ToastService } from '../../shared/services/toast/toast.service';
import * as TeacherActions from '../../teacher/services/teacher/teacher-store/teacher.action';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { UserDataR } from '../../shared/services/user/user.model';
import { SeededTeacherWithLessonsModel } from 'src/app/shared/models/seeded-teachers-cards/seeded-teacher-cards.model';
import * as UserActions from '../../shared/services/user/user-store/user.action';
import { RoleEnum } from '../../shared/enums/roles.enum';
import { Router } from '@angular/router';
import * as fromUser from '../../shared/services/user/user-store/user.selector';

@Component({
  selector: 'app-landing-home',
  templateUrl: './home.component.html',
})
export class LandingHomeComponent extends BaseComponent implements OnInit, OnDestroy {
  teachers: SeededTeacherWithLessonsModel[] = [];
  userData: UserDataR = {
    profilePicture: '',
    about: '',
    professionalExperience: ''
  };

  constructor(
    private router: Router,
    override toastService: ToastService,
    override store: Store,
    override action$?: Actions
  ) {
    super(store, toastService, action$);

    action$?.pipe(ofType(TeacherActions.getSeededTeachersSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response && response.data && response.data.length > 0) {
        this.teachers = response.data;
      }
    })

    action$?.pipe(ofType(UserActions.loadUserDataSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        if (response.data.userId) {
          this.userData = response.data;
          this.navigateHome(this.userData.roleId);
        }
      }
    })
  }

  ngOnInit(): void {
    this.subscriptionsInit();
    this.checkStoreUserData();
  }

  checkStoreUserData() {
    const userData = this.store.select(fromUser.getUserData);
    userData
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          this.navigateHome(response.roleId);
        }
      })
  }

  navigateHome(userRoleId?: number) {
    if (userRoleId && userRoleId.toString() === RoleEnum.STUDENT) {
      this.router.navigateByUrl('/student/home');
    }
    else if (userRoleId && userRoleId.toString() === RoleEnum.TEACHER) {
      this.router.navigateByUrl('/teacher/home');
    }
  }

  private subscriptionsInit(): void {
    this.store.dispatch(TeacherActions.getSeededTeachers());
  }
}
